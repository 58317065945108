import React from "react";
import HeadBlock from "../components/HeadBlock";
import styles from "../assets/styles/notfound.module.scss";
import Header from "../components/Header";
import Nav from "../components/Nav";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <HeadBlock title={"404"} path="" />
      <Header />
      <div className={styles.notfound}>
        <h1>404</h1>
        <div className={styles.text}>
          <p>
            <strong>ごめんなさい！</strong>
          </p>
          <p>お探しのページは見つかりませんでした</p>
        </div>
        <img src={require("../assets/images/404.png")} alt="" />
        <button
          type="button"
          className={styles.btn}
          onClick={() => navigate("/")}
        >
          作品一覧へ
        </button>
      </div>
      <Nav />
    </>
  );
}

export default NotFound;
