import React, { createRef, useEffect, useState } from "react";
import HeadBlock from "../components/HeadBlock";
import styles from "../assets/styles/stream.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import { Button } from "../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import logo from "../assets/images/content/logo.svg";

export const StreamsDetail = () => {
  const { user } = useAuthContext();
  const { streamsId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>();
  const [returnPage, setReturnPage] = useState<string>("/contents");
  const videoRef = createRef<HTMLAudioElement>();
  const videoContainerRef = createRef<HTMLDivElement>();
  const [shownStartModal, setShownStartModal] = useState<boolean>(true);
  const [shownRelationModal, setShownRelationModal] = useState<boolean>(false);
  const [isPausedVideo, setIsPausedVideo] = useState<boolean>(true);
  const [isEndedVideo, setIsEndedVideo] = useState<boolean>(false);

  useEffect(() => {
    if (streamsId === "pazle-talk-1") {
      setTitle("PAZLE Talk Vol.1");
      setReturnPage("/contents/hug-time");
    } else if (streamsId === "pazle-talk-2") {
      setTitle("PAZLE Talk Vol.2");
      setReturnPage("/contents/melody");
    } else if (streamsId === "pazle-talk-4") {
      setTitle("PAZLE Talk Vol.4");
      setReturnPage("/contents/kuroda-san");
    }
  }, [streamsId]);

  useEffect(() => {
    if (isEndedVideo) setShownRelationModal(true);
  }, [isEndedVideo]);

  const onPlaying = () => {
    setIsEndedVideo(false);
    setIsPausedVideo(false);
  };

  const onEnded = () => {
    setIsEndedVideo(true);
    setIsPausedVideo(false);
  };

  const onPause = () => {
    setIsPausedVideo(true);
  };

  const startVideo = () => {
    if (!videoRef.current) return;
    searchParams.set("isPlaying", "true");
    setSearchParams(searchParams);

    setShownStartModal(false);
    videoRef.current
      .play()
      .then(() => {
        setIsPausedVideo(false);
      })
      .catch(
        () =>
          videoRef.current?.play().then(() => {
            setIsPausedVideo(false);
          }),
      );
    videoRef.current.muted = false;
  };

  const clickButtonReplay = () => {
    if (!videoRef.current) return;
    videoRef.current.play();
    videoRef.current.currentTime = 0;
  };

  return (
    <>
      <HeadBlock title={title} path={`streams/${streamsId}`} />
      <div className={styles.streams}>
        <div className={styles.contents_header}>
          <button
            type="button"
            className={styles.btn_close}
            onClick={() => {
              navigate(returnPage);
            }}
          >
            <i className={stylesIcon.icon_close}></i>
          </button>
          <img src={logo} alt="" />
          {user ? (
            <div className={styles.login}>
              <i className={stylesIcon.icon_user_outline}></i>
              <p>ログイン中</p>
            </div>
          ) : (
            <Button
              size="s"
              variant="secondary"
              onClick={() => navigate("/signin")}
            >
              ログイン/登録
            </Button>
          )}
        </div>

        <div className={styles.video_wrap}>
          <div ref={videoContainerRef} className={styles.video}>
            <img
              src={`${process.env.PUBLIC_URL}/ogp_${streamsId}.png`}
              alt=""
            />
            <audio
              ref={videoRef}
              id={streamsId}
              src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/streams%2F${streamsId}.wav?alt=media`}
              onPlaying={onPlaying}
              onPause={onPause}
              onEnded={onEnded}
              muted
              controls
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
              preload="none"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>

          <div className={styles.contents_footer_btns}>
            <Button
              className={styles.btn_relation}
              size="s"
              variant={isPausedVideo ? "primary" : "secondary"}
              onClick={() => {
                setShownRelationModal(true);
                searchParams.set("relationPage", "true");
                setSearchParams(searchParams);
              }}
            >
              関連作品を見る
            </Button>
          </div>
          {isEndedVideo && (
            <div className={styles.announce_ended_video}>
              <Button variant="secondary" onClick={clickButtonReplay}>
                もう一度視聴する
              </Button>
            </div>
          )}
        </div>

        {shownStartModal && (
          <div className={styles.modal}>
            <div className={styles.inner}>
              <h2>
                以下の配信を
                <br />
                視聴しますか？
              </h2>
              <div className={styles.btn_close_wrap}>
                <button
                  type="button"
                  className={styles.btn_close}
                  onClick={() => navigate(returnPage)}
                >
                  <i className={stylesIcon.icon_close}></i>
                </button>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/ogp_${streamsId}.png`}
                alt=""
              />
              <Button onClick={startVideo}>視聴する</Button>
            </div>
            <div className={styles.overlay}></div>
          </div>
        )}

        {shownRelationModal && (
          <div className={`${styles.modal} ${styles.modal_relation}`}>
            <div className={styles.inner}>
              <h2>関連作品</h2>
              <div className={styles.btn_close_wrap}>
                <button
                  type="button"
                  className={styles.btn_close}
                  onClick={() => {
                    setShownRelationModal(false);
                    searchParams.delete("relationPage");
                    setSearchParams(searchParams);
                  }}
                >
                  <i className={stylesIcon.icon_close}></i>
                </button>
              </div>
              {(streamsId === "pazle-talk-1" ||
                streamsId === "pazle-talk-2") && (
                <ul className={styles.modal_relation_contents}>
                  <li>
                    <img src={"https://pazle.click/ogp_hug-time.png"} alt="" />
                    <div className={styles.modal_relation_footer}>
                      <div className={styles.modal_relation_txt}>
                        <h3>ひみつのはぐたいむ</h3>
                        <p>全1チャプター / 5分20秒</p>
                      </div>
                      <Button
                        className={styles.modal_relation_btn}
                        size="s"
                        onClick={() =>
                          window.open("/purchase/hug-time?chapter=1", "_blank")
                        }
                      >
                        ¥400
                      </Button>
                    </div>
                  </li>
                  <li>
                    <img src={"https://pazle.click/ogp_melody.png"} alt="" />
                    <div className={styles.modal_relation_footer}>
                      <div className={styles.modal_relation_txt}>
                        <h3>ひみつのめろでぃ</h3>
                        <p>全3チャプター / 11分34秒</p>
                      </div>
                      <Button
                        className={styles.modal_relation_btn}
                        size="s"
                        onClick={() =>
                          window.open("/purchase/melody?chapter=all", "_blank")
                        }
                      >
                        ¥600
                      </Button>
                    </div>
                  </li>
                </ul>
              )}
              {streamsId === "pazle-talk-4" && (
                <ul className={styles.modal_relation_contents}>
                  <li>
                    <img
                      src={"https://pazle.click/ogp_kuroda-san.png"}
                      alt=""
                    />
                    <div className={styles.modal_relation_footer}>
                      <div className={styles.modal_relation_txt}>
                        <h3>犬派になって、黒田さん！</h3>
                        <p>全3チャプター / 6分8秒</p>
                      </div>
                      <Button
                        className={styles.modal_relation_btn}
                        size="s"
                        onClick={() =>
                          window.open(
                            "/purchase/kuroda-san?chapter=all",
                            "_blank",
                          )
                        }
                      >
                        ¥600
                      </Button>
                    </div>
                  </li>
                  <li>
                    <img
                      src={"https://pazle.click/ogp_fuyuzora-melt-reply.png"}
                      alt=""
                    />
                    <div className={styles.modal_relation_footer}>
                      <div className={styles.modal_relation_txt}>
                        <h3>想い、冬空メルト。～reply～</h3>
                        <p>全3チャプター / 6分48秒</p>
                      </div>
                      <Button
                        className={styles.modal_relation_btn}
                        size="s"
                        onClick={() =>
                          window.open(
                            "/purchase/fuyuzora-melt-reply?chapter=all",
                            "_blank",
                          )
                        }
                      >
                        ¥600
                      </Button>
                    </div>
                  </li>
                </ul>
              )}
            </div>

            <div
              className={styles.overlay}
              role="button"
              tabIndex={0}
              onClick={() => setShownRelationModal(false)}
              onKeyUp={() => setShownRelationModal(false)}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default StreamsDetail;
