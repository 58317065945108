import HeadBlock from "../../../components/HeadBlock";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import styles from "../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewKurodaSan() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={"小林 裕介「一人二役のBLを演じるのは不思議な気持ちでした」"}
        path="interview/kuroda-san"
      />
      <div className={`${styles.Interview} ${styles.kurodaSan}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/kuroda-san"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>小林 裕介「一人二役のBLを演じるのは不思議な気持ちでした」</h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回はPAZLE初のBL作品「犬派になって、黒田さん！」の公開を記念し、対象的性格である高梨と黒田を演じ分けた声優の小林
              裕介さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/kuroda-san/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―収録お疲れ様でした！まず物語全体の印象はいかがでしたか？
            </p>
            <p>
              今回、数分のショートの物語だったので、主人公たちのオフィスライフの1幕をちょっとだけ切り取った、そんなストーリーでしたね。すごく日常感があってよかったです。
              <br />
              変なトラブルもなく、ただただいつも通り過ごすというのが僕的にはすごく好きですし、そのフラットな日常の中にどんな面白さを見つけていくかを考えるのが楽しいので、やっていて心地よかったです。
            </p>
            <p className={styles.interviewer}>
              ―今回一人二役に挑戦していただいたのですが、それぞれのキャラクターをどう感じられましたか？
            </p>
            {user ? (
              <p>
                僕はなるべくナチュラルにやろうかなと思っていたんですけど、ディレクターさんから作り込んでいこうと言われたので、どちらのキャラも少し誇張しながら作った感はありました。犬系男子とドS系上司というのは、どちらも僕にあまりない要素だったので、どうやって味付けしていこうかなというのはあれこれ考えました。
                <br />
                普通のアニメではここまで濃くすることもなかなかないですから。だいぶ味付けが濃いめになったキャラクターたちだったと思います。
              </p>
            ) : (
              <p>僕はなるべくナチュラルにやろうかなと思っていたんですけど...</p>
            )}
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=kuroda-san"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <img
                  src={require("../../../assets/images/interview/kuroda-san/img2.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―一人二役演じてみてどうでしたか？難しかった点、楽しかった点があれば教えてください。
                </p>
                <p>
                  こういう2人の掛け合いのドラマって、普通は二人の演じるテンポ感をお互いに合わせてやり取りし、駆け引きするというものなんですよね。それを今回は一人で全て計画してやらなきゃいけないっていうのが難しかったです。
                  <br />
                  収録の仕方としては先に高梨を撮り、次に黒田というふうに分けてとったものですから。
                </p>
                <p>
                  例えば黒田にこう受け答えをさせたいから高梨はこういうふうにセリフを終わらせようみたいな、すごく計画的にやっていたのでそれが今までと違う点でした。自分のセリフを自分で受けるので、なんかクスッと笑っちゃいますよね。
                </p>
                <p>
                  あと何よりS役をやることがなくほぼM役なので、Sって何だろうって頭にはてなマークを浮かべながらやってましたが、ブースの向こうでは皆さんに好評だったようなので自信を持ってSができたと、今後自慢していきたいと思います（笑）
                </p>

                <img
                  src={require("../../../assets/images/interview/kuroda-san/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回、主人公は「撫でられる」ことでドキドキしましたが、小林さんはどんな仕草や行動をされるとドキドキしますか？
                </p>
                <p>
                  これよく言ってるんですけど僕、身長が低いので女性と並んでも同じぐらいのことがほとんどなんです。だから、上目遣いってされることがないんですよ。
                  <br />
                  だから僕よりだいぶ下の人が見上げる形でこっちを見てくれるといいなぁって思いますね。僕にとってはなかなかレアな体験ですけど（笑）
                </p>

                <p className={styles.interviewer}>
                  ―小林さんは犬派ですか？猫派ですか？もしよければその理由も教えてください。
                </p>
                <p>
                  どっちも飼っていないので答えるのが難しいんですけど、知り合いが両方飼っていて、僕もいずれはどっちも飼いたいと思ってるんですよ。ただ、猫を先に飼っちゃうと縄張り意識が強いから後から犬が来たときにすごい喧嘩をするって聞いたんです。なのでまず最初は犬がいいですね。
                  <br />
                  たまにペットショップに入って眺めたりしていますが、目があってビビッと来た子を見つけたらお迎えできたらいいなと思ってます。
                </p>

                <img
                  src={require("../../../assets/images/interview/kuroda-san/img4.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―ありがとうございました！最後に視聴者の皆様へメッセージをお願いします。
                </p>
                <p>
                  今回まさかの一人二役でBLをやるということで不思議な気持ちになったのですが、皆さんに喜んでいただけるんじゃないかという要素を自分なりに詰め込んでみました。
                  <br />
                  僕も完成がどういうふうになるか聞いてみないことにはわからないですが、僕の新しい一面、小林が珍しく攻めてるなって思ってもらえるレアな作品だと思いますのでぜひ聴いていただけたら嬉しいです！
                </p>

                <div className={styles.introduction}>
                  <p>
                    小林 裕介（コバヤシ ユウスケ） 声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/kuroda-san"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/kuroda-san/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/kuroda-san/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/kuroda-san/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/kuroda-san/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/kuroda-san&text=小林 裕介「一人二役のBLを演じるのは不思議な気持ちでした」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,小林裕介"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewKurodaSan;
