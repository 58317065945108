import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Outlet } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";
import imgLogoPazle from "../../../assets/images/logo.svg";
import imgLogoPazleBeta from "../../../assets/images/content/logo.svg";
import imgLogoPazleProduct from "../../../assets/images/content/logo_footer.svg";
import imgArrow from "../../../assets/images/top_arrow_top.svg";
import imgLogoVector from "../../../assets/images/top_logo_vector.svg";
import imgLogoPazleWhite from "../../../assets/images/logo_white.svg";
import imgLogoContent from "../../../assets/images/content/oshi-katsu/logo.svg";

function ContentOshiKatsu() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock title={"推し活らいふ！"} path="contents/oshi-katsu/detail" />
      <div className={`${styles.Content} ${styles.oshiKatsu}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img src={imgLogoPazle} alt="" />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/oshi-katsu/detail">
                <img
                  src={imgLogoPazleBeta}
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/oshi-katsu"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/oshi-katsu"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/oshi-katsu/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/oshi-katsu/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.mainvisualTitle}>
                <div className={styles.text}>
                  <h1>
                    <img src={imgLogoContent} alt="推し活らいふ！" />
                  </h1>
                  <p className={styles.tag}>
                    主人公の芽依が所属する「推し活同好会」
                    <br />
                    今日もランダムグッズを巡る戦いが始まる...
                  </p>
                </div>
                <a
                  href="/contents/oshi-katsu"
                  target="_blank"
                  className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
                >
                  ボイスドラマを視聴する
                </a>
              </div>
              <div className={styles.free}>
                <p>
                  <span>
                    <strong>1</strong>
                    <small>話目</small>
                  </span>
                  <span>無料</span>
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/oshi-katsu/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/actor_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>葛西 芽依 役</p>
                  <p className={styles.name}>
                    小澤 麗那<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    小澤 麗那（オザワ レナ）声優。
                    <br />
                    <br />
                    {`1月26日生まれ、愛知県出身。
                    出演作品は、『アイドルマスターシャイニーカラーズ』（郁田はるき）、
                    『白猫プロジェクト NEW WORLD'S』（ウルスラ、エイダ）、
                    『安達としまむら』（デロス）、『アルケランド』（ヴァラク）など。`}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/story2.png")}
                  alt=""
                />
                <p>
                  「運つかっちゃったぁぁぁ！」
                  <br />
                  ごく普通の大学二年生の葛西芽依。様々なオタクが集まり推しについて談義したり時には協力しあう「推し活同好会」メンバーの１人である。
                </p>
                <p>
                  今日の活動はメンバーの推しのランダムグッズを引き当てること！見事引き当てた芽依だが自身が推しているアイドルグループのお知らせが届き...？
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    小澤 麗那（オザワ レナ）声優。
                    <br />
                    <br />
                    {`1月26日生まれ、愛知県出身。
                    出演作品は、『アイドルマスターシャイニーカラーズ』（郁田はるき）、
                    『白猫プロジェクト NEW WORLD'S』（ウルスラ、エイダ）、
                    『安達としまむら』（デロス）、『アルケランド』（ヴァラク）など。`}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/oshi-katsu"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  <strong>
                    ランダムグッズで一喜一憂するというのはとても共感できるなと思いました
                  </strong>
                </h3>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―収録お疲れ様でした！アイドルオタクの可愛らしい女の子の感じと叫び声のシーンとのギャップがすごく聴き応えがありました！
                  </p>
                  <p className={styles.Interviewee}>
                    ありがとうございます！アイドルが好きという自分とも共通しているところがあったので、自分なりにできるものを出せればいいなと思って…
                  </p>
                </div>
                <a
                  href="/interview/oshi-katsu"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/oshi-katsu/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/oshi-katsu/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img src={imgLogoPazleProduct} alt="" />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/oshi-katsu/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/oshi-katsu/detail&text=小澤麗那さん出演！「推し活同好会」に所属する主人公の日常物語「推し活らいふ！」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,小澤麗那"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img src={imgArrow} alt="" />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img src={imgLogoVector} className={styles.vector} alt="" />
                <span>/</span>
                <img src={imgLogoPazleWhite} className={styles.pazle} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentOshiKatsu;
