import HeadBlock from "../../../components/HeadBlock";
import styles from "../../../assets/styles/interview.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewHugTime() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "道井 悠「セリフ以外の部分で想像力を働かせられるよう演じました」＆ 長谷川 玲奈「ギャップが好きだなと思いました」"
        }
        path="interview/hug-time"
      />
      <div className={`${styles.Interview} ${styles.hugTime}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/hug-time"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              道井 悠「セリフ以外の部分で想像力を働かせられるよう演じました」＆
              長谷川 玲奈「ギャップが好きだなと思いました」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回は、「ひみつのはぐたいむ」の作品公開を記念し、いままでに感じたことの無い感情に戸惑う峰田彩希役を演じた声優の道井
              悠さんと、甘えん坊な同級生の丸山寧々役を演じた声優の長谷川
              玲奈さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/hug-time/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―本日はありがとうございました！
              <br />
              二人での掛け合いが臨場感があってドキドキしました。
              <br />
              さっそくですが、まず物語の印象についてお聞かせください。
            </p>
            <p>
              <strong>道井さん：</strong>
              最初は、どうして寧々と隠れてハグしてるんだろう？って思いました。状況や感情がよく分からないなと。
              <br />
              でも最後に、体育倉庫で寧々が待っていてくれた時に私も嬉しくなって。
              <br />
              あの瞬間が最高のキュンじゃないかな？と思いました。
            </p>
            <p>
              <strong>長谷川さん：</strong>
              私は、素の自分もやりそうだなと思いました。
            </p>
            <p>
              <strong>道井さん：</strong>
              結構コミュニケーションが多いタイプだもんね？
            </p>
            <p>
              <strong>長谷川さん：</strong>そうですね！
              <br />
              性格は逆かもしれないけど、行動面では私もやりそうなイメージだったので。
              <br />
              なので、
              {user
                ? "第三者から見たらこんなことをしているとは思わないような二人なので、ギャップが好きだなって思いました。"
                : "..."}
            </p>
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=hug-time"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <p className={styles.interviewer}>
                  ―先ほど、長谷川さんは自分もやりそうだと言っていましたが、主人公と自分とで重なる部分はありましたか？
                </p>
                <p>
                  <strong>道井さん：</strong>
                  私もそんなに友達が多いタイプではないんですけど。一匹狼とまではいかないですが。
                  <br />
                  声をかけられたら誰にでも着いていくけど、特定の大親友グループがなさそうな感じはちょっと似てるなと思いました。
                  <br />
                  …質問なんでしたっけ？（笑）
                </p>
                <p className={styles.interviewer}>
                  ―主人公と自分とで重なる部分はありましたか？という質問です！（笑）
                </p>
                <p>
                  <strong>道井さん：</strong>
                  性格的に、自分と似てるなっていう部分はありました。
                </p>
                <p className={styles.interviewer}>
                  ―クールなイメージなんですかね？
                </p>
                <p>
                  <strong>道井さん：</strong>
                  そうですね。でも、やっぱり寂しいっていう気持ちはあって。
                  <br />
                  そこを寧々と埋めていくのも分かるなと思いました。
                </p>
                <p className={styles.interviewer}>
                  ―長谷川さんは、他に自分と重なる部分や演じてみて感じたことはありますか？
                </p>
                <p>
                  <strong>長谷川さん：</strong>
                  寧々ちゃんは多分、彩希ちゃんに助けてもらっていたのかなと思います。
                  <br />
                  それが本当に嬉しくて。居心地が良くて。
                  <br />
                  でも、だからハグしたいというよりは、彩希ちゃんとの関係が今までになかったからこそ嬉しさと共に離れたくない関係になったのかな？って思いました。
                </p>

                <img
                  src={require("../../../assets/images/interview/hug-time/img2.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回は高校の話ですが、高校時代の印象的な思い出はありますか？
                </p>
                <p>
                  <strong>道井さん：</strong>彩希ちゃんは水泳部ですよね？
                  <br />
                  私はフェンシング部だったんですけど、部活に打ち込んでいました。
                  <br />
                  でも打ち込んでいる中で、ちょっとどこかで寂しいっていう気持ちも持っていて。
                  <br />
                  それが印象的です。
                </p>
                <p className={styles.interviewer}>
                  ―フェンシング部は合宿などもありましたか？
                </p>
                <p>
                  <strong>道井さん：</strong>ありました！
                </p>
                <p className={styles.interviewer}>
                  ―では、みんなで夜に語り合ったりとかも？
                </p>
                <p>
                  <strong>道井さん：</strong>そうですね。
                  <br />
                  夜、急に卓球大会が開催されました！白熱した戦いが行われました...笑
                  <br />
                  あとは、朝早く起こされて山一周走らされたりとか...。思い出深いです。
                </p>
                <p className={styles.interviewer}>
                  ―ありがとうございます。
                  <br />
                  長谷川さんは高校時代の印象的な思い出はありますか？
                </p>
                <p>
                  <strong>長谷川さん：</strong>
                  私は高校で猫をかぶっていたので、保健室レッツゴー！タイプの人間でした。
                  <br />
                  なので、学校での思い出はないかもしれない...。
                  <br />
                  朝の出欠確認で「長谷川玲奈さん」って呼ばれて、返事をした後すぐに保健室に行きました。
                  <br />
                  保健室の先生と保健室にくるいつメンが仲間でした。
                </p>
                <p className={styles.interviewer}>
                  ―一緒に保健室に集まる人たちがいるんですね。
                </p>
                <p>
                  <strong>長谷川さん：</strong>似てる子達がいて。
                  <br />
                  それなりに楽しかったけど、思っていた高校生活とは違ったかなって思いました。
                </p>
                <p className={styles.interviewer}>
                  ―アニメだと保健室の先生とお菓子を食べながら、みんなで談笑したりするじゃないですか。
                  <br />
                  そんな感じもあったんですか？
                </p>
                <p>
                  <strong>長谷川さん：</strong>ありました！
                </p>
                <p>
                  <strong>道井さん：</strong>
                  思っていた高校生活とは違ったって言ってたけど、私たちは役者だからいろんな青春をいろんな作品で味わわせていただけるのは嬉しいよね。
                </p>
                <p>
                  <strong>長谷川さん：</strong>嬉しいです！
                  <br />
                  そういう青春ができなかったので。
                </p>

                <img
                  src={require("../../../assets/images/interview/hug-time/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―PAZLEでは、電車などちょっとした移動時間に音楽を聴く代わりにコンテンツを聴いて移動を楽しんでいただきたいなと思っています。
                  <br />
                  そういった音声だけのコンテンツを演じる上で気をつけたことはありますか？
                </p>
                <p>
                  <strong>道井さん：</strong>
                  SE部分でアドリブさせていただいたので、そういったセリフ以外の部分で想像力を働かせられるように演じてました。
                </p>
                <p className={styles.interviewer}>
                  ―聞いていて、ショートストーリーの中でもどんどん起伏があるのを感じました。
                  <br />
                  最初と最後のセリフでだいぶ感情の差があってすごいなと思いました。
                </p>
                <p className={styles.interviewer}>
                  ―最後に視聴者の皆さんにメッセージをお願いします！
                </p>
                <p>
                  <strong>道井さん：</strong>私、百合作品が実は初めてで。
                  <br />
                  初めての百合作品で後輩の長谷川玲奈と絡ませていただいたことが大変光栄です。
                  <br />
                  友情以上百合未満みたいな、ちょっとキュンする物語を耳で楽しんでいただければと思うとともに、みなさまPAZLEというサービスをよろしくお願いします！
                  <br />
                  これから、いい作品がたくさん出ると思うのでぜひチェックしてください！
                </p>
                <p>
                  <strong>長谷川さん：</strong>私も多分初めてで。
                </p>
                <p>
                  <strong>道井さん：</strong>本当？
                </p>
                <p>
                  <strong>長谷川さん：</strong>恐らく...！
                  <br />
                  先輩の道井さんとこういう演技をするのは初めてなので、すごく新鮮な気持ちになりました。
                  <br />
                  また、百合作品が好きなので演じられて嬉しかったです。
                  <br />
                  百合が好きな方ってたぶん女性の方が多いと思うんですけど、そんなに生々しくはない綺麗な百合なので、男性でも女の子同士の友情以上百合未満の関係を耳で楽しんでいただけたらなと思います。
                </p>
                <p className={styles.interviewer}>―ありがとうございました！</p>
                <div className={styles.introduction}>
                  <p>
                    道井 悠（ミチイ ハルカ） 声優。
                    <br />
                    <br />
                    10月3日生まれ、石川県出身。O型。出演作品は、『終末のハーレム』（柊春歌役）、『邪神ちゃんドロップキック』（スライム先生役）、『TOKYO
                    7thシスターズ』（神城スイ役）、『蒼穹のスカイガレオン』（コアトリクエ役、ヨワルテクトリ役）など。
                  </p>
                </div>
                <div className={styles.introduction}>
                  <p>
                    長谷川 玲奈（ハセガワ レナ） 声優。
                    <br />
                    <br />
                    3月15日生まれ、新潟県出身。O型。出演作品は、『恋と呼ぶには気持ち悪い』（天草理緒役）、『邪神ちゃんドロップキックX』（アトレ役）、『理系が恋に落ちたので証明してみた。r=1-sinθ（ハート）』（神楽野
                    春役）、『さよなら私のクラマー』（加古川香梨奈役）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼冒頭30秒を無料で視聴できます！／</p>
              <a
                href="/contents/hug-time"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/hug-time/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/hug-time/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  道井 悠<small>さん、</small>長谷川 玲奈<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「ボイスドラマの感想」を記載し引用リツイートしていただくと、抽選で道井悠さん・長谷川玲奈さん直筆色紙を2名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/hug-time/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/hug-time/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/hug-time/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/hug-time/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/hug-time/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/hug-time/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/hug-time&text=道井 悠「セリフ以外の部分で想像力を働かせられるよう演じました」＆ 長谷川 玲奈「ギャップが好きだなと思いました」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,道井悠,長谷川玲奈"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewHugTime;
