import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../assets/styles/top.module.scss";
import stylesIcon from "../../assets/styles/icon.module.scss";
import imgLogoPazle from "../../assets/images/logo.svg";
import imgLogoPazleBeta from "../../assets/images/logo_beta.svg";
import imgLogoPazleWhite from "../../assets/images/logo_white.svg";
import imgLogoCsp from "../../assets/images/logo_csp_s_white.svg";
import imgArrow from "../../assets/images/top_arrow_top.svg";
import imgLogoVector from "../../assets/images/top_logo_vector.svg";

function Top() {
  const [isLoadingKv, setIsLoadingKv] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <div className={styles.Top}>
      <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
        <img src={imgLogoPazle} alt="" />
        <p>
          <span className={styles.word}>N</span>
          <span className={styles.word}>o</span>
          <span className={styles.word}>w</span>
          <span className={`${styles.word} ${styles.wordFirstLetter}`}>L</span>
          <span className={styles.word}>o</span>
          <span className={styles.word}>a</span>
          <span className={styles.word}>d</span>
          <span className={styles.word}>i</span>
          <span className={styles.word}>n</span>
          <span className={styles.word}>g</span>
          <span className={styles.word}>.</span>
          <span className={styles.word}>.</span>
          <span className={styles.word}>.</span>
        </p>
      </div>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <h1>
            <a href="/about">
              <img src={imgLogoPazleBeta} alt="PAZLE" className={styles.logo} />
            </a>
          </h1>
          <a href="#sns" className={styles.pageToSns}>
            <i className={stylesIcon.icon_arrow_down_circle}></i>
            SNSをフォローする
          </a>
          <div className={styles.headerBtns}>
            <a
              href="https://twitter.com/Pazle_info"
              target="_brank"
              className={`${styles.btnTw}`}
            >
              フォローする
            </a>
            <a
              href="https://lin.ee/r4SVWtA"
              target="_brank"
              className={styles.btnLine}
            >
              <img
                src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                alt="友だち追加"
              />
            </a>
            <a
              href="/"
              target="_brank"
              className={`${styles.navBtnPc} ${styles.btn} ${styles.btnPrimary}`}
            >
              <span>ボイスドラマを視聴</span>
            </a>
          </div>
        </nav>
      </header>

      <main id="main">
        <div
          className={`${styles.mainvisual} ${!isLoading && styles.isAnimated}`}
        >
          <img
            src={require("../../assets/images/top_kv_sp.jpg")}
            className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
            alt=""
          />
          <img
            src={require("../../assets/images/top_kv_pc.jpg")}
            className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
            onLoad={() => setIsLoadingKv(false)}
            alt=""
          />
          <div className={styles.text}>
            <p className={styles.copy}>
              ボイスドラマで、
              <br />
              あなたの世界を彩る。
            </p>
            <p className={styles.tag}>
              話題の声優さんのボイスドラマが視聴できる
              <br className={`${styles.brSp}`} />
              「PAZLE（ベータ版）」誕生
            </p>
            <div className={styles.btnWrapperPc}>
              <a
                href="/"
                target="_brank"
                className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
              >
                <span>ボイスドラマを視聴する（冒頭無料）</span>
              </a>
            </div>
            <div className={styles.productImgPcWrap}>
              <img
                className={styles.productImgPc}
                src={require("../../assets/images/top_kv_image_pc.png")}
                alt=""
              />
            </div>
          </div>
          <div className={styles.cspSp}>
            <img src={imgLogoCsp} alt="" />
            <p>
              作品のご購入でクリエイターに
              <br />
              売上の一部が還元されます
            </p>
          </div>
        </div>

        <div className={styles.btnWrapperSp}>
          <a
            href="/"
            target="_brank"
            className={`${styles.btn} ${styles.btnPrimary} ${styles.btnLarge}`}
          >
            <span>ボイスドラマを視聴する（冒頭無料）</span>
          </a>
        </div>

        <div className={styles.bannerApp}>
          <a
            href="https://itunes.apple.com/jp/app/id6477372760"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.bannerAppImgPc}
              src={require("../../assets/images/top_banner_app_pc.jpg")}
              alt=""
            />
            <img
              className={styles.bannerAppImgSp}
              src={require("../../assets/images/top_banner_app_sp.jpg")}
              alt=""
            />
          </a>
        </div>

        <div className={styles.publicContents}>
          <div className={styles.publicContentsTitle}>
            <span>
              ベータ版では声優さんにスポットを当てたボイスドラマを公開！
            </span>
            <h2>PAZLEで生まれた作品たち</h2>
          </div>
          <ul className={styles.contentsList}>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_life.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_ozawa.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>小澤 麗那</p>
                  <p className={styles.nameEn}>
                    Rena
                    <br />
                    Ozawa
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  「運つかっちゃったぁぁぁ！」
                  <br />
                  ごく普通の大学二年生の葛西芽依。様々なオタクが集まり推しについて談義したり時には協力しあう「推し活同好会」メンバーの１人である。今日の活動はメンバーの推しのランダムグッズを引き当てること！見事引き当てた芽依だが自身が推しているアイドルグループのお知らせが届き...？
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/oshi-katsu"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/oshi-katsu/detail"
                  target="_brank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_gl2.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_sato.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>佐藤 妃星</p>
                  <p className={styles.nameEn}>
                    Kiara
                    <br />
                    Sato
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  「はぁ…。やっぱり無理だ…。」
                  <br />
                  最近スランプ気味である吹奏楽部の優花。
                  <br />
                  先輩の彩希との交流を通して調子を取り戻す。
                  <br />
                  ある日、彩希と寧々がハグしている現場を目撃してしまい、優花はなんとも言えない気持ちになった。もしかして彩希に対する気持ちは憧れではなく…?
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/melody"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/melody/detail"
                  target="_brank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_bl.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_kobayashi.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>小林 裕介</p>
                  <p className={styles.nameEn}>
                    Yusuke
                    <br />
                    Kobayashi
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  今日もたくさんダメ出しされちゃったな...。
                  <br />
                  僕、高梨影人の上司である、黒田剛臣さんは部署のみんなからもイケメン鬼上司と恐れられている存在だ。
                  <br />
                  毎日たくさん怒られているが僕は全然怖くない。
                  <br />
                  なぜなら、僕は黒田さんの裏の顔を知っているから...。
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/kuroda-san"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/kuroda-san/detail"
                  target="_brank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_youth2.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_kobayashi.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>小林 裕介</p>
                  <p className={styles.nameEn}>
                    Yusuke
                    <br />
                    Kobayashi
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  高校３年生の冬。僕は自分のヘタレさに心底後悔している…。
                  <br />
                  僕のことを好きだと言ってくれた実紅に対して、テンパった僕はその場から逃げ出してしまった。そんな時に聞こえてきた恐竜のような叫び声。
                  <br />
                  僕はその瞬間に声に向かって駆け出した！
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/fuyuzora-melt-reply"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/fuyuzora-melt-reply/detail"
                  target="_brank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_fantasy_2.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_takamori.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>高森 奈津美</p>
                  <p className={styles.nameEn}>
                    Natsumi
                    <br />
                    Takamori
                  </p>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_okamoto.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>岡本 信彦</p>
                  <p className={styles.nameEn}>
                    Nobuhiko
                    <br />
                    Okamoto
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  生まれつき炎の魔剣に愛されている少年・紅は魔剣の呪いを解くために旅にでる。ブラウティナ王国の第一王女セシリアは紅の呪いの話を聞き、強い正義感から呪いを解く旅に同行することになる。
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/kouen-no-maken"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/kouen-no-maken/detail"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_youth.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_hasegawa.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>長谷川 育美</p>
                  <p className={styles.nameEn}>
                    Ikumi
                    <br />
                    Hasegawa
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  高校３年生の冬。私の告白はあっけなく惨敗したー
                  <br />
                  夜の学校に忍び込んだ少女は、屋上に向かった。2月の寒い夜、学校の屋上で星空を見上げながら叫び出す。実紅の心の奥底に秘めた思いが、その叫び声と共に夜空に溢れ出すのだった...
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/fuyuzora-melt"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/fuyuzora-melt/detail"
                  target="_brank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_gl.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_michii.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>道井 悠</p>
                  <p className={styles.nameEn}>
                    Haruka
                    <br />
                    Michii
                  </p>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_rena_hasegawa.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>長谷川 玲奈</p>
                  <p className={styles.nameEn}>
                    Rena
                    <br />
                    Hasegawa
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  私、峰田
                  彩希は最近人に言えない秘密を抱えてしまっている。今日も学校の体育倉庫で丸山
                  寧々は私の胸に顔を埋める...
                  <br />
                  これは友情なの？それとも...恋？
                  <br />
                  私たちの秘密のハグタイムが今日もやってくる。
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/hug-time"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/hug-time/detail"
                  target="_blank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_sf.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_koichi.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>小市 眞琴</p>
                  <p className={styles.nameEn}>
                    Makoto
                    <br />
                    Koichi
                  </p>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_toki.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>土岐 隼一</p>
                  <p className={styles.nameEn}>
                    Shunichi
                    <br />
                    Toki
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  数十年後、人類史上最高の天才と呼ばれる学生、真木
                  心一。彼の元に未来からやってくた崎野
                  夢芽はある使命を帯びているという。
                  <br />
                  タイムマシンの前で微笑むと夢芽と悲しい表情を浮かべる心一。二人の間にある壮絶な運命とは...
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/sayonara-no-mirai"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/sayonara-no-mirai/detail"
                  target="_blank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
            <li className={styles.active}>
              <img
                className={styles.imgContent}
                src={require("../../assets/images/top_collaboration_fantasy.png")}
                alt=""
              />
              <ul className={styles.cvList}>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_amasaki.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>天﨑 滉平</p>
                  <p className={styles.nameEn}>
                    Kohei
                    <br />
                    Amasaki
                  </p>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/top_collaboration_iwami.png")}
                    alt=""
                  />
                  <p className={styles.nameJa}>石見 舞菜香</p>
                  <p className={styles.nameEn}>
                    Manaka
                    <br />
                    Iwami
                  </p>
                </li>
              </ul>
              <div className={styles.story}>
                <p>
                  <strong>Story</strong>
                </p>
                <p>
                  学園トーナメントで激しく大剣を振るリオン・リルスィード。彼女は神代
                  悠汰に怒っていた。なにしろ着替え中の更衣室を見られてしまったのだから。だが悠汰は知っていた。リオンが背負う過酷な運命と、彼がリオンを救わなければいけないことを。
                </p>
              </div>
              <div className={styles.btns}>
                <a
                  href="/contents/noroi-no-rondo"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnPrimary} ${styles.btnSmall}`}
                >
                  ボイスドラマを視聴する
                </a>
                <a
                  href="/contents/noroi-no-rondo/detail"
                  target="_blank"
                  className={`${styles.btnLink}`}
                >
                  ボイスドラマ詳細を見る
                </a>
              </div>
            </li>
          </ul>
        </div>

        {!isLoadingKv && (
          <>
            <section className={styles.interview}>
              <div className={styles.title}>
                <h2 className={`${styles.titleEn} ${styles.active}`}>
                  Creator’s interview
                </h2>
                <span className={styles.titleJa}>
                  PAZLEクリエイターの想いを直撃！
                </span>
              </div>
              <ul className={styles.list}>
                <li className={styles.active}>
                  <a href="/interview/fuyuzora-melt" target="_brank">
                    <img
                      src={require("../../assets/images/interview/fuyuzora-melt/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/sayonara-no-mirai" target="_brank">
                    <img
                      src={require("../../assets/images/interview/sayonara-no-mirai/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/noroi-no-rondo" target="_brank">
                    <img
                      src={require("../../assets/images/interview/noroi-no-rondo/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/hug-time" target="_brank">
                    <img
                      src={require("../../assets/images/interview/hug-time/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/kouen-no-maken" target="_brank">
                    <img
                      src={require("../../assets/images/interview/kouen-no-maken/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/fuyuzora-melt-reply" target="_brank">
                    <img
                      src={require("../../assets/images/interview/fuyuzora-melt-reply/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/kuroda-san" target="_brank">
                    <img
                      src={require("../../assets/images/interview/kuroda-san/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/melody" target="_brank">
                    <img
                      src={require("../../assets/images/interview/melody/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
                <li className={styles.active}>
                  <a href="/interview/oshi-katsu" target="_brank">
                    <img
                      src={require("../../assets/images/interview/oshi-katsu/thumbnail.png")}
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </section>

            <section className={styles.beta}>
              <div className={styles.title}>
                <h2 className={`${styles.titleEn} ${styles.active}`}>
                  ベータ版でできること
                </h2>
                <span className={styles.titleJa}>サービス紹介</span>
              </div>
              <section className={styles.inner}>
                <div className={`${styles.image} ${styles.active}`}>
                  <img
                    src={require("../../assets/images/top_beta1.png")}
                    alt=""
                  />
                </div>
                <div className={styles.text}>
                  <h3 className={`${styles.subTitle} ${styles.active}`}>
                    声優さんにスポットをあてた
                    <br className={styles.brPc} />
                    ボイスドラマを公開！
                  </h3>
                  <div className={`${styles.textBody} ${styles.active}`}>
                    <p>
                      来年公開予定の正式版リリース前にPAZLEが推す声優さんのボイスドラマを視聴できるベータ版を公開しました！
                      <br />
                      美麗なイラストに乗せた声優さんの想いのこもった作品をぜひお楽みしください！
                    </p>
                  </div>
                </div>
              </section>
              <section className={styles.inner}>
                <div className={`${styles.image} ${styles.active}`}>
                  <img
                    src={require("../../assets/images/top_beta2.png")}
                    alt=""
                  />
                </div>
                <div className={styles.text}>
                  <h3 className={`${styles.subTitle} ${styles.active}`}>
                    スワイプでサクサク視聴！
                  </h3>
                  <div className={`${styles.textBody} ${styles.active}`}>
                    <p>
                      上へスワイプすることで作品を次々視聴できます。
                      <br />
                      作品冒頭は無料で公開！ご購入いただくと全編視聴できます！
                      <br />
                      ご購入作品は一覧ページからいつでも視聴可能です。
                    </p>
                  </div>
                  <div className={styles.btnWrapper}>
                    <a
                      href="/"
                      target="_brank"
                      className={`${styles.btn} ${styles.btnPrimary} ${styles.btnMedium} ${styles.btnPc}`}
                    >
                      ボイスドラマを視聴する（冒頭無料）
                    </a>
                  </div>
                </div>
              </section>

              <div className={styles.ambassador}>
                <ul>
                  <li>
                    <img
                      src={require("../../assets/images/top_michii.png")}
                      alt=""
                    />
                    <div className={styles.text}>
                      <p className={styles.name}>道井 悠</p>
                      <p className={styles.job}>
                        声優/作品出演、ナレーション協力
                      </p>
                    </div>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/images/top_hasegawa.png")}
                      alt=""
                    />
                    <div className={styles.text}>
                      <p className={styles.name}>長谷川 玲奈</p>
                      <p className={styles.job}>
                        声優/作品出演、ナレーション協力
                      </p>
                    </div>
                  </li>
                </ul>
                <img
                  className={styles.ambassadorMessage}
                  src={require("../../assets/images/top_ambassador_message.png")}
                  alt="私達も声優として作品作りに参加しています！"
                />
              </div>

              <div className={styles.csp}>
                <p>
                  PAZLEはクリエイターを
                  <br className={styles.brSp} />
                  支援しています！
                </p>
                <img
                  className={styles.imgPc}
                  src={require("../../assets/images/top_csp_pc.png")}
                  alt=""
                />
                <img
                  className={styles.imgSp}
                  src={require("../../assets/images/top_csp_sp.png")}
                  alt=""
                />
              </div>
            </section>

            <section className={styles.official}>
              <div className={styles.title}>
                <span className={styles.tag}>＼2024年リリース予定／</span>
                <h2 className={`${styles.titleEn} ${styles.active}`}>
                  正式版でできること
                </h2>
                <span className={styles.titleJa}>
                  クリエイター向け機能について
                </span>
              </div>
              <section className={styles.inner}>
                <div className={`${styles.image} ${styles.active}`}>
                  <img
                    className={styles.textCollaboration}
                    src={require("../../assets/images/top_about_collaboration.png")}
                    alt=""
                  />
                  <img
                    className={styles.creator}
                    src={require("../../assets/images/top_about_creator.png")}
                    alt=""
                  />
                  <img
                    className={styles.arrow}
                    src={require("../../assets/images/top_about_arrow.png")}
                    alt=""
                  />
                  <img
                    className={styles.consumer}
                    src={require("../../assets/images/top_about_consumer.png")}
                    alt=""
                  />
                </div>
                <div className={styles.text}>
                  <h3 className={`${styles.subTitle} ${styles.active}`}>
                    クリエイターがコラボレーション！
                    <br />
                    共創作品を発表・販売！
                  </h3>
                  <div className={`${styles.textBody} ${styles.active}`}>
                    <p>
                      声優・イラストレーター・シナリオライターなどクリエイターのためのサービスを開発中！
                      <br />
                      作品やスキルを登録、コラボレーション相手を探し、異業種との作品づくりを実現。配信、販売、コミュニティ機能を備え、視聴者へ質の高い作品が提供できます！
                      <br />
                      メールのご登録で開発状況を配信します！
                    </p>
                    <div className={styles.can}>
                      <p className={styles.canTitle}>
                        クリエイターが正式版でできること
                      </p>
                      <ul>
                        <li>
                          <p>
                            クリエイター
                            <br />
                            同士のマッチング
                          </p>
                        </li>
                        <li>
                          <p>
                            作品の
                            <br />
                            配信・販売
                          </p>
                        </li>
                        <li>
                          <p>
                            ファンとの
                            <br />
                            コミュニケーション
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <div className={styles.movie}>
                <video
                  src={require("../../assets/images/promotion.mp4")}
                  muted
                  autoPlay
                  loop
                  playsInline
                ></video>
                <img
                  src={require("../../assets/images/top_movie.png")}
                  alt=""
                />
              </div>

              <div className={styles.btnWrapper}>
                <a
                  href="/contents/promotion"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSecondary} ${styles.btnMedium}`}
                >
                  <span>サービス上で動画を視聴する</span>
                </a>
              </div>
            </section>

            <section className={styles.contact}>
              <div className={styles.inner}>
                <div className={styles.contactTextWrap}>
                  <h2 className={styles.contactTitle}>
                    2024年、
                    <br className={styles.brSp} />
                    正式版を公開予定！
                  </h2>
                  <p className={styles.contactText}>
                    ただいま正式版を鋭意開発中！
                    <br />
                    正式版では作品やスキルを登録、コラボレーション相手を探し、異業種との作品づくりを実現。配信、販売、コミュニティ機能を備え、視聴者へ質の高い作品が提供できます！
                    <br />
                    <br />
                    正式版のリリース状況は公式X（旧：Twitter）にて配信します。
                  </p>
                </div>
                <a
                  href="https://twitter.com/Pazle_info"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnLine} ${styles.btnLarge}`}
                >
                  フォローする
                </a>
              </div>
            </section>
          </>
        )}
      </main>

      <footer className={styles.footer}>
        <div className={styles.footerInner}>
          <div className={styles.backToTopWrap}>
            <a className={styles.backToTop} href="#main">
              <img src={imgArrow} alt="" />
            </a>
          </div>
          <div className={styles.snsLinks} id="sns">
            <a
              href="https://twitter.com/Pazle_info"
              target="_brank"
              className={`${styles.btnTw}`}
            >
              フォローする
            </a>
            <a
              href="https://lin.ee/r4SVWtA"
              target="_brank"
              className={styles.btnLine}
            >
              <img
                src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                alt="友だち追加"
              />
            </a>
          </div>
          <div className={styles.inner}>
            <ul className={styles.linkList}>
              <li>
                <a href="https://vectordesign.jp/" target="_brank">
                  運営会社について
                </a>
              </li>
              <li>
                <a href="/terms" target="_brank">
                  利用規約
                </a>
              </li>
              <li>
                <a href="/privacy" target="_brank">
                  プライバシーポリシー
                </a>
              </li>
              <li>
                <a href="/tokutei" target="_brank">
                  特定商取引法に基づく表記
                </a>
              </li>
              <li>
                <a href="/requirements" target="_brank">
                  動作環境について
                </a>
              </li>
            </ul>
            <div className={styles.copylight}>
              <small>©︎2023</small>
              <img src={imgLogoVector} className={styles.vector} alt="" />
              <span>/</span>
              <img src={imgLogoPazleWhite} className={styles.pazle} alt="" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Top;
