import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

const MuteControllerContext = createContext(
  {} as {
    isMuted: boolean;
    setIsMuted: Dispatch<React.SetStateAction<boolean>>;
  },
);

export const useMuteControllerContext = () => {
  return useContext(MuteControllerContext);
};

export const MuteControllerProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const value = {
    isMuted,
    setIsMuted,
  };

  return (
    <MuteControllerContext.Provider value={value}>
      {children}
    </MuteControllerContext.Provider>
  );
};

export default MuteControllerProvider;
