import styles from "../assets/styles/maintenance.module.scss";
import HeadBlock from "../components/HeadBlock";
import logo from "../assets/images/logo.svg";

function Maintenance() {
  return (
    <>
      <HeadBlock title={"現在、メンテナンス中です"} />
      <div className={styles.Maintenance}>
        <img className={styles.logo} src={logo} alt="PAZLE" />
        <div className={styles.inner}>
          <h1>現在、メンテナンス中です</h1>
          <img src={require("../assets/images/maintenance.png")} alt="" />
          <p>
            現在、システムメンテナンスを行なっているため、PAZLE
            をご利用いただけません。
            <br />
            ご不便をおかけしますが、ご理解いただきますようお願い申し上げます。
          </p>
        </div>
        <footer className={styles.footer}>
          <ul>
            <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdnS9xymPyO5EXHsHTIDrDwbTn3qMaRTw1W2vHft_nDE14KBA/viewform"
                target="_brank"
              >
                お問い合わせ
              </a>
            </li>
            <li>
              <a href="https://vectordesign.jp/" target="_brank">
                運営会社について
              </a>
            </li>
            <li>
              <a href="/terms" target="_brank">
                利用規約
              </a>
            </li>
            <li>
              <a href="/privacy" target="_brank">
                プライバシーポリシー
              </a>
            </li>
            <li>
              <a href="/tokutei" target="_brank">
                特定商取引法に基づく表記
              </a>
            </li>
            <li>
              <a href="/requirements" target="_brank">
                動作環境について
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
}

export default Maintenance;
