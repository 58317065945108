import HeadBlock from "../../../components/HeadBlock";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import styles from "../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import imgLogoInterview from "../../../assets/images/interview/logo.svg";
import imgLogoPazleProduct from "../../../assets/images/content/logo_footer.svg";
import imgArrow from "../../../assets/images/top_arrow_top.svg";
import imgLogoVector from "../../../assets/images/top_logo_vector.svg";
import imgLogoPazleWhite from "../../../assets/images/logo_white.svg";

function InterViewOshiKatsu() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "小澤 麗那「ランダムグッズで一喜一憂するというのはとても共感できるなと思いました"
        }
        path="interview/oshi-katsu"
      />
      <div className={`${styles.Interview} ${styles.oshiKatsu}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img src={imgLogoInterview} alt="PAZLE Creator’s interview" />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/oshi-katsu"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              小澤 麗那
              「ランダムグッズで一喜一憂するというのはとても共感できるなと思いました
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回はPAZLE作品「推し活らいふ！」の公開を記念し、今回の出演が初の主役となる小澤
              麗那さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/oshi-katsu/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―収録お疲れ様でした！アイドルオタクの可愛らしい女の子の感じと叫び声のシーンとのギャップがすごく聴き応えがありました！
            </p>
            <p>
              ありがとうございます！
              <br />
              アイドルが好きという自分とも共通しているところがあったので、自分なりにできるものを出せればいいなと思ってやらせていただきました。
            </p>
            <p className={styles.interviewer}>
              ―物語を演じられた全体の印象はいかがでしたか？
            </p>
            <p>
              収録を今終えたばかりなんですけど、演技のディレクションをしてくださった演出家の方の印象がすごく頭に残っています。
              <br />
              〇〇風にとか、とても面白いディレクションをしていただき、私も
              {user
                ? "一生懸命お応えさせていただいたので、良い意味で元々イメージしていた演技と違うものをお出しできました。とてもやりがいのあるシナリオを演じられたと思います！"
                : "..."}
            </p>
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=oshi-katsu"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <img
                  src={require("../../../assets/images/interview/oshi-katsu/img2.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―小澤さんもアイドルオタクとのことですが、主人公や物語に共感できるポイントはありましたか？
                </p>
                <p>
                  ストーリーの中にもあったのですが、ランダムグッズで一喜一憂するというのはとても共感できるなと思いました！
                  <br />
                  私も缶バッチや、アクスタなどで推しのアイテムを当てると大騒ぎします（笑）
                  <br />
                  逆になかなか欲しいものが手に入らないと落ち込みますし、やっぱり欲しいものこそなかなか当たらないよな、というのを共感しながら演じました。
                  <br />
                  演じさせていただいた「めいちゃん」は全力でオタクをやってる子でしたね。私はそこまで激しくはないのですが、近いものを感じました。
                </p>
                <img
                  src={require("../../../assets/images/interview/oshi-katsu/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―小澤さんが推しのグッズで一番欲しいアイテムの種類はなんですか？
                </p>
                <p>
                  一番嬉しいものは写真集です！
                  <br />
                  推しの写真集はもちろん持ってるのですが、私がよく見るタイミングがおやつ時なんです。紅茶と一緒におやつを食べるのが日課なのですが、おやつを片手に写真集を読んでるととてもおしゃれな気持ちになれるというか、すごく充実した気持ちになれるんです（笑）
                  <br />
                  なので写真集が一番好きです！
                  <br />
                  あと、写真集は発売までに期間が空いたりなかなか手に入らないのですが、予約を待っている時間も楽しみの一つです。
                </p>

                <p className={styles.interviewer}>
                  ―推し活をしていて楽しいエピソード、苦労したエピソードがあればそれぞれ教えてください
                </p>
                <p>
                  私の推しのアイドルが毎週1回オンライン番組をやっていて、毎週それを心待ちにしているんです。夜わくわくしながらお家に帰って観て見て、癒されるのが楽しいです！
                  <br />
                  苦労っていう言い方が正しいかわからないのですが、声優のお仕事をさせていただいていると推しのアイドルの方と共演できる機会があるんです。
                  <br />
                  でも私はどちらかというと推しの視界に入りたくないオタクで。もちろんファンですなんて絶対言えないですし、当たり障りのない会話もできなくて、とっても意識しちゃって何も話せなくなってしまうのがちょっとつらいところです。
                  <br />
                  本当は話せたら嬉しいのですが、緊張しちゃうしアワアワしちゃって（笑）
                </p>

                <img
                  src={require("../../../assets/images/interview/oshi-katsu/img4.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―最後に視聴者の皆様へメッセージをお願いします
                </p>
                <p>
                  初めまして、小澤麗那と申します。
                  <br />
                  主演を務めさせていただいたことは初めてですし、1人での朗読作品というのも初めてだったので、とても貴重な経験をさせていただきました！
                  <br />
                  ディレクションしてくださった演出家の方のおかげもあり楽しかったです！
                  <br />
                  寒い季節なのですが全力を出しすぎてめちゃめちゃ汗をかかせてもらいました（笑）
                  <br />
                  なので、この汗たっぷりの全力感が視聴者の皆さんにも伝わって楽しんでいただけたら嬉しいです！ありがとうございました。
                </p>

                <div className={styles.introduction}>
                  <p>
                    小澤 麗那（オザワ レナ）声優。
                    <br />
                    <br />
                    {`1月26日生まれ、愛知県出身。
                    出演作品は、『アイドルマスターシャイニーカラーズ』（郁田はるき）、
                    『白猫プロジェクト NEW WORLD'S』（ウルスラ、エイダ）、
                    『安達としまむら』（デロス）、『アルケランド』（ヴァラク）など。`}
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/oshi-katsu"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/oshi-katsu/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/oshi-katsu/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/oshi-katsu/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img src={imgLogoPazleProduct} alt="" />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/oshi-katsu/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/oshi-katsu/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/oshi-katsu&text=小澤 麗那「ランダムグッズで一喜一憂するというのはとても共感できるなと思いました- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,小澤麗那"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img src={imgArrow} alt="" />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img src={imgLogoVector} className={styles.vector} alt="" />
                <span>/</span>
                <img src={imgLogoPazleWhite} className={styles.pazle} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewOshiKatsu;
