import { useEffect } from "react";
import HeadBlock from "../../../components/HeadBlock";
import styles from "../../../assets/styles/interview.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewNoroiNoRondo() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "石見 舞菜香「この子の奥行きみたいなものを想像してもらえたらいいな」＆ 天﨑 滉平「飄々としていながらも、壮絶な部分を隠して生きている」"
        }
        path="interview/noroi-no-rondo"
      />
      <div className={`${styles.Interview} ${styles.noroiNoRondo}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/noroi-no-rondo"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              石見
              舞菜香「この子の奥行きみたいなものを想像してもらえたらいいな」＆
              天﨑 滉平「飄々としていながらも、壮絶な部分を隠して生きている」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回は、「魔法の紋章と呪いの恋輪舞」の作品公開を記念し、呪いを背負った女性剣士リオン役を演じた声優の石見
              舞菜香さんと、呪いの魔女を姉に持つ剣士役を演じた声優の天﨑
              滉平さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/noroi-no-rondo/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―改めまして本日はありがとうございました。
              <br />
              さっそくですが、まず物語の印象についてお聞かせください。
            </p>
            <p>
              <strong>天﨑さん：</strong>
              設定資料を事前に読ませていただいたのですが、今回収録したショートストーリーの前後にも物語が広がっているんだろうな、というのをすごく感じました。
              <br />
              あとは結構シリアスなストーリーですよね。
              <br />
              特に悠汰（ゆうた）は、お姉さん以外の家族を亡くしていて、そのお姉さんでさえも呪いの力で世界を変えようとする魔女になっていて。
              <br />
              とても壮絶でいろんな経験をしてきた子だと思うので、少しでも悠汰のそういった面が出せるといいなと思って演じました。
            </p>
            <p className={styles.interviewer}>
              ―収録を聞いていて、コミカルな雰囲気からシリアスモードに切り替わる演技に感動しました。
              <br />
              石見さんはいかがですか？
            </p>
            <p>
              <strong>石見さん：</strong>
              最初、リオンさんは悠汰さんにずっと怒っていて、その後にシリアスな展開に切り替わるのでどうメリハリをつけようかなって考えながらやらせていただきました。
              <br />
              リオンさんもいろんなことを抱えていそうなキャラクターなのですが、最後に希望のある感じで終わっていて、リオンさんと悠汰さんの関係性も今後きっと親密になっていくのかな？って想像しながらやらせていただきました。
              <br />
              朗読コンテンツということで、
              {user
                ? "しっとりしたお話がくるのかなと思っていたんですけど、バチバチのバトル系ですごく新鮮な気持ちで楽しく演じさせていただきました。"
                : "..."}
            </p>
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=noroi-no-rondo"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <p className={styles.interviewer}>
                  ―役を作るにあたり、悠汰とリオンはそれぞれどんな性格のキャラクターだと思って演じられましたか？
                </p>
                <p>
                  <strong>天﨑さん：</strong>
                  悠汰は、前半のシーンでは更衣室でリオンの着替えを覗いてしまって怒られて...というようなコミカルなイメージが出るように演じました。
                  <br />
                  表では飄々としていながらも、壮絶な部分を隠して生きていると思うので、そこを前半に出しつつ、リオンに姉の呪いがかかっていることを知ってからは、誰にも見せていない一面が出るように意識しました。
                </p>
                <p>
                  <strong>石見さん：</strong>
                  リオンさんは最初は噛みついてくるけど、なんだかんだ関係値が深まってきたらちょっとツンとしながらデレるみたいな子なのかなって思っていました。
                  <br />
                  設定資料の中で、眉目秀麗（びもくしゅうれい）で戦闘技術も高くて学園でも人気者というので割としっかりした子ではあるものの、顔を赤らめながら叫ぶシーンがあったので普段とのギャップがチャーミングなキャラクターなのかな、って思いながら演じておりました。
                </p>
                <p className={styles.interviewer}>
                  ―今、ストーリーのイラストを描いてもらっているのですが、まさにそういうようなイメージで描いていただいています！
                </p>
                <p>
                  <strong>石見さん：</strong>よかったです！
                </p>

                <img
                  src={require("../../../assets/images/interview/noroi-no-rondo/img2.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回、短いストーリーでバトルとドラマを演じる上で気をつけた点・難しかった点はありますか？
                </p>
                <p>
                  <strong>天﨑さん：</strong>
                  今回、1枚のイラストに声を当てるということで、戦闘の緊張感をどう出せるのかを考えなければいけないな、と思い挑みました。
                  <br />
                  例えば、リオンが剣を振り下ろすなど動きがあるシーンで、リオンの息が入るところでは悠汰は逆に入れないでおこうかな、など。
                  <br />
                  特に、最後の大きな一振りに対して悠汰が剣を出すシーンは、SEのみにする方がメリハリがあっていいかなと思いました。
                  <br />
                  この企画ならではの良さが出るといいなと思って、どこにリアクションを入れるのかは意識して考えました。
                </p>
                <p>
                  <strong>石見さん：</strong>
                  私はリオンさんが今までどういう風に生きてきて、呪いの被害がどの程度のものだったのかは想像でしか分からなかったんですけど、秘密を知られた相手を殺したいと思うくらい重たいものなんだろうなと思ったので、深刻になりすぎちゃったかなと思うくらいで演じました。
                  <br />
                  でも、この子の奥行きみたいなものを想像してもらえたらいいなって思いながらやりました。
                </p>
                <p className={styles.interviewer}>
                  ―今まで、今回のような音声だけの朗読コンテンツを演じられたことはありますか？
                </p>
                <p>
                  <strong>天﨑さん：</strong>
                  朗読「劇」なら実際に生でお客さんの前でやらせていただいたことはありますが、朗読コンテンツをアプリで配信するのは初めてだと思います。
                  <br />
                  石見さんはどうですか？
                </p>
                <p>
                  <strong>石見さん：</strong>
                  たしかに、今までアプリで配信するのはあんまりなかったかもしれないですね。
                </p>
                <p className={styles.interviewer}>
                  ―今回の朗読コンテンツを演じる際に、いつもと意識的に変えられた部分はありましたか？
                </p>
                <p>
                  <strong>天﨑さん：</strong>どうなんだろ...？
                </p>
                <p>
                  <strong>石見さん：</strong>
                  結構、悠汰さん視点でのモノローグが多く、私はほぼセリフだったので自然とできたかもしれないですね。
                </p>
                <p>
                  <strong>天﨑さん：</strong>
                  確かにそこまで違いを意識してなかったかもしれないです。
                  <br />
                  ただ朗読劇は生なので噛んでしまう恐れがあるのですが、今回は収録だったのでやり直してちゃんと納得のいくものをお届けできるという違いはありました（笑）
                </p>

                <img
                  src={require("../../../assets/images/interview/noroi-no-rondo/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―最後に視聴者の皆さんにメッセージをお願いします！
                </p>
                <p>
                  <strong>天﨑さん：</strong>
                  このサービスは、すごく面白そうだなと思いました！
                  <br />
                  ショートストーリーなので、お時間がある時に肩の力を抜いて軽い気持ちで聞けるけれど、軽い気持ちで聞いたら面白くてのめり込んでしまうという今までにない試みだなと思いました。
                  <br />
                  ぜひ、この作品の続編を想像していただいたり、他のコンテンツにも触れて楽しんでいただけたらなと思います。
                </p>
                <p>
                  <strong>石見さん：</strong>
                  例えばお仕事疲れちゃったなとか、活字を目で追えないなとか、そういうタイミングでも耳で聞くだけで物語を感じることができるし、主婦の方なども家事をしながら聞くことができたり、楽しみ方が広がるなって思いました。
                  <br />
                  ぜひこのコンテンツを存分に楽しんでいただけたら嬉しいです。
                  <br />
                  この先、またリオンちゃんや悠汰さんと会える機会があることを私も願っておりますので、ぜひとも応援をよろしくお願いします。
                </p>
                <p className={styles.interviewer}>
                  ―私たちも次回作を作れるように頑張りますので引き続きよろしくお願いします！
                  <br />
                  ありがとうございました！
                </p>
                <div className={styles.introduction}>
                  <p>
                    石見 舞菜香（イワミ マナカ） 声優。
                    <br />
                    <br />
                    4月30日生まれ、埼玉県出身。出演作品は、『【推しの子】』（黒川あかね役）、『フルーツバスケット』（本田透役）、『ひきこまり吸血姫の悶々』（サクナ・メモワール役）、『不滅のあなたへ』（リーン役）、『クジラの子らは砂上に歌う』（リコス役）など。
                  </p>
                </div>
                <div className={styles.introduction}>
                  <p>
                    天﨑 滉平（アマサキ コウヘイ） 声優。
                    <br />
                    <br />
                    10月22日生まれ、大阪府出身。出演作品は、『トモちゃんは女の子!』（御崎光助役）、『時々ボソッとロシア語でデレる隣のアーリャさん』（久世政近役）、『アイドルマスター
                    SideM』（東雲荘一郎役）、『事情を知らない転校生がグイグイくる。』（北川虎太郎役）、『僕のヒーローアカデミア』（物間寧人役）、『ヒプノシスマイク
                    -Division Rap Battle- Rhyme
                    Anima』（山田三郎役）、『ダンス・ダンス・ダンスール』（安田海咲役）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼冒頭30秒を無料で視聴できます！／</p>
              <a
                href="/contents/noroi-no-rondo"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/noroi-no-rondo/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  石見 舞菜香<small>さん、</small>天﨑 滉平<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    公式X（旧：Twitter）から投稿される応募用のポストに「ボイスドラマの感想」を記載し引用リツイートしていただくと、抽選で石見舞菜香さん・天﨑滉平さん直筆色紙を2名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/noroi-no-rondo/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/noroi-no-rondo/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/noroi-no-rondo/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/noroi-no-rondo&text=石見 舞菜香「この子の奥行きみたいなものを想像してもらえたらいいな」＆ 天﨑 滉平「飄々としていながらも、壮絶な部分を隠して生きている」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,石見舞菜香,天﨑滉平"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewNoroiNoRondo;
