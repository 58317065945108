export type ContentCategoryType = "YOUTH" | "GL" | "SF" | "FANTAGY" | "PR";
export const displayContentCategory = (
  category: ContentCategoryType,
): string => {
  switch (category) {
    case "YOUTH":
      return "琴線を揺さぶる青春の物語";
    case "SF":
      return "SF";
    case "GL":
      return "百合";
    case "FANTAGY":
      return "ファンタジー";
    case "PR":
      return "プロモーション";
    default:
      return "";
  }
};
