import { Dispatch, SetStateAction, useEffect } from "react";
import * as firebaseui from "firebaseui";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  TwitterAuthProvider,
} from "firebase/auth";
import loadJs from "loadjs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { analytics, auth, db } from "../firebase";
import stylesIcon from "../assets/styles/icon.module.scss";
import { logEvent } from "firebase/analytics";
import { doc, setDoc, Timestamp } from "firebase/firestore";

export const SigninModal = ({
  setShownModalSignin,
  shownModalSignin,
  chapter,
}: {
  setShownModalSignin: Dispatch<SetStateAction<string | null>>;
  shownModalSignin: string | null;
  chapter: number[];
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { contentsId } = useParams();
  const id = contentsId ? contentsId : searchParams.get("id");

  useEffect(() => {
    const uiConfig = {
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        signInSuccessWithAuthResult: (authResult: any) => {
          const isNewUser = authResult.additionalUserInfo.isNewUser;
          const providerId = authResult.additionalUserInfo.providerId;

          if (isNewUser && providerId === "password")
            sendEmailVerification(authResult.user);
          if (isNewUser) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).twq("event", "tw-ofkye-ofyqg", {});
            logEvent(analytics, "register_member", {
              in_modal: true,
            });
            if (localStorage.getItem("introduction_code")) {
              (async () => {
                setDoc(doc(db, "introductions", authResult.user.uid), {
                  introducerCode: localStorage.getItem("introduction_code"),
                  createdAt: Timestamp.now(),
                });
              })();
            }
          } else {
            logEvent(analytics, "login", {
              in_modal: true,
            });
          }

          if (id) {
            if (chapter.length === 1)
              navigate(`/purchase/${id}?chapter=${chapter[0]}`);
            else navigate(`/purchase/${id}?chapter=all`);
          } else {
            navigate("/", { state: { registerComplete: isNewUser } });
          }
          return false;
        },
        signInFailure: () => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        },
      },
      signInFlow: "popup",
      signInOptions: [
        TwitterAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID,
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
      tosUrl: "/terms",
      privacyPolicyUrl: "/privacy",
    };

    loadJs(
      [
        "https://www.gstatic.com/firebasejs/9.9.2/firebase-app-compat.js",
        "https://www.gstatic.com/firebasejs/9.9.2/firebase-auth-compat.js",
        "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth__ja.js",
      ],
      {
        success: () => {
          let ui = firebaseui.auth.AuthUI.getInstance();
          if (!ui) {
            ui = new firebaseui.auth.AuthUI(auth);
          }
          ui.start("#firebaseui-auth-container", uiConfig);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownModalSignin]);

  return (
    <div className="modal_signin">
      <div className="inner">
        <h2>
          購入するには
          <br />
          アカウントが必要です
        </h2>
        <div className="btn_close_wrap">
          <button
            type="button"
            className="btn_close"
            onClick={() => setShownModalSignin(null)}
          >
            <i className={stylesIcon.icon_close}></i>
          </button>
        </div>
        <p className="emphasis">
          購入してここでしか聴けない
          <br />
          <strong>ボイスドラマ</strong>を聴こう！
        </p>
        <div id="firebaseui-auth-container" className="container"></div>
      </div>
      <div className="overlay" onClick={() => setShownModalSignin(null)}></div>
    </div>
  );
};
