import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";

function ContentHugTime() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock title={"ひみつのはぐたいむ"} path="contents/hug-time/detail" />
      <div className={`${styles.Content} ${styles.hugTime}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt=""
          />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/hug-time/detail">
                <img
                  src={
                    require("../../../assets/images/content/logo.svg").default
                  }
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/hug-time"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/hug-time"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/hug-time/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/hug-time/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.text}>
                <h1>
                  <img
                    src={
                      require("../../../assets/images/content/hug-time/logo.svg")
                        .default
                    }
                    alt="ひみつのはぐたいむ"
                  />
                </h1>
                <p className={`${styles.tag}`}>
                  PAZLEボイスドラマ第四弾公開！
                  <br />
                  体育倉庫でこっそりハグするのが2人の楽しみ…
                </p>
              </div>
              <a
                href="/contents/hug-time"
                target="_blank"
                className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
              <div className={`${styles.mainvisualCsp} ${styles.cspSp}`}>
                <img
                  src={require("../../../assets/images/logo_csp_s.svg").default}
                  alt=""
                />
                <p>
                  作品のご購入でクリエイターに
                  <br />
                  売上の一部が還元されます
                </p>
              </div>
              <div className={`${styles.mainvisualCsp} ${styles.cspPc}`}>
                <img
                  src={require("../../../assets/images/logo_csp.svg").default}
                  alt=""
                />
                <p>
                  作品をご購入いただくとクリエイターに売上の一部が還元されます
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/hug-time/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/hug-time/actor_michii_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>峰田 彩希 役</p>
                  <p className={styles.name}>
                    道井 悠<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    道井 悠（ミチイ ハルカ） 声優。
                    <br />
                    <br />
                    10月3日生まれ、石川県出身。O型。出演作品は、『終末のハーレム』（柊春歌役）、『邪神ちゃんドロップキック』（スライム先生役）、『TOKYO
                    7thシスターズ』（神城スイ役）、『蒼穹のスカイガレオン』（コアトリクエ役、ヨワルテクトリ役）など。
                  </p>
                </div>
              </li>
              <li>
                <img
                  src={require("../../../assets/images/content/hug-time/actor_hasegawa_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>丸山 寧々 役</p>
                  <p className={styles.name}>
                    長谷川 玲奈<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    長谷川 玲奈（ハセガワ レナ） 声優。
                    <br />
                    <br />
                    3月15日生まれ、新潟県出身。O型。出演作品は、『恋と呼ぶには気持ち悪い』（天草理緒役）、『邪神ちゃんドロップキックX』（アトレ役）、『理系が恋に落ちたので証明してみた。r=1-sinθ（ハート）』（神楽野
                    春役）、『さよなら私のクラマー』（加古川香梨奈役）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/hug-time/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/hug-time/story2.png")}
                  alt=""
                />
                <p>
                  私、峰田 彩希は最近人に言えない秘密を抱えてしまっている。
                  <br />
                  今日も学校の体育倉庫で丸山 寧々は私の胸に顔を埋める...
                </p>
                <p>
                  これは友情なの？それとも...恋？
                  <br />
                  私たちの秘密のはぐたいむが今日もやってくる。
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    道井 悠（ミチイ ハルカ） 声優。
                    <br />
                    <br />
                    10月3日生まれ、石川県出身。O型。出演作品は、『終末のハーレム』（柊春歌役）、『邪神ちゃんドロップキック』（スライム先生役）、『TOKYO
                    7thシスターズ』（神城スイ役）、『蒼穹のスカイガレオン』（コアトリクエ役、ヨワルテクトリ役）など。
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    長谷川 玲奈（ハセガワ レナ） 声優。
                    <br />
                    <br />
                    3月15日生まれ、新潟県出身。O型。出演作品は、『恋と呼ぶには気持ち悪い』（天草理緒役）、『邪神ちゃんドロップキックX』（アトレ役）、『理系が恋に落ちたので証明してみた。r=1-sinθ（ハート）』（神楽野
                    春役）、『さよなら私のクラマー』（加古川香梨奈役）など。
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼冒頭30秒を無料で視聴できます！／</p>
              <a
                href="/contents/hug-time"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/hug-time/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>
                      セリフ以外の部分で想像力を働かせられるよう演じました
                    </strong>
                  </h3>
                  <p>道井 悠</p>
                </div>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>ギャップが好きだなと思いました</strong>
                  </h3>
                  <p>長谷川 玲奈</p>
                </div>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―本日はありがとうございました！
                    <br />
                    二人での掛け合いが臨場感があってドキドキしました。
                    <br />
                    さっそくですが、まず物語の印象についてお聞かせください
                  </p>
                  <p className={styles.Interviewee}>
                    道井さん：最初は、どうして寧々と隠れてハグしてるんだろう？って思いました。状況や感情がよく分からないなと。
                    <br />
                    でも最後に、体育倉庫で寧々が待っていてくれた時に私も嬉しくなって。
                    <br />
                    あの瞬間が最高のキュンじゃないかな？と...
                  </p>
                </div>
                <a
                  href="/interview/hug-time"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
            <section className={styles.present}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/hug-time/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  道井 悠<small>さん、</small>長谷川 玲奈<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「ボイスドラマの感想」を記載し引用リツイートしていただくと、抽選で道井悠さん・長谷川玲奈さん直筆色紙を2名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/hug-time/detail/present"
                  state={{ background: location }}
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/hug-time/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/hug-time/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/hug-time/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/hug-time/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/hug-time/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/hug-time/detail&text=道井悠さん、長谷川玲奈さん出演！体育倉庫でこっそりハグするのが2人の楽しみ…「ひみつのはぐたいむ」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,道井悠,長谷川玲奈"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentHugTime;
