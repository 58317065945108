import React from "react";
import HeadBlock from "../../components/HeadBlock";
import styles from "../../assets/styles/static.module.scss";
import Header from "../../components/Header";

function Privacy() {
  return (
    <>
      <HeadBlock title={"プライバシーポリシー"} path="privacy" />
      <Header />
      <div className={styles.privacy}>
        <h1>プライバシーポリシー</h1>
        <p>
          VECTOR合同会社（以下「当社」といいます。）は、当社が開発・運営を行うサービスをご利用になる会員（以下「ユーザー」といいます。）のプライバシーを尊重し、ユーザーの個人情報の管理に細心の注意を払うものとします。
        </p>
        <section>
          <h2>1. 個人情報の取得</h2>
          <p>
            当社がサービスを提供するために必要な範囲でのみユーザーの個人情報（氏名、住所、電話番号等の情報を単一または複数組み合わせることにより特定の個人を識別できる情報）を取得いたします。
            <br />
            個人情報を不正な手段により取得いたしません。
          </p>
        </section>
        <section>
          <h2>2. 個人情報の利用</h2>
          <p>
            取得したユーザーの個人情報は、複数のサービス等において相互に利用することがあります。
            <br />
            保有する個人情報について、利用目的の達成に必要な範囲を超えて取扱いいたしません。
            <br />
            利用目的の達成を超える範囲で個人情報を取り扱う場合は、あらかじめユーザーの同意を得るものとします。
          </p>
        </section>
        <section>
          <h2>3. 個人情報の利用の例外</h2>
          <p>
            法令に基づく場合、生命・身体または財産の保護のために必要がある場合、児童の健全な育成の推進のために必要がある場合、公的な機関またはその委託を受けた者に協力する必要がある場合、緊急の事態においてユーザーの安全性を保護する必要がある場合は例外として本人の同意を得ずに個人情報を取り扱うことがあります。
          </p>
        </section>
        <section>
          <h2>4. 個人情報を利用したデータの公開</h2>
          <p>
            取得したユーザーの個人情報をもとに統計データなどのデータを作成する場合、個人を識別できないように加工いたします。
          </p>
        </section>
        <section>
          <h2>5. 個人情報の利用の免責</h2>
          <p>
            ユーザーが自ら第三者に個人情報を明らかにした場合、ユーザーが入力した情報等により、個人が識別できてしまった場合の責任は負いません。
          </p>
        </section>
        <section>
          <h2>6. 退会について</h2>
          <p>
            <a href="mailto:support@pazle.click">support@pazle.click</a>
            へユーザーIDを記載の上、ご依頼ください。
            <br />
            お申し出いただいた方がご本人であることを確認したうえで、当社所定の方法にて速やかに退会手続きいたします。
            <br />
            ユーザーが退会手続きをされた場合、当社が提供するサービスは、ご利用いただけなくなります。
          </p>
          <section>
            <h3>ユーザーIDの確認方法はこちら</h3>
            <ol>
              <li>
                ログイン後に<a href="/menu/profile/edit">プロフィール画面</a>
                を開く
              </li>
              <li>画面上部にある「ユーザーID」を確認</li>
            </ol>
          </section>
        </section>
        <section>
          <h2>7. プライバシーポリシーの改定</h2>
          <p>
            個人情報を保護するために予告無くプライバシーポリシーを改訂することがあります。
          </p>
        </section>
      </div>
    </>
  );
}

export default Privacy;
