import React from "react";
import { useEffect } from "react";
import HeadBlock from "../../../components/HeadBlock";
import styles from "../../../assets/styles/interview.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../components/AuthContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewSayonaraNoMirai() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "土岐 隼一「異質な恋愛での葛藤を表現できたらと思いました。」＆ 小市 眞琴「やりがいのあるキャラクターでした。」"
        }
        path="interview/sayonara-no-mirai"
      />
      <div className={`${styles.Interview} ${styles.sayonaraNoMirai}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/sayonara-no-mirai"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              土岐隼一「異質な恋愛での葛藤を表現できたらと思いました。」＆
              小市眞琴「やりがいのあるキャラクターでした。」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回は、「君の過去にさよならの未来を数えて」の作品公開を記念し、天才発明化の真木一心役を演じた声優の土岐隼一さんと、未来からやってきた崎野
              夢芽役を演じた声優の小市
              眞琴さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/sayonara-no-mirai/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―本日はありがとうございました！お二人とも別々の撮影だったのですが掛け合いがぴったり噛み合っていて、また一瞬で役に入られていたのを拝見しプロの方のすごさを感じました。
              <br />
              さっそくですが、今回のストーリーで特に印象に残った部分や魅力はありますか？
            </p>
            <p>
              <strong>土岐さん：</strong>
              僕も結構SFが好きで、日本海外問わず色んな作品を見るんですけど。
              <br />
              主人公自身がタイムスリップで助けに行くというのはよくありますが、未来から1年に1回だけヒロインが主人公の前に現れて助けてくれる、という設定はあまり見たことがなかったです。
              <br />
              すごく馴染みのあるテーマでも新鮮で面白かったな、と思いました。
            </p>
            <p>
              <strong>小市さん：</strong>
              私は小説を読むのが好きでSFも好きなのですが、この短いストーリー内にSFの要素が分かりやすくまとめられているのがすごいと思いました。
              <br />
              女の子はタイムマシーンでどんどん過去に戻って、男の子は普通に成長していて、その二人が出会うというSFの難しい設定が分かりやすくまとめられていて、読んでいて面白かったです。
            </p>
            <img
              src={require("../../../assets/images/interview/sayonara-no-mirai/img2.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―今回や役を演じてみてどんな感想を持たれましたか？
            </p>
            {user ? (
              <p>
                <strong>土岐さん：</strong>
                正直感じたのは、主人公自身もどかしいだろうなって。守られるだけで何もできないっていう。
                <br />
                例えば、自分が既にタイムマシンを作っているなら何かできるかもしれないですけど、タイムマシンを作るのが50歳とだいぶ先のことで。
                <br />
                本当に自分がタイムマシンを作るのか分からない状態で守られていて。
                <br />
                お互いを大切に思っていて、でも距離を縮められない理由がある。
                <br />
                主人公は、遠距離恋愛とはまた違う、異質な恋愛での難しい感情を抱きながら日々を送ってるんだろうなって感じました。
                <br />
                なので、そういう葛藤をショートストーリーの中に表現できたらと思いました。
              </p>
            ) : (
              <p>
                <strong>土岐さん：</strong>
                正直感じたのは、主人公自身もどかしいだろうなって。守られるだけで何もできないっていう。
                <br />
                例えば、...
              </p>
            )}
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=sayonara-no-mirai"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <p>
                  <strong>小市さん：</strong>
                  楽しかったです！ってすごく平凡な感想になっちゃうんですけど（笑）
                  <br />
                  こういう切ない話は好きですし、ナチュラルにお芝居できたかなと思います。
                  <br />
                  すごくやりがいのあるキャラクターでした！
                </p>
                <p className={styles.interviewer}>
                  ―もしタイムマシンがあったら何をしたいですか？
                </p>
                <p>
                  <strong>土岐さん：</strong>
                  タイムマシンって言葉を知らない人間はいないと思うんです。
                  <br />
                  僕もいろんなSFの作品を見てきたので、タイムマシンというものを理解している人間だと思います。
                  <br />
                  タイムマシンを得ても絶対にやっていけないことは、過去と未来を変えることなんですよね。
                  <br />
                  過去や未来を変えることで絶対にパラドックスが起きるし、どこかでその因果律が歪むことによって自分のいる場所が歪んでしまうので。
                  <br />
                  それを感じたい人だったらいいと思うんですけど、僕は感じたくないんです。
                  <br />
                  なので自分に関係することを一切せずに、まだ過去の中で曖昧になっているものを見るだけのツアーをしたいです。
                </p>
                <p>
                  例えば本当にこの偉人はいたのかとか、ピラミッドや世界遺産が出来たての頃を見るとか。
                  <br />
                  僕は未来は見たくないんです。知らぬが仏っていう言葉もありますし。もしかしたら三百年後には人類がいなくなってるかもしれないなんて思うから、絶望もしたくないので。
                  <br />
                  おとなしく歴史に何の変化も起こすことなく、過去を見て帰ってきたいと思います。
                </p>
                <p className={styles.interviewer}>
                  ―確かに、ピラミッドとかどうやって作られたのかいまだに謎ですもんね。
                </p>
                <p>
                  <strong>土岐さん：</strong>あとモアイもそうですよね。
                  <br />
                  コロッセオに水を入れて船を浮かべていたのも、本当にそんなことできたのか見てみたいですし、ストーンヘンジも何のために作ったのかを知りたいです。
                  <br />
                  異世界転送装置みたいな説がありますが、そういうのを見られるだけで僕は幸せだなと思うので、おとなしくそれで終わらせておこうと思います。
                </p>
                <img
                  src={require("../../../assets/images/interview/sayonara-no-mirai/img3.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―小市さんはもしタイムマシンを使えたら何をしたいですか？
                </p>
                <p>
                  <strong>小市さん：</strong>
                  十年後とかに、自分はしっかり仕事ができているのか確認したいです！
                  <br />
                  この職業って、将来どうなっていくのか分からないので十年後が気になります！
                  <br />
                  あとは過去を変えられるのであれば、「お前は声優になるから、今からこういうことをやっておけ！」って伝えたいですね。
                </p>
                <p className={styles.interviewer}>
                  ―土岐さん曰く、タイムマシーンで過去と未来を変えることはご法度らしいです。。！
                </p>
                <p>
                  <strong>小市さん：</strong>
                  じゃあ、過去の自分に伝えることはできないですね！（笑）
                  <br />
                  ならば...過去には戻らず、十年後の自分が何をしているかだけを確認します！
                  <br />
                  変に過去を変えて、今の自分がなくなる、みたいなのあるじゃないですか？
                  <br />
                  そうならないように、十年後に自分がどうなっているかだけを確認します。
                </p>
                <p>
                  …でもどうなんだろう？
                  <br />
                  十年後の未来を見たとして、自分がもしも声優としてうまくいってなかったら今の自分をちょっと修正しちゃいそうですね。
                  <br />
                  こうならないようにこう仕事しよう！とか。
                  <br />
                  また別の未来になっちゃう気がしますね。
                </p>
                <p className={styles.interviewer}>
                  ―でも、パラレルワールドは無限にありますからね。
                </p>
                <p>
                  <strong>小市さん：</strong>確かに！
                  <br />
                  じゃあ、タイムマシーンを使えたら、やはり十年後の自分は確認したいですね。
                </p>
                <p className={styles.interviewer}>
                  ―PAZLEでは、電車などちょっとした移動時間に音楽を聴く代わりにコンテンツを聴いて移動を楽しんでいただきたいなと思っています。
                  <br />
                  そういった音声だけのコンテンツを演じる上で気をつけたことはありますか？
                </p>
                <p>
                  <strong>土岐さん：</strong>
                  移動している最中に聞くからっていうところを意識して作ってくださるのって、この脚本を書いてくださる方だと思うんです。
                  <br />
                  ショートストーリーの中で起承転結を作らなきゃいけないだったりとか、バランスをすごく考えてストーリーが作られてると思います。
                  <br />
                  だからこそ僕たちは、ここはこういう風に盛り上げたいんだろうなとか、こういうことをしてほしいんだろうなっていう意図を体現できたらなって思っています。
                  <br />
                  これはストーリーの長さに限らず、大切にしていることかなって思いますね。
                  <br />
                  なのでこのショートストーリーで起承転結を作るのは本当にすごいなって思いました。
                </p>
                <p>
                  <strong>小市さん：</strong>
                  今回のものだけではなく、過去にも本を一冊まるまる朗読したことがあるのですが、淡々と読みすぎないように意識していました。
                  <br />
                  普通にお芝居するときはもっと緩急をつけたりするんですけど、朗読は情緒的な演技とナレーションの中間地点をやらなきゃいけないなと思っていて。
                  <br />
                  声優として、ナレーション的な一文の中にも感情を組み込むことを意識しています。
                </p>
                <img
                  src={require("../../../assets/images/interview/sayonara-no-mirai/img4.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―お聞きしていて、序盤から終盤までのセリフの中ですごく抑揚があって、聞いててかなり迫ってくるものがあり、少しずつ込められている感情が違うのを感じて感動しました！
                </p>
                <p>
                  <strong>土岐さん：</strong>
                  聴いてる人たちも、何かをしながら聴くこともあるでしょうし、家でぼーっとしながら聴くこともあると思います。
                  <br />
                  その方たちにこのチームの皆さまの想いが届けばなと思いつつ頑張らせていただきました。
                </p>
                <p>
                  <strong>小市さん：</strong>
                  そう受け取っていただけて嬉しいです！
                  <br />
                  ありがとうございます！
                </p>
                <p className={styles.interviewer}>
                  ―最後に視聴者の皆さんにメッセージをお願いします！
                </p>
                <p>
                  <strong>土岐さん：</strong>
                  SFって、昨今なかなか手に取るのが難しいと思うんですけど、ショートストーリーだからこそ手を出しやすいと思います。
                  <br />
                  ただその中にある、続きが気になる部分がこのストーリーには本当にたくさんあって。
                  <br />
                  果たしてこの二人がどうなるのかっていうのは僕もまだ未知数ですけど、皆様に良い意味でとても触れやすいストーリーになっていますので、ぜひ聴いていただければと思います。
                </p>
                <p>
                  <strong>小市さん：</strong>
                  例えば、通勤中などたとえ短い時間でも生活に彩りを与えられたら嬉しいです！
                  <br />
                  今日一日頑張ろう！とか、その人の心が動くものを届けられたらいいなと思っています。
                  <br />
                  音楽のように今日の朝はこの物語を聴こう！みたいなのが日常的になるとすごく嬉しいですね。
                </p>
                <p className={styles.interviewer}>
                  ―私たちもこのコンテンツの続きが生まれるように頑張ってこのサービスを、いいサービスに大きく成長させていきたいと思います！本日はありがとうございました！
                </p>
                <div className={styles.introduction}>
                  <p>
                    土岐 隼一（トキ シュンイチ） 声優。
                    <br />
                    <br />
                    5月7日生まれ、東京都出身。A型。出演作品は、『異世界召喚は二度目です』（セツ役）、『勇者が死んだ！』（トウカ・スコット／シオン・ブレイダン役）、『Paradox
                    Live THE
                    ANIMATION』（征木北斎役）、『東京リベンジャーズ』（羽宮一虎役）、『原神』（フレミネ役）、『プロジェクトセカイ
                    カラフルステージ! feat.初音ミク』（神代類役）など。
                  </p>
                </div>
                <div className={styles.introduction}>
                  <p>
                    小市 眞琴（コイチ マコト） 声優。
                    <br />
                    <br />
                    2月17日生まれ、東京都出身。出演作品は、『The Legend of
                    Heroes 閃の軌跡 Northern
                    War』（ラヴィアン・ウィンスレット役）、『るろうに剣心
                    -明治剣客浪漫譚-』（明神弥彦役）、『アンデッドガール・マーダーファルス』（馳井静句役）、『地獄楽』（ヌルガイ役）、『シャングリラ・フロンティア〜クソゲーハンター、神ゲーに挑まんとす〜』（オイカッツォ
                    / 魚臣慧役）、『アイドルマスター
                    シンデレラガールズ』（結城晴役）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼冒頭32秒を無料で視聴できます！／</p>
              <a
                href="/contents/sayonara-no-mirai"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/sayonara-no-mirai/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/sayonara-no-mirai/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  土岐 隼一<small>さん、</small>小市 眞琴<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で各2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「ボイスドラマの感想」と「どちらのサイン色紙を希望するか」を記載し引用リツイートしていただくと、抽選で土岐隼一さん・小市眞琴さん直筆色紙を各2名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/sayonara-no-mirai/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/sayonara-no-mirai/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/sayonara-no-mirai/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/sayonara-no-mirai/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/sayonara-no-mirai/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/sayonara-no-mirai/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/sayonara-no-mirai&text=土岐隼一「異質な恋愛での葛藤を表現できたらと思いました。」＆ 小市眞琴「やりがいのあるキャラクターでした。」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,土岐隼一,小市眞琴"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewSayonaraNoMirai;
