import HeadBlock from "../components/HeadBlock";
import styles from "../assets/styles/interviews.module.scss";
import Nav from "../components/Nav";
import { NavLink } from "react-router-dom";

function Interviews() {
  const interviews = [
    {
      contentId: "oshi-katsu",
      title:
        "小澤 麗那「ランダムグッズで一喜一憂するというのはとても共感できるなと思いました」",
    },
    {
      contentId: "melody",
      title:
        "佐藤 妃星「声優として最初の作品になるので、想いを込めて演じました」",
    },
    {
      contentId: "kuroda-san",
      title: "小林 裕介「一人二役のBLを演じるのは不思議な気持ちでした」",
    },
    {
      contentId: "fuyuzora-melt-reply",
      title: "小林 裕介「僕の方はギャグでいいよと言われてびっくりしました」",
    },
    {
      contentId: "kouen-no-maken",
      title:
        "岡本 信彦「主人公っぽくないところが魅力だと感じました」＆ 高森 奈津美「コミカルさみたいなのが本当にかわいらしく感じました」",
    },
    {
      contentId: "hug-time",
      title:
        "道井 悠「セリフ以外の部分で想像力を働かせられるよう演じました」＆ 長谷川 玲奈「ギャップが好きだなと思いました」",
    },
    {
      contentId: "noroi-no-rondo",
      title:
        "石見 舞菜香「この子の奥行きみたいなものを想像してもらえたらいいな」＆ 天﨑 滉平「飄々としていながらも、壮絶な部分を隠して生きている」",
    },
    {
      contentId: "sayonara-no-mirai",
      title:
        "土岐 隼一「異質な恋愛での葛藤を表現できたらと思いました」＆ 小市 眞琴「やりがいのあるキャラクターでした」",
    },
    {
      contentId: "fuyuzora-melt",
      title: "長谷川 育美「絶妙なラインの表現は新しい挑戦でした」",
    },
  ];

  return (
    <>
      <HeadBlock title="Creator’s interview" path="interviews" />
      <div className={styles.Interviews}>
        <div className={styles.interviews}>
          {interviews.map((interview, i) => (
            <div className={styles.interviews_item} key={i}>
              <div className={styles.thumbnail}>
                <img
                  src={require(
                    `../assets/images/interview/${interview.contentId}/thumbnail.png`,
                  )}
                  alt=""
                />
              </div>
              <div className={styles.item_text}>
                <p className={styles.title}>{interview.title}</p>
                <div className={styles.btn}>
                  <NavLink
                    className={styles.btn_secondary}
                    to={`/interview/${interview.contentId}`}
                    target="_blank"
                  >
                    インタビューを読む
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Nav />
    </>
  );
}

export default Interviews;
