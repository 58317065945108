import React from "react";
import HeadBlock from "../../components/HeadBlock";
import styles from "../../assets/styles/static.module.scss";
import Header from "../../components/Header";

function Requirements() {
  return (
    <>
      <HeadBlock title={"動作環境について"} path="requirements" />
      <Header />
      <div className={styles.requirements}>
        <h1>動作環境について</h1>
        <p>
          PAZLE では、下記の環境を動作環境としています。
          <br />
          動作環境以外のOS、ブラウザを使用している場合はサポートの対象外となります。
        </p>
        <section>
          <h2>Windows</h2>
          <ul>
            <li>Microsoft Edge 最新版</li>
            <li>Google Chrome 最新版</li>
          </ul>
        </section>
        <section>
          <h2>macOS</h2>
          <ul>
            <li>Google Chrome 最新版</li>
            <li>Safari 最新版</li>
          </ul>
        </section>
        <section>
          <h2>iOS/iPadOS</h2>
          <ul>
            <li>Safari 最新版</li>
          </ul>
        </section>
        <section>
          <h2>Android</h2>
          <ul>
            <li>Google Chrome 最新版</li>
          </ul>
        </section>
      </div>
    </>
  );
}

export default Requirements;
