import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MyContentsList from "./pages/my-contents/MyContentsList";
import Menu from "./pages/menu/Menu";
import NotFound from "./pages/NotFound";
import SignIn from "./pages/SignIn";
import Terms from "./pages/static/Terms";
import Tokutei from "./pages/static/Tokutei";
import Profile from "./pages/menu/ProfileEdit";
import { AuthProvider } from "./components/AuthContext";
import { NotificationProvider } from "./components/Notification";
import Top from "./pages/lp/About";
import Privacy from "./pages/static/Privacy";
import ContentFuyuzoraMelt from "./pages/lp/Content/ContentFuyuzoraMelt";
import InterViewFuyuzoraMelt from "./pages/lp/Interview/InterViewFuyuzoraMelt";
import ApplyFuyuzoraMelt from "./pages/lp/Apply/ApplyFuyuzoraMelt";
import MuteControllerProvider from "./components/MuteController";
import Maintenance from "./pages/Maintenance";
import Requirements from "./pages/static/Requirements";
import Purchase from "./pages/Purchase";
import ContentSayonaraNoMirai from "./pages/lp/Content/ContentSayonaraNoMirai";
import InterViewSayonaraNoMirai from "./pages/lp/Interview/InterViewSayonaraNoMirai";
import ApplySayonaraNoMirai from "./pages/lp/Apply/ApplySayonaraNoMirai";
import ContentNoroiNoRondo from "./pages/lp/Content/ContentNoroiNoRondo";
import InterViewNoroiNoRondo from "./pages/lp/Interview/InterViewNoroiNoRondo";
import ContentHugTime from "./pages/lp/Content/ContentHugTime";
import InterViewHugTime from "./pages/lp/Interview/InterViewHugTime";
import InterViewKouenNoMaken from "./pages/lp/Interview/InterViewKouenNoMaken";
import ContentKouenNoMaken from "./pages/lp/Content/ContentKouenNoMaken";
import Home from "./pages/Home";
import ApplyHugTime from "./pages/lp/Apply/ApplyHugTime";
import ContentsDetail from "./pages/ContentsDetail";
import ContentFuyuzoraMeltReply from "./pages/lp/Content/ContentFuyuzoraMeltReply";
import InterViewFuyuzoraMeltReply from "./pages/lp/Interview/InterViewFuyuzoraMeltReply";
import ApplyNoroiNoRondo from "./pages/lp/Apply/ApplyNoroiNoRondo";
import ContentKurodaSan from "./pages/lp/Content/ContentKurodaSan";
import InterViewKurodaSan from "./pages/lp/Interview/InterViewKurodaSan";
import ApplyKouenNoMaken from "./pages/lp/Apply/ApplyKouenNoMaken";
import stylesIcon from "./assets/styles/icon.module.scss";
import ContentMelody from "./pages/lp/Content/ContentMelody";
import InterViewMelody from "./pages/lp/Interview/InterViewMelody";
import ApplyFuyuzoraMeltReply from "./pages/lp/Apply/ApplyFuyuzoraMeltReply";
import ContentOshiKatsu from "./pages/lp/Content/ContentOshiKatsu";
import InterViewOshiKatsu from "./pages/lp/Interview/InterViewOshiKatsu";
import StreamsDetail from "./pages/StreamsDetail";

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  const [shownNoticeDev, setShownNoticeDev] = useState<boolean>(true);
  const [isUnderMaintenance] = useState<boolean>(false);

  return (
    <div className="App">
      {/* memo: 冬に使用 */}
      {/* <img src={require('./assets/images/snow.gif')} alt="" style={{position: "fixed", top: 0, width: "100%", pointerEvents: "none"}} /> */}
      <div
        style={{
          position: "relative",
          paddingTop:
            process.env.REACT_APP_ENV === "development" && shownNoticeDev
              ? "32px"
              : "0",
        }}
      >
        {process.env.REACT_APP_ENV === "development" && shownNoticeDev && (
          <div
            className="noticeDev"
            style={{
              position: "fixed",
              top: 0,
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "32px",
              padding: "0 8px",
              background: "red",
              color: "white",
              fontWeight: 600,
            }}
          >
            <p>※ これは検証環境です ※</p>
            <i
              className={stylesIcon.icon_close}
              style={{ position: "absolute", right: "8px", cursor: "pointer" }}
              onClick={() => setShownNoticeDev(false)}
            ></i>
          </div>
        )}
        <AuthProvider>
          <MuteControllerProvider>
            <NotificationProvider>
              <Routes location={background || location}>
                <Route path="/about" element={<Top />} />
                <Route
                  path="/contents/fuyuzora-melt/detail"
                  element={<ContentFuyuzoraMelt />}
                >
                  <Route
                    path="/contents/fuyuzora-melt/detail/present"
                    element={<ApplyFuyuzoraMelt />}
                  />
                </Route>
                <Route
                  path="/contents/sayonara-no-mirai/detail"
                  element={<ContentSayonaraNoMirai />}
                >
                  <Route
                    path="/contents/sayonara-no-mirai/detail/present"
                    element={<ApplySayonaraNoMirai />}
                  />
                </Route>
                <Route
                  path="/contents/noroi-no-rondo/detail"
                  element={<ContentNoroiNoRondo />}
                >
                  <Route
                    path="/contents/noroi-no-rondo/detail/present"
                    element={<ApplyNoroiNoRondo />}
                  />
                </Route>
                <Route
                  path="/contents/hug-time/detail"
                  element={<ContentHugTime />}
                >
                  <Route
                    path="/contents/hug-time/detail/present"
                    element={<ApplyHugTime />}
                  />
                </Route>
                <Route
                  path="/contents/kouen-no-maken/detail"
                  element={<ContentKouenNoMaken />}
                >
                  <Route
                    path="/contents/kouen-no-maken/detail/present"
                    element={<ApplyKouenNoMaken />}
                  />
                </Route>
                <Route
                  path="/contents/fuyuzora-melt-reply/detail"
                  element={<ContentFuyuzoraMeltReply />}
                >
                  <Route
                    path="/contents/fuyuzora-melt-reply/detail/present"
                    element={<ApplyFuyuzoraMeltReply />}
                  />
                </Route>
                <Route
                  path="/contents/kuroda-san/detail"
                  element={<ContentKurodaSan />}
                ></Route>
                <Route
                  path="/contents/melody/detail"
                  element={<ContentMelody />}
                ></Route>
                <Route
                  path="/contents/oshi-katsu/detail"
                  element={<ContentOshiKatsu />}
                ></Route>
                <Route
                  path="/contents/:contentsId"
                  element={
                    isUnderMaintenance ? <Maintenance /> : <ContentsDetail />
                  }
                />
                <Route
                  path="/streams/:streamsId"
                  element={
                    isUnderMaintenance ? <Maintenance /> : <StreamsDetail />
                  }
                />
                <Route
                  path="/contents"
                  element={isUnderMaintenance ? <Maintenance /> : <Home />}
                />
                <Route
                  path="/interviews"
                  element={isUnderMaintenance ? <Maintenance /> : <Home />}
                />
                <Route
                  path="/my-contents"
                  element={
                    isUnderMaintenance ? <Maintenance /> : <MyContentsList />
                  }
                />
                <Route
                  path="/menu"
                  element={isUnderMaintenance ? <Maintenance /> : <Menu />}
                />
                <Route
                  path="/menu/profile/edit"
                  element={isUnderMaintenance ? <Maintenance /> : <Profile />}
                />
                {/* <Route path="/menu/mail/edit" element={isUnderMaintenance ? <Maintenance /> : <Mail />} />
                <Route path="/menu/password/edit" element={isUnderMaintenance ? <Maintenance /> : <Password />} /> */}
                <Route
                  path="/purchase/:id"
                  element={isUnderMaintenance ? <Maintenance /> : <Purchase />}
                />
                <Route
                  path="/signin"
                  element={isUnderMaintenance ? <Maintenance /> : <SignIn />}
                />
                <Route
                  path="/interview/fuyuzora-melt"
                  element={<InterViewFuyuzoraMelt />}
                />
                <Route
                  path="/interview/sayonara-no-mirai"
                  element={<InterViewSayonaraNoMirai />}
                />
                <Route
                  path="/interview/noroi-no-rondo"
                  element={<InterViewNoroiNoRondo />}
                />
                <Route
                  path="/interview/hug-time"
                  element={<InterViewHugTime />}
                />
                <Route
                  path="/interview/kouen-no-maken"
                  element={<InterViewKouenNoMaken />}
                />
                <Route
                  path="/interview/fuyuzora-melt-reply"
                  element={<InterViewFuyuzoraMeltReply />}
                />
                <Route
                  path="/interview/kuroda-san"
                  element={<InterViewKurodaSan />}
                />
                <Route path="/interview/melody" element={<InterViewMelody />} />
                <Route
                  path="/interview/oshi-katsu"
                  element={<InterViewOshiKatsu />}
                />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/tokutei" element={<Tokutei />} />
                <Route path="/requirements" element={<Requirements />} />
                <Route
                  path="/"
                  element={isUnderMaintenance ? <Maintenance /> : <Home />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
              {background && (
                <Routes>
                  <Route
                    path="/contents/fuyuzora-melt/detail/present"
                    element={<ApplyFuyuzoraMelt />}
                  />
                  <Route
                    path="/contents/sayonara-no-mirai/detail/present"
                    element={<ApplySayonaraNoMirai />}
                  />
                  <Route
                    path="/contents/hug-time/detail/present"
                    element={<ApplyHugTime />}
                  />
                  <Route
                    path="/contents/noroi-no-rondo/detail/present"
                    element={<ApplyNoroiNoRondo />}
                  />
                  <Route
                    path="/contents/kouen-no-maken/detail/present"
                    element={<ApplyKouenNoMaken />}
                  />
                  <Route
                    path="/contents/fuyuzora-melt-reply/detail/present"
                    element={<ApplyFuyuzoraMeltReply />}
                  />
                </Routes>
              )}
            </NotificationProvider>
          </MuteControllerProvider>
        </AuthProvider>
      </div>
    </div>
  );
}

export default App;
