import React from "react";
import { Helmet } from "react-helmet-async";

interface HeadBlockProps {
  title?: string;
  path?: string;
}

const HeadBlock = (props: HeadBlockProps) => {
  const { title, path } = props;
  return (
    <Helmet>
      <title>{title ? `${title} - PAZLE` : "PAZLE"}</title>
      <meta property="og:title" content={title && title} />
      <meta
        property="og:url"
        content={`https://pazle.click/${path}` || "https://pazle.click/"}
      />
    </Helmet>
  );
};

export default HeadBlock;
