import { MouseEventHandler, ReactNode } from "react";
import styles from "../assets/styles/button.module.scss";

export const Button = ({
  children,
  className,
  variant = "primary",
  size = "m",
  disabled = false,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  variant?: "primary" | "secondary";
  size?: "m" | "s";
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  let styleVariant = styles.primary;
  let styleSize = styles.medium;

  switch (variant) {
    case "primary":
      styleVariant = styles.primary;
      break;
    case "secondary":
      styleVariant = styles.secondary;
      break;
  }
  switch (size) {
    case "m":
      styleSize = styles.medium;
      break;
    case "s":
      styleSize = styles.small;
      break;
  }
  return (
    <button
      type="button"
      className={`${styles.btn} ${styleSize} ${styleVariant} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
