import React, { useEffect, useState } from "react";
import { analytics, auth, db } from "../firebase";
import * as firebaseui from "firebaseui";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  TwitterAuthProvider,
} from "firebase/auth";
import loadJs from "loadjs";
import HeadBlock from "../components/HeadBlock";
import {
  Navigate,
  NavLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import { logEvent } from "firebase/analytics";
import { doc, setDoc, Timestamp } from "firebase/firestore";

function SignIn() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuthContext();

  useEffect(() => {
    if (searchParams.get("code"))
      localStorage.setItem("introduction_code", searchParams.get("code")!);

    const uiConfig = {
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        signInSuccessWithAuthResult: (authResult: any) => {
          const isNewUser = authResult.additionalUserInfo.isNewUser;
          const providerId = authResult.additionalUserInfo.providerId;

          if (isNewUser && providerId === "password")
            sendEmailVerification(authResult.user);
          if (isNewUser) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).twq("event", "tw-ofkye-ofyqg", {});
            logEvent(analytics, "register_member");
            if (localStorage.getItem("introduction_code")) {
              (async () => {
                setDoc(doc(db, "introductions", authResult.user.uid), {
                  introducerCode: localStorage.getItem("introduction_code"),
                  createdAt: Timestamp.now(),
                });
              })();
            }
          } else {
            logEvent(analytics, "login");
          }

          if (searchParams.get("contentId")) {
            navigate(
              `/purchase/${searchParams.get(
                "contentId",
              )}?chapter=${searchParams.get("chapter")}`,
            );
          } else if (searchParams.get("interViewId")) {
            navigate(`/interview/${searchParams.get("interViewId")}`, {
              state: { registerComplete: isNewUser },
            });
          } else {
            navigate("/", { state: { registerComplete: isNewUser } });
          }
          setIsLoading(false);
          return false;
        },
        signInFailure: () => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        },
      },
      signInFlow: "popup",
      signInOptions: [
        TwitterAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID,
        "apple.com",
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
      tosUrl: "/terms",
      privacyPolicyUrl: "/privacy",
    };

    loadJs(
      [
        "https://www.gstatic.com/firebasejs/9.9.2/firebase-app-compat.js",
        "https://www.gstatic.com/firebasejs/9.9.2/firebase-auth-compat.js",
        "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth__ja.js",
      ],
      {
        success: () => {
          let ui = firebaseui.auth.AuthUI.getInstance();
          if (!ui) {
            ui = new firebaseui.auth.AuthUI(auth);
          }
          ui.start("#firebaseui-auth-container", uiConfig);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return <Navigate to="/" />;
  } else {
    return (
      <>
        <HeadBlock title={"ログイン・アカウント登録"} path="signin" />
        <div className="SignIn">
          <div className="header">
            <NavLink to="/">
              <img
                src={require("../assets/images/logo.svg").default}
                className="logo"
                alt="PAZLE"
              />
            </NavLink>
            <p>
              購入してここでしか聴けない
              <br />
              <strong>ボイスドラマ</strong>を聴こう！
            </p>
          </div>
          <div id="firebaseui-auth-container" className="container"></div>
          <div className={`${isLoading ? "loading" : "loading hidden"}`}>
            <div>
              <img
                src={require("../assets/images/loading.svg").default}
                alt="loading"
              />
              <br />
              <span className="text">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignIn;
