import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "../assets/styles/notification.module.scss";
import iconStyles from "../assets/styles/icon.module.scss";

const NotificationContext = createContext<{
  setText: (text: string | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ setText: () => {} });

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [text, setText] = useState<string | null>(null);
  const [isDisplay, setIsDisplay] = useState<boolean>(false);
  const value = {
    setText,
  };

  useEffect(() => {
    if (text) {
      setIsDisplay(true);
      setTimeout(() => {
        setIsDisplay(false);
      }, 3000);
    }
  }, [text]);

  return (
    <NotificationContext.Provider value={value}>
      <div
        className={
          isDisplay
            ? styles.notification
            : styles.notification + " " + styles.hidden
        }
      >
        <div className={styles.inner}>
          <i className={iconStyles.icon_check_circle + " " + styles.icon}></i>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
