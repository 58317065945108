import React from "react";
import HeadBlock from "../../components/HeadBlock";
import styles from "../../assets/styles/static.module.scss";
import Header from "../../components/Header";

function Terms() {
  return (
    <>
      <HeadBlock title={"利用規約"} path="terms" />
      <Header />
      <div className={styles.terms}>
        <h1>利用規約</h1>
        <section>
          <h2>第１条（はじめに）</h2>
          <ol>
            <li>
              本利用規約（以下「本規約」といいます）は、VECTOR 合同会社（以下
              「当社」といいます）が
              本サイト上で提供する全てのサービス（以下「本サー
              ビス」）の利用に際して遵守していただく事項を定めています。
              本サービスの
              利用に際しては、本規約の全文をお読みいただいた上で、本規約の内容に同意
              いただく必要があります。
            </li>
            <li>
              本サービスには、本規約の他、弊社が別途定める各種規約、ガイドライン
              等（以下本規約と併せて「本規約等」といいます）が適用されるものとします。
              本規約と弊社が別途定める各種規約、ガイドライン等の定めが異なる場合には、
              後者の定めが優先して適用されます。
            </li>
          </ol>
        </section>
        <section>
          <h2>第２条（定義）</h2>
          <ol>
            <li>
              「本サイト」とは、当社が運営する「PAZLE」と称するウェブサイト (
              <a href="https://pazle.click/">https://pazle.click/</a>)
              をいいます。
            </li>
            <li>
              「本サービス」とは、本サイトまたは関連アプリを経由し提供する画像、
              テキスト、音声等の創作、閲覧、及び関連する全てのコンテンツの使用、配信、
              販売に関係するサービスをいいます。
            </li>
            <li>
              「ユーザー」とは、本サービス及び関連サービスを利用するために会員登
              録している者、及び会員登録を行わずに利用できるサービスを利用する個人を
              いいます。
            </li>
            <li>
              「登録メールアドレス」とは、本サービス及び関連サービスの提供を受け
              る目的で、登録ユーザーが当社に提供したメールアドレスの情報をいいます。
            </li>
            <li>
              「パスワード」とは、本サービス及び関連サービスを利用するに当たって、
              登録メールアドレスと照合して本人を識別するための文字列をいいます。
            </li>
            <li>
              「コンテンツ」とは、画像、テキスト、音声等本サービスを利用してクリ
              エイターにより投稿された画像等データのことをいいます。
            </li>
            <li>
              「クリエイター」とは、本サービスにコンテンツを投稿する者をいいます。
            </li>
          </ol>
        </section>
        <section>
          <h2>第３条（本規約等の変更）</h2>
          <p>
            本規約は、予告なしに変更されることがあります。ユーザーに個別通知するこ
            とは致しかねますので、ご利用の際には、随時、最新の利用規約を参照ください。本サービスの内容は、将来予告なく変更、追加、削除することがあります。
          </p>
        </section>
        <section>
          <h2>
            第４条（マイページ、登録メールアドレス、ID 及びパスワードの管理等）
          </h2>
          <ol>
            <li>
              ユーザーは、本サービスを利用するにあたっては、必要事項を入力のうえ、
              マイページを作成するものとします。
            </li>
            <li>
              ユーザーは、自分の管理に属する使用可能なメールアドレスを登録メールアドレスとして登録するものとし、当該登録メールアドレスが自己の管理に属さなくなった場合には、自己の管理に属する使用可能な別のメールアドレスに変更しなければならないものとします。
            </li>
            <li>
              ユーザーは、自己の登録メールアドレス、パスワード及びIDの不正利用の防止に努めるとともに、その管理について一切の責任を負うものとします。
              登録メールアドレス、パスワード及びIDが第三者に利用されたことによって生じた損害等につきましては、当社はいかなる責任も負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第５条（コンテンツの購入）</h2>
          <ol>
            <li>
              ユーザーは、本サービスが規定する決済方法により代金を支払うことでコンテンツを購入（ダウンロード）することができます。
            </li>
            <li>
              コンテンツは、マイページ上にダウンロードされたデータとして保存されますが、ユーザー自身が適宜バックアップ等の手段によりデータを保全するものとします。万が一、マイページ上のデータが何らかの事情により消失した場合いおいても、当社は一切の責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第６条（禁止事項等）</h2>
          <ol>
            <li>
              ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
              <ol>
                <li>
                  本サービスのアカウントを第三者に対して譲渡等をすること。
                </li>
                <li>
                  購入したコンテンツの転売行為、譲渡行為、その他これに類する行為。
                </li>
                <li>その他、本規約に違反する行為。</li>
              </ol>
            </li>
            <li>
              ユーザーが前項各号に違反していることが判明した場合には、当社は事前通告なく、ユーザーのアカウントを停止することがあります。その際、マイページ上に保存されたコンテンツが消失したとしても、当社は一切の責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第７条（コンテンツの権利関係及び責任主体）</h2>
          <p>
            本サービスを利用して投稿された画像、テキスト、音声等の情報の権利（著作権および著作者人格権等の周辺権利）は、創作したクリエイターに帰属します。
          </p>
        </section>
        <section>
          <h2>第８条（本サービスの提供内容の変更）</h2>
          <ol>
            <li>
              当社は、事前の予告なくして、本サービス及び関連サービスをいつでも任意の理由で追加、変更、中断、終了することができます。
            </li>
            <li>
              当社は、本サービス及び関連サービスの追加、変更、中断、終了に関し、一切の責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第９条（本サービスの提供の中断と中止）</h2>
          <ol>
            <li>
              本サービスは使用者様により良く楽しんでいただくため、事故を防ぐ対策を立てますが、自然災害、システム点検や障害、電気通信障害及びその他不可抗力が発生した場合、または弊社がサービスを中断せざるを得ないと判断した場合、使用者様に通達することなく本サービスの全部又は一部の提供を中断することがあります。
            </li>
            <li>
              当社は事前にユーザーに通知の上、本サービスの全部又は一部の提供を中止することがあります。
            </li>
            <li>
              前項による本サービスの中止に関し、その原因でユーザーまたは第三者に損害が発生した場合があっても、弊社は一切責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１１条（本サービスの提供の中断と中止）</h2>
          <ol>
            <li>
              本サービスは使用者様により良く楽しんでいただくため、事故を防ぐ対策を立てますが、自然災害、システム点検や障害、電気通信障害及びその他不可抗力が発生した場合、または弊社がサービスを中断せざるを得ないと判断した場合、使用者様に通達することなく本サービスの全部又は一部の提供を中断することがあります。
            </li>
            <li>
              当社は事前にユーザーに通知の上、本サービスの全部又は一部の提供を中止することがあります。
            </li>
            <li>
              前項による本サービスの中止に関し、その原因でユーザーまたは第三者に損害が発生した場合があっても、弊社は一切責任を負いません。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１０条（利用料金）</h2>
          <ol>
            <li>
              本サービスの基本機能の利用は登録の有無に関わらず無料とします。ただし、コンテンツを購入する場合には別途料金が発生します。
            </li>
            <li>
              有料サービスの内容・利用料金、支払方法等については、別途有料サービス利用規約の定めることとします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１１条（免責事項）</h2>
          <ol>
            <li>
              本サービスにより、ユーザーが入手したすべての情報などについて、その正確性、完全性、有用性等につき、当社は保証しないものとし、その責任を負わないものとします。
            </li>
            <li>
              本サービスにより、ユーザーがクリエイターその他第三者との間で紛争になった場合においても、当社は一切の責任を負いません。ユーザーは自身の費用と責任で当該紛争を解決するものとします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１２条（本規約及びその他の利用条件等の有効性）</h2>
          <ol>
            <li>
              本規約及びその他の利用条件等の規定の一部が法令に基づいて無効と判断されても、本規約及びその他の利用条件等のその他の規定は有効とします。
            </li>
            <li>
              利用規約等の規定の一部があるユーザーとの関係で無効とされ、又は取り消された場合でも、利用規約等はその他のユーザーとの関係では有効とします。
            </li>
          </ol>
        </section>
        <section>
          <h2>第１３条（準拠法及び管轄裁判所）</h2>
          <ol>
            <li>本規約の準拠法は、日本法とします。</li>
            <li>
              ユーザーと当社の間で訴訟の必要が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
        </section>
        <p>附則</p>
        <ul>
          <li>本規約は2023年7月1日から施行します。</li>
          <li>
            本規約の施行前にユーザーによって行われた行為についても本規約が適用されます。
          </li>
        </ul>
      </div>
    </>
  );
}

export default Terms;
