import HeadBlock from "../../../components/HeadBlock";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import styles from "../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import React, { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { Link } from "react-router-dom";
import imgLogoCreatorInterview from "../../../assets/images/interview/logo.svg";
import imgLogoPazleFooter from "../../../assets/images/content/logo_footer.svg";
import imgLogoPazleWhite from "../../../assets/images/logo_white.svg";
import imgLogoVector from "../../../assets/images/top_logo_vector.svg";
import imgArrowTop from "../../../assets/images/top_arrow_top.svg";

function InterViewFuyuzoraMeltReply() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={"小林 裕介「僕の方はギャグでいいよと言われてびっくりしました」"}
        path="interview/fuyuzora-melt-reply"
      />
      <div className={`${styles.Interview} ${styles.reply}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={imgLogoCreatorInterview}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/fuyuzora-melt-reply"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              小林 裕介「僕の方はギャグでいいよと言われてびっくりしました」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回はPAZLE大人気作品「想い、冬空メルト」の続編「Replay」の公開を記念し、少し天然でヘタレな男子高校生を演じた声優の小林
              裕介さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/fuyuzora-melt-reply/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―収録お疲れ様でした！続編ということで個人的にもとても楽しみにしていた収録です。
              <br />
              まず物語全体の印象はいかがでしたか？
            </p>
            <p>
              一人語りとして、モノローグの部分とセリフの部分を同時に録らせていただきました。
              <br />
              最初台本をいただいたときにアンサーストーリーとは？と思っていたら、もう既に長谷川育美さんが実紅視点のストーリーを録られていて配信されているということなので、なるほど以前の物語があった上での裏側の話というかTrueStoryのようなものなんだというのを知りました。
              <br />
              普通だったら、
              {user
                ? "一つの物語の中に女性サイド男性サイドを盛り込みますが、別々にストーリーを作るのは面白い試みだなと思いました。"
                : "..."}
            </p>
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=fuyuzora-melt-reply"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <p className={styles.interviewer}>
                  ―女性編（CV:長谷川
                  育美さん）へのアンサーストーリーとなっていますが、アンサーストーリーとして意識したところはありますか？
                </p>
                <p>
                  まずは物語の温度感だったり空気感は揃えた方がいいだろうなと思って、長谷川さんのドラマを聞いてから来ました。
                  <br />
                  長谷川さんはすごくストレートに、特にモノローグに関してはとつとつとやられている印象があったので、僕も世界観を合わせようと思ったところ、まさかの僕の方はギャグでいいよと言われて…えっ！みたいな。びっくりしました（笑）
                  <br />
                  なので結局準備したプランはほぼ崩れ、その場で即興で作り上げていきましたね。ただ、一番最後のセリフが長谷川さんと僕とで一緒なので、そこだけはしっかり合わせて締めようと決めてお芝居を組み上げていきました。
                </p>
                <img
                  src={require("../../../assets/images/interview/fuyuzora-melt-reply/img2.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―小林さん自身もどんどん熱くなり勢いが増していって素敵でした！
                  <br />
                  主人公の翠くんはきょどったり、覚悟を決めたり、感情の動きが大きいですが演じてみていかがでしたか？
                </p>
                <p>
                  最初からこんなにあれこれやるつもりはなかったので、いざデフォルメして演じてみるとセリフや文章の「間」をしっかりとらないと、翠くんがただただ感情の切り替わりが早いだけのちょっとおかしな子になっちゃうんですよね。
                  <br />
                  なので何テイクかやらせていただいて徐々に「間」の使い方を調整していきました。
                  <br />
                  でもすごい楽しかったのが、ディレクターの方が最初の説明時にはクールな佇まいだったんですけど、やっていくうちに、「いいですねっ！」「そのテンションのままいきましょう！！」「いただきますっ！！！」みたいにどんどん熱が入ってきて（笑）その熱量の高低が僕にとって良し悪しの基準みたいになってました。
                  <br />
                  きっと僕もスタッフさんにとっても想像とだいぶ違う仕上がりになったと思うんですけれども、結果すごく良いものになったなって感じますね。
                </p>

                <img
                  src={require("../../../assets/images/interview/fuyuzora-melt-reply/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―僕らも聴きながら何度も爆笑しちゃいました。とても楽しい現場で物語の雰囲気がよく出ていたと思います！
                  <br />
                  ところで、今回翠君の告白がうまくいった場合最初のデートはどんなシチュエーションがおすすめですか？
                  <br />
                </p>
                <p>
                  高校生ですもんね、定番は遊園地じゃないでしょうか。とは言っても翠くんは自分から誘わないと思うので、実紅ちゃんが率先して翠くんが楽しめる場所を探してくれるんじゃないかと。
                  <br />
                  ショッピングとかはあまり乗り気じゃないだろうなと思って、遊園地やアトラクション系を選ぶんじゃないかなと思います。
                  <br />
                  なので最初の取っ掛かりは実紅ちゃんに任せて、決まった後は文明の利器を使ってエスコートの工夫などを愚直に考えてほしいですね。
                </p>
                <p className={styles.interviewer}>
                  ―小林さんは遊園地に行ったら何に乗りますか？
                </p>
                <p>
                  絶叫系はコンプリートするでしょうね！中学生ぐらいになってようやく乗れるようになったんですけど、それ以降は友達と行くと乗れない人も無理やり乗せるようなちょっと悪い子に育ちました（笑）
                  <br />
                  お化け屋敷は嫌いなので、それはもう全力で逃げます！
                </p>

                <img
                  src={require("../../../assets/images/interview/fuyuzora-melt-reply/img4.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回高校生の瑞々しいストーリーとなっていますが、小林さんの高校時代の印象的なエピソードなどありますか？
                </p>
                <p>
                  高校生のときはもう部活しかやっていなかくて、僕バレーボール部だったんですけれども、うちの部活は監督がいなかったんですよ。
                  <br />
                  一応いたんですけれども大学生のOBの方で、そんな頻繁には来れなかったので基本的に自分たちであれやこれやメニューを組んで練習していました。
                  <br />
                  大変でしたけど自分たちのやりたいようにできる時間が多かった分、すごい思い出に残っていますし、もう全てを部活に捧げていました。
                </p>

                <p className={styles.interviewer}>
                  ―ありがとうございました！最後に視聴者の皆様へメッセージをお願いします
                </p>
                <p>
                  今回はアンサーストーリーということなので、もちろん僕が演じている部分だけでも実紅のセリフだったり、振る舞いっていうのは想像できるんですが、２つ合わせて聴いていただくのがこの作品のあるべき姿だと思います。
                  <br />
                  この機会に、実紅と翠の両方のストーリーを堪能していただけたら嬉しいです。
                </p>
                <div className={styles.introduction}>
                  <p>
                    小林 裕介（コバヤシ ユウスケ） 声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/fuyuzora-melt-reply"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/fuyuzora-melt-reply/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  小林裕介<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「想い、冬空メルト。〜reply〜」の感想を記載し引用リツイートしていただくと、抽選で2名様に小林裕介さん直筆色紙をプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/fuyuzora-melt-reply/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/fuyuzora-melt-reply/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/fuyuzora-melt-reply/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img src={imgLogoPazleFooter} alt="" />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/fuyuzora-melt-reply&text=小林 裕介「僕の方はギャグでいいよと言われてびっくりしました」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,小林裕介"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img src={imgArrowTop} alt="" />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img src={imgLogoVector} className={styles.vector} alt="" />
                <span>/</span>
                <img src={imgLogoPazleWhite} className={styles.pazle} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewFuyuzoraMeltReply;
