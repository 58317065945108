import Header from "../components/Header";
import Nav from "../components/Nav";
import styles from "../assets/styles/purchase.module.scss";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { analytics, db } from "../firebase";
import { PaymentMethodModal } from "../components/PaymentMethodModal";
import { logEvent } from "firebase/analytics";

function Purchase() {
  const { user } = useAuthContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<
    string | null
  >(null);
  const [title, seTitle] = useState<string>();
  const [thumbnailUrl, seThumbnailUrl] = useState<string>();
  const [chapter, setChapter] =
    useState<{ chapter: number; price: number }[]>();

  useEffect(() => {
    if (!user) return;
    if (!id || !searchParams.get("chapter")) {
      alert("作品あるいはチャプターが存在しません");
      navigate("/contents");
      return;
    }
    (async () => {
      const snapContent = await getDoc(doc(db, "contents", id));
      if (!snapContent.exists()) {
        alert("作品あるいはチャプターが存在しません");
        navigate("/contents");
      }
      const snapChapters = await getDocs(
        query(collection(db, "contents", id, "chapters")),
      );

      if (searchParams.get("chapter")! !== "all") {
        if (
          snapChapters.docs.length === 0 ||
          snapChapters.docs.filter(
            (ch) => ch.id === searchParams.get("chapter"),
          ).length === 0
        ) {
          alert("作品あるいはチャプターが存在しません");
          navigate("/contents");
        }
      } else {
        if (
          snapChapters.docs.length === 0 ||
          snapChapters.docs.filter((ch) => ch.id === "2" || ch.id === "3")
            .length === 0
        ) {
          alert("作品あるいはチャプターが存在しません");
          navigate("/contents");
        }
      }

      let isPurchased: boolean;
      let unpurchasedChapter: string | null;
      let unpurchasedChapterPrice: number | null;
      if (searchParams.get("chapter")! !== "all") {
        const docSnap = await getDoc(
          doc(
            db,
            "users",
            user.uid,
            "contents",
            id,
            "chapters",
            searchParams.get("chapter")!,
          ),
        );
        isPurchased = docSnap.exists();
      } else {
        const docSnap = await getDocs(
          query(
            collection(db, "users", user.uid, "contents", id, "chapters"),
            where(documentId(), "in", ["2", "3"]),
          ),
        );
        isPurchased = docSnap.docs.length === 2;

        unpurchasedChapter =
          docSnap.docs.length === 1
            ? docSnap.docs[0].id === "2"
              ? "3"
              : "2"
            : null;
        unpurchasedChapterPrice =
          docSnap.docs.length === 1
            ? snapChapters.docs
                .filter((sc) => sc.id === unpurchasedChapter)[0]
                .data().price
            : null;
      }
      if (isPurchased) {
        alert("すでに購入された作品です");
        logEvent(analytics, "completed_purchase", {
          timing: localStorage.getItem("purchase_button_click_location"),
          unpurchased: false,
        });
        if (searchParams.get("chapter")! === "all")
          navigate(
            `/my-contents?id=${id}&chapter=${searchParams.get(
              "chapter",
            )!}&isPlaying=true`,
          );
        else
          navigate(
            `/my-contents?id=${id}&chapter=${searchParams.get(
              "chapter",
            )!}&isPlaying=true`,
          );
        return;
      }

      seTitle(snapContent.data()?.title);
      seThumbnailUrl(
        `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${id}.jpg?alt=media`,
      );
      if (searchParams.get("chapter")! !== "all") {
        setChapter([
          {
            chapter: Number(searchParams.get("chapter")!),
            price: snapChapters.docs
              .filter((ch) => ch.id === searchParams.get("chapter")!)[0]
              .data().price,
          },
        ]);
      } else {
        if (unpurchasedChapter! === "2" || unpurchasedChapter! === "3") {
          setChapter([
            {
              chapter: Number(unpurchasedChapter),
              price: unpurchasedChapterPrice!,
            },
          ]);
        } else {
          setChapter(
            snapChapters.docs
              .filter((ch) => ch.id === "2" || ch.id === "3")
              .map((ch) => {
                return {
                  chapter: Number(ch.id),
                  price: Number(ch.data().price),
                };
              }),
          );
        }
      }
      setShowPaymentMethodModal(id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return (
      <Navigate
        to={`/signin?contentId=${id}&chapter=${searchParams.get("chapter")}`}
      />
    );
  } else {
    return (
      <>
        <Header />
        <div className={styles.purchase}>
          {id && title && thumbnailUrl && chapter && showPaymentMethodModal ? (
            <PaymentMethodModal
              contentId={id}
              title={title}
              thumbnailUrl={thumbnailUrl}
              chapter={chapter}
              setShownModal={setShowPaymentMethodModal}
              closeButton={false}
            />
          ) : (
            <p>購入準備中...</p>
          )}
        </div>
        <Nav />
      </>
    );
  }
}

export default Purchase;
