import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { createRef, MutableRefObject, useEffect, useState } from "react";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import HeadBlock from "../components/HeadBlock";
import Header from "../components/Header";
import { SigninModal } from "../components/SigninModal";
import { analytics, auth, db, functions, storage } from "../firebase";
import { Actor, Chapter, Content } from "../utils/types";
import styles from "../assets/styles/contentsDetail.module.scss";
import { Button } from "../components/Button";
import { useSwipeable } from "react-swipeable";
import { ContentsView } from "../components/ContentsView";
import { getDownloadURL, ref } from "firebase/storage";
import { PaymentMethodModal } from "../components/PaymentMethodModal";
import { logEvent } from "firebase/analytics";

export const ContentsDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { contentsId } = useParams();
  const { user } = useAuthContext();
  const [content, setContent] = useState<Content>();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<
    string | null
  >(null);
  const [purchasedContent, setPurchasedContent] = useState<{
    id: string;
    chapters: number[];
  }>();
  const [shownModalSignin, setShownModalSignin] = useState<string | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const [showContentsModal, setShowContentsModal] = useState<string | null>(
    null,
  );
  const [chapter, setChapter] =
    useState<{ chapter: number; price: number }[]>();
  const containerRef = createRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!contentsId) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).twq("event", "tw-ofkye-ofypn", {});
    (async () => {
      const snap = await getDoc(doc(db, "contents", contentsId));
      if (!snap.exists()) {
        alert("作品が見つかりませんでした");
        navigate("/contents");
        return;
      }

      const snapCh = await getDocs(
        collection(db, "contents", contentsId, "chapters"),
      );
      const chapters = await Promise.all(
        snapCh.docs.map(async (ch) => {
          const actors = await Promise.all(
            ch.data().actors.map(async (a: Actor) => {
              return {
                name: a.name,
                role: a.role,
                image: await getActorImageUrl(a.image).then((url) => url),
              };
            }),
          );

          return {
            index: Number(ch.id),
            actors: actors,
            price: ch.data().price,
            outline: ch.data().outline,
            sampleTotalTime: ch.data().sampleTotalTime,
            totalTime: ch.data().totalTime,
            createdAt: ch.data().createdAt,
          } as Chapter;
        }),
      );

      const actors = await Promise.all(
        snap.data().actors.map(async (a: Actor) => {
          return {
            name: a.name,
            role: a.role,
            image: await getActorImageUrl(a.image).then((url) => url),
          };
        }),
      );

      setContent({
        id: snap.id,
        title: snap.data().title,
        category: snap.data().category,
        actors: actors,
        highlight: snap.data().highlight,
        bgColor: snap.data().bgColor,
        thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${snap.id}.jpg?alt=media`,
        chapters,
        createdAt: snap.data().createdAt,
      });
    })();

    (async () => {
      if (!user) return;

      const snap = await getDocs(
        collection(db, "users", user.uid, "contents", contentsId, "chapters"),
      );
      if (snap.docs.length === 0) return;
      setPurchasedContent({
        id: contentsId,
        chapters: snap.docs.map((ch) => Number(ch.id)),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("isPlaying"))
      navigate(`/contents/${contentsId}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const chapter = Number(searchParams.get("chapter"));

    if (!searchParams.get("isPlaying")) {
      setShowContentsModal(null);
    } else {
      if (!content) return;
      if (!content.chapters[chapter - 1].videoUrl) {
        setIsLoadingVideo(true);
        getVideoUrl(content.id, chapter - 1).then((url) => {
          content.chapters[chapter - 1].videoUrl = url;
          setShowContentsModal(content.id);
          setIsLoadingVideo(false);
          setShowContentsModal(contentsId ? contentsId : null);
        });
      } else {
        setIsLoadingVideo(false);
        setShowContentsModal(contentsId ? contentsId : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getVideoUrl = async (id: string, cIndex: number): Promise<string> => {
    if (content?.chapters[cIndex].price === 0) {
      const getContentUrl = httpsCallable(functions, "getContentUrl");
      const url = getContentUrl({
        contentId: `${id}_${content?.chapters[cIndex].index}`,
      }).then((result) => result.data as string);
      return url;
    } else {
      if (
        content &&
        purchasedContent &&
        purchasedContent.chapters.includes(content.chapters[cIndex].index)
      ) {
        const getContentUrl = httpsCallable(functions, "getContentUrl");
        if (content.chapters.length > 1) {
          const url = getContentUrl({
            contentId: `${id}_${content?.chapters[cIndex].index}`,
          }).then((result) => result.data as string);
          return url;
        } else {
          const url = getContentUrl({ contentId: id }).then(
            (result) => result.data as string,
          );
          return url;
        }
      } else {
        return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fsamples%2F${id}.mp4?alt=media`;
      }
    }
  };

  const getActorImageUrl = async (fileName: string): Promise<string> => {
    return getDownloadURL(ref(storage, `contents/actors/${fileName}.png`));
  };

  const handlers = useSwipeable({
    onSwiped: (event) => {
      if (event.dir === "Up" || event.dir === "Down") {
        navigate(`/contents/${content?.id}`, { replace: true });
      }
    },
    trackMouse: true,
  });

  const convertTime = (second: number): string => {
    const timeM = Math.floor(((second % (24 * 60 * 60)) % (60 * 60)) / 60);
    const timeS = ((second % (24 * 60 * 60)) % (60 * 60)) % 60;
    if (timeM > 0) return `${timeM}分${timeS}秒`;
    else return `${timeS}秒`;
  };

  const clickButtonBuy = (
    content: Content,
    chapter: { chapter: number; price: number }[],
    isInBulk: boolean,
  ) => {
    setChapter(chapter);
    const user = auth.currentUser;

    logEvent(analytics, "click_purchase_button", {
      is_login: user ? true : false,
      timing: "detail",
      is_in_bulk: isInBulk,
    });
    localStorage.setItem("purchase_button_click_location", "detail");

    if (!user) {
      setShownModalSignin(content.id);
      return;
    }

    (async () => {
      let isPurchased: boolean;
      if (chapter.length === 1) {
        const docSnap = await getDoc(
          doc(
            db,
            "users",
            user.uid,
            "contents",
            content.id,
            "chapters",
            String(chapter[0].chapter),
          ),
        );
        isPurchased = docSnap.exists();
      } else {
        const docSnap = await getDocs(
          query(
            collection(
              db,
              "users",
              user.uid,
              "contents",
              content.id,
              "chapters",
            ),
            where(documentId(), "in", ["2", "3"]),
          ),
        );
        isPurchased = docSnap.docs.length > 0;
      }

      if (isPurchased) {
        alert("すでに購入された作品です");
        logEvent(analytics, "completed_purchase", {
          timing: localStorage.getItem("purchase_button_click_location"),
          unpurchased: false,
        });
        navigate(
          `/my-contents?id=${content.id}&chapter=${chapter[0].chapter}&isPlaying=true`,
        );
        return;
      } else {
        setShowPaymentMethodModal(content.id);
      }
    })();
  };

  return (
    <>
      <HeadBlock
        title={content?.title && content?.title}
        path={`contents/detail/${contentsId}`}
      />
      <Header buckButtonTarget={`/contents?id=${contentsId}`} />
      {content && (
        <div className={styles.contentsDetail}>
          <img
            className={styles.thumbnail}
            src={`https://pazle.click/ogp_${content.id}.png`}
            alt=""
          />
          <h2>{content.title}</h2>
          <p className={styles.chapter}>
            全{content.chapters.length}チャプター /{" "}
            {convertTime(
              content.chapters.reduce((sum, ch) => sum + ch.totalTime, 0),
            )}
          </p>

          <div className={styles.buttonBulk}>
            {content.chapters.length !== 1 &&
              (!purchasedContent ||
                purchasedContent?.chapters.length === 0) && (
                <Button
                  onClick={() =>
                    clickButtonBuy(
                      content,
                      [
                        { chapter: 2, price: content.chapters[1].price },
                        { chapter: 3, price: content.chapters[2].price },
                      ],
                      true,
                    )
                  }
                  disabled={
                    content.chapters.length ===
                    purchasedContent?.chapters.length
                  }
                >
                  まとめて購入する ¥
                  {content.chapters[1].price + content.chapters[2].price}
                </Button>
              )}
          </div>

          <ul className={styles.chapterList}>
            {content.chapters.map((c, i) => (
              <li key={i}>
                <div>
                  <p className={styles.chapterTitle}>Chapter.{c.index}</p>
                  <p className={styles.chapterTime}>
                    {convertTime(c.totalTime)}
                    {c.sampleTotalTime !== 0 &&
                      c.totalTime !== c.sampleTotalTime &&
                      `（無料視聴：${convertTime(c.sampleTotalTime)}）`}
                  </p>
                </div>
                <div className={styles.buttons}>
                  {c.totalTime === c.sampleTotalTime ? (
                    <div className={styles.buttonSecondary}>
                      <Button
                        size="s"
                        variant="secondary"
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (window as any).twq("event", "tw-ofkye-ofypp", {});
                          logEvent(analytics, "click_play_button");
                          navigate(
                            `/contents/${content.id}?chapter=${c.index}&isPlaying=true`,
                          );
                        }}
                      >
                        無料で視聴する
                      </Button>
                    </div>
                  ) : purchasedContent?.chapters.includes(c.index) ? (
                    <div className={styles.buttonSecondary}>
                      <Button
                        size="s"
                        variant="secondary"
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (window as any).twq("event", "tw-ofkye-ofypp", {});
                          logEvent(analytics, "click_play_button");
                          navigate(
                            `/contents/${content.id}?chapter=${c.index}&isPlaying=true`,
                          );
                        }}
                      >
                        視聴する
                      </Button>
                    </div>
                  ) : (
                    <>
                      {c.sampleTotalTime !== 0 &&
                        c.totalTime !== c.sampleTotalTime && (
                          <div className={styles.buttonSecondary}>
                            <Button
                              size="s"
                              variant="secondary"
                              onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                (window as any).twq(
                                  "event",
                                  "tw-ofkye-ofypp",
                                  {},
                                );
                                logEvent(analytics, "click_play_button");
                                navigate(
                                  `/contents/${content.id}?chapter=${c.index}&isPlaying=true`,
                                );
                              }}
                            >
                              視聴する
                            </Button>
                          </div>
                        )}
                      <Button
                        size="s"
                        onClick={() => {
                          clickButtonBuy(
                            content,
                            [{ chapter: c.index, price: c.price }],
                            false,
                          );
                        }}
                      >{`¥${c.price}`}</Button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <section>
            <h3>作品の見どころ</h3>
            <pre>{content.highlight.replaceAll("\\n", "\n")}</pre>
          </section>

          <section>
            <h3>登場人物</h3>
            <ul className={styles.actorList}>
              {content.actors.map((a, i) =>
                a.role ? (
                  <li key={i}>
                    {a.role}（{a.name}）
                  </li>
                ) : (
                  <li key={i}>{a.name}</li>
                ),
              )}
            </ul>
          </section>

          {content.id === "hug-time" && (
            <section>
              <h3>関連配信</h3>
              <NavLink
                to={"/streams/pazle-talk-1"}
                className={styles.relatedWorksImg}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/ogp_pazle-talk-1.png`}
                  alt=""
                />
              </NavLink>
            </section>
          )}

          {content.id === "melody" && (
            <section>
              <h3>関連配信</h3>
              <NavLink
                to={"/streams/pazle-talk-2"}
                className={styles.relatedWorksImg}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/ogp_pazle-talk-2.png`}
                  alt=""
                />
              </NavLink>
            </section>
          )}

          {/* pazle-talk-4差し込み */}
          {(content.id === "kuroda-san" ||
            content.id === "fuyuzora-melt-reply") && (
            <section>
              <h3>関連配信</h3>
              <NavLink
                to={"/streams/pazle-talk-4"}
                className={styles.relatedWorksImg}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/ogp_pazle-talk-4.png`}
                  alt=""
                />
              </NavLink>
            </section>
          )}

          {(content.id === "fuyuzora-melt" ||
            content.id === "fuyuzora-melt-reply" ||
            content.id === "hug-time" ||
            content.id === "melody") && (
            <section>
              <h3>関連作品</h3>
              {content.id === "fuyuzora-melt" && (
                <a
                  href={`/contents/fuyuzora-melt-reply?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_fuyuzora-melt-reply.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "fuyuzora-melt-reply" && (
                <a
                  href={`/contents/fuyuzora-melt?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_fuyuzora-melt.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "hug-time" && (
                <a
                  href={`/contents/melody?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img src={"https://pazle.click/ogp_melody.png"} alt="" />
                </a>
              )}
              {content.id === "melody" && (
                <a
                  href={`/contents/hug-time?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img src={"https://pazle.click/ogp_hug-time.png"} alt="" />
                </a>
              )}
            </section>
          )}

          {content.id !== "promotion" && content.id !== "promotion-toki" && (
            <section>
              <h3>インタビュー</h3>
              <a
                href={`/interview/${content.id}?relation=${content.id}`}
                target="_blank"
                className={styles.relatedWorksImg}
                rel="noreferrer"
              >
                <img
                  src={require(
                    `../assets/images/interview/${content.id}/thumbnail.png`,
                  )}
                  alt=""
                />
              </a>
            </section>
          )}

          {(content.id === "kouen-no-maken" ||
            content.id === "noroi-no-rondo" ||
            content.id === "sayonara-no-mirai" ||
            content.id === "hug-time" ||
            content.id === "promotion" ||
            content.id === "promotion-toki" ||
            content.id === "kuroda-san" ||
            content.id === "fuyuzora-melt-reply") && (
            <section>
              <h3>この作品を視聴した人におすすめ</h3>
              {content.id === "kouen-no-maken" && (
                <a
                  href={`/contents/noroi-no-rondo?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_noroi-no-rondo.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "noroi-no-rondo" && (
                <a
                  href={`/contents/kouen-no-maken?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_kouen-no-maken.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "sayonara-no-mirai" && (
                <a
                  href={`/contents/promotion-toki?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_promotion-toki.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "hug-time" && (
                <a
                  href={`/contents/promotion?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img src={"https://pazle.click/ogp_promotion.png"} alt="" />
                </a>
              )}
              {content.id === "kuroda-san" && (
                <a
                  href={`/contents/fuyuzora-melt-reply?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_fuyuzora-melt-reply.png"}
                    alt=""
                  />
                </a>
              )}
              {content.id === "fuyuzora-melt-reply" && (
                <a
                  href={`/contents/kuroda-san?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img src={"https://pazle.click/ogp_kuroda-san.png"} alt="" />
                </a>
              )}
              {content.id === "promotion" && (
                <a
                  href={`/contents/hug-time?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img src={"https://pazle.click/ogp_hug-time.png"} alt="" />
                </a>
              )}
              {content.id === "promotion-toki" && (
                <a
                  href={`/contents/sayonara-no-mirai?relation=${content.id}`}
                  target="_blank"
                  className={styles.relatedWorksImg}
                  rel="noreferrer"
                >
                  <img
                    src={"https://pazle.click/ogp_sayonara-no-mirai.png"}
                    alt=""
                  />
                </a>
              )}
            </section>
          )}

          {isLoadingVideo && (
            <div className={styles.loading_video}>
              <p>読み込み中...</p>
            </div>
          )}
        </div>
      )}

      {showContentsModal === content?.id && (
        <div ref={containerRef}>
          <div {...handlers}>
            <ContentsView
              containerRef={containerRef}
              c={content}
              currentContentId={content.id}
              purchasedContent={purchasedContent}
              setShowContentsModal={setShowContentsModal}
            />
          </div>
        </div>
      )}

      {content && chapter && showPaymentMethodModal === content.id && (
        <PaymentMethodModal
          contentId={content.id}
          title={content.title}
          thumbnailUrl={content.thumbnailUrl}
          chapter={chapter}
          setShownModal={setShowPaymentMethodModal}
        />
      )}

      {content && chapter && shownModalSignin === content.id && (
        <SigninModal
          setShownModalSignin={setShownModalSignin}
          shownModalSignin={shownModalSignin}
          chapter={chapter.map((ch) => ch.chapter)}
        />
      )}
    </>
  );
};

export default ContentsDetail;
