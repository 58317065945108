import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "../assets/styles/nav.module.scss";

export const Nav = () => {
  const location = useLocation();

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <NavLink
            to="/contents"
            className={({ isActive }) =>
              isActive || location.pathname === "/interviews"
                ? styles.active
                : ""
            }
          >
            <i className={styles.home} />
            <span>ホーム</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/my-contents"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <i className={styles.mycontents} />
            <span>購入作品</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/menu"
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            <i className={styles.menu} />
            <span>メニュー</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
