import { logEvent } from "firebase/analytics";
import {
  createRef,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { analytics, auth, db } from "../firebase";
import { useAuthContext } from "./AuthContext";
import styles from "../assets/styles/contentsView.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import { Content } from "../utils/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "./Button";
import { doc, getDoc } from "firebase/firestore";
import { SigninModal } from "./SigninModal";
import { PaymentMethodModal } from "./PaymentMethodModal";
import { v4 as uuidv4 } from "uuid";

export const ContentsView = ({
  containerRef,
  c,
  purchasedContent,
  currentContentId,
  setShowContentsModal,
}: {
  containerRef: MutableRefObject<HTMLDivElement>;
  c: Content;
  currentContentId: string | undefined;
  purchasedContent: { id: string; chapters: number[] } | undefined;
  setShowContentsModal: Dispatch<SetStateAction<string | null>>;
}) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMutedVideo, setIsMutedVideo] = useState<boolean>(false);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement>();
  const [shownDetail, setShownDetail] = useState<boolean>(false);
  const [shownGuideCsp, setShownGuideCsp] = useState<boolean>(false);
  const [shownChapterList, setShownChapterList] = useState<boolean>(false);
  const [isEndedVideo, setIsEndedVideo] = useState<boolean>(false);
  const [isPausedVideo, setIsPausedVideo] = useState<boolean>(false);
  const [isMovingSeekbar, setIsMovingSeekbar] = useState<boolean>(false);
  const [isClickedSkip, setIsClickedSkip] = useState<boolean>(false);
  const [shownController, setShownController] = useState<boolean>(false);
  const [chapter, setChapter] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timeId, setTimeId] = useState<any>();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<
    string | null
  >(null);
  const [shownModalSignin, setShownModalSignin] = useState<string | null>(null);
  const progressBarValueRef = createRef<HTMLSpanElement>();
  const progressBarHundleRef = createRef<HTMLSpanElement>();
  const [logo, setLogo] = useState<string | undefined>();

  useEffect(() => {
    setVideoRef(containerRef.current.getElementsByTagName("video")[0]);
  }, [containerRef]);

  useEffect(() => {
    if (searchParams.get("chapter"))
      setChapter(Number(searchParams.get("chapter")));
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("isPlaying")) {
      if (videoRef) {
        videoRef.pause();
        videoRef.currentTime = 0;
      }
    } else {
      if (videoRef?.id === currentContentId)
        videoRef
          ?.play()
          .catch(() => videoRef?.play().catch(() => setShownController(true)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, videoRef]);

  useEffect(() => {
    const deletedCsp = JSON.parse(localStorage.getItem("deleted_csp")!);
    if (!deletedCsp) {
      setShownGuideCsp(true);
    } else {
      setShownGuideCsp(false);
    }
  }, []);

  useEffect(() => {
    try {
      const logo = require(`../assets/images/content/${c.id}/logo.svg`);
      setLogo(logo);
    } catch {
      setLogo(undefined);
    }
  });

  useEffect(() => {
    if (videoRef) videoRef.muted = isMutedVideo;
  }, [isMutedVideo, videoRef]);

  useEffect(() => {
    if (isEndedVideo) {
      setShownController(false);
      return;
    }

    if (isClickedSkip || isMovingSeekbar) {
      clearTimeout(timeId);
      setTimeId(undefined);
      setIsClickedSkip(false);
      return;
    }

    if (shownController) {
      setTimeId(
        setTimeout(() => {
          if (shownController && !videoRef?.paused) {
            setShownController(false);
          }
        }, 2000),
      );
    }

    if (!shownController) {
      clearTimeout(timeId);
      setTimeId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shownController,
    videoRef?.paused,
    isEndedVideo,
    isClickedSkip,
    isMovingSeekbar,
  ]);

  const pausePlay = () => {
    if (!videoRef) return;

    if (videoRef.paused) {
      videoRef.play().catch(() => {
        videoRef!.pause();
      });
    } else {
      videoRef.pause();
    }
  };

  const onPlaying = () => {
    setIsEndedVideo(false);
    setIsPausedVideo(false);
  };

  const onEnded = () => {
    setIsEndedVideo(true);
    setIsPausedVideo(false);

    if (
      c.chapters.length > 1 &&
      purchasedContent?.chapters.includes(chapter + 1)
    ) {
      searchParams.set("chapter", `${chapter + 1}`);
      setSearchParams(searchParams);
    }
  };

  const onPause = () => {
    setIsPausedVideo(true);
  };

  const clickButtonReplay = () => {
    if (!videoRef) return;
    videoRef.play();
    videoRef.currentTime = 0;
  };

  const updateProgress = () => {
    if (!videoRef) return;
    const duration = videoRef.duration;
    const currentTime = videoRef.currentTime;
    const progress = `${(currentTime / duration) * 100}%`;

    if (progressBarValueRef.current)
      progressBarValueRef.current!.style.width = progress;
    if (progressBarHundleRef.current)
      progressBarHundleRef.current!.style.left = progress;
  };

  const onTouchStartHundle = () => {
    const listener = () => {
      document.removeEventListener("touchend", listener, true);
      document.removeEventListener("touchmove", touchMovePositionHundle, true);
      setIsMovingSeekbar(false);
    };
    document.addEventListener("touchend", listener, true);
    document.addEventListener("touchmove", touchMovePositionHundle, true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const touchMovePositionHundle = (e: any) => {
    if (!videoRef) return;
    const min = 0;
    const max = videoRef.getBoundingClientRect().width - 32;
    const cursorPosotion = e.changedTouches[0].pageX;
    const movePosition =
      cursorPosotion < min ? min : cursorPosotion > max ? max : cursorPosotion;
    const duration = videoRef.duration;
    const cursorPosotionTime = (cursorPosotion / max) * duration;
    const movePositionTime =
      cursorPosotionTime < min
        ? min
        : cursorPosotionTime > duration
          ? duration
          : cursorPosotionTime;

    if (progressBarHundleRef.current)
      progressBarHundleRef.current!.style.left = `${movePosition}px`;
    if (progressBarValueRef.current)
      progressBarValueRef.current!.style.width = `${movePosition}px`;
    videoRef.currentTime = movePositionTime;
  };

  const onMouseDownHundle = () => {
    const listener = () => {
      document.removeEventListener("mouseup", listener, true);
      document.removeEventListener("mousemove", movePositionHundle, true);
      setIsMovingSeekbar(false);
    };
    document.addEventListener("mouseup", listener, true);
    document.addEventListener("mousemove", movePositionHundle, true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const movePositionHundle = (e: any) => {
    if (!videoRef) return;
    const min = 0;
    const max = videoRef.getBoundingClientRect().width;
    const videoLeft = videoRef.getBoundingClientRect().left;
    const cursorPosotion = e.x - videoLeft;
    const movePosition =
      cursorPosotion < min ? min : cursorPosotion > max ? max : cursorPosotion;
    const duration = videoRef.duration;
    const cursorPosotionTime = (cursorPosotion / max) * duration;
    const movePositionTime =
      cursorPosotionTime < min
        ? min
        : cursorPosotionTime > duration
          ? duration
          : cursorPosotionTime;

    if (progressBarHundleRef.current)
      progressBarHundleRef.current!.style.left = `${movePosition}px`;
    if (progressBarValueRef.current)
      progressBarValueRef.current!.style.width = `${movePosition}px`;
    videoRef.currentTime = movePositionTime;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickSkipBtn = (e: any, number: number) => {
    e.stopPropagation();
    setIsClickedSkip(true);
    if (videoRef) {
      videoRef.currentTime = videoRef.currentTime + number;
    }

    if (!user && !localStorage.getItem("uid")) {
      const uuid = uuidv4();
      localStorage.setItem("uid", `temp-${uuid}`);
    }

    logEvent(analytics, "click_skip_button", {
      is_login: user ? true : false,
      unpurchased: purchasedContent
        ? !purchasedContent.chapters.includes(c.chapters[chapter - 1].index)
        : true,
      type: number > 0 ? "forward" : "replay",
      firebase_uid: user ? user.uid : localStorage.getItem("uid"),
      time: new Date(),
    });
  };

  const convertTime = (second: number): string => {
    const timeM = Math.floor(((second % (24 * 60 * 60)) % (60 * 60)) / 60);
    const timeS = ((second % (24 * 60 * 60)) % (60 * 60)) % 60;
    if (timeM > 0) return `${timeM}分${timeS}秒`;
    else return `${timeS}秒`;
  };

  const clickButtonBuy = (
    content: Content,
    chapter: number,
    timing: "in_watching" | "after_watching" | "chapter",
  ) => {
    if (videoRef) videoRef.pause();
    const user = auth.currentUser;

    logEvent(analytics, "click_purchase_button", {
      is_login: user ? true : false,
      timing,
    });
    localStorage.setItem("purchase_button_click_location", timing);

    if (!user) {
      setShownModalSignin(c.id);
      return;
    }

    (async () => {
      const snap = await getDoc(
        doc(
          db,
          "users",
          user.uid,
          "contents",
          content.id,
          "chapters",
          String(chapter),
        ),
      );
      const isPurchased = snap.exists();
      if (isPurchased) {
        alert("すでに購入された作品です");
        logEvent(analytics, "completed_purchase", {
          timing: localStorage.getItem("purchase_button_click_location"),
          unpurchased: false,
        });
        navigate(
          `/my-contents?id=${content.id}&chapter=${chapter}&isPlaying=true`,
        );
        return;
      } else {
        setShowPaymentMethodModal(c.id);
      }
    })();
  };

  return (
    <>
      <div className={styles.contentsView}>
        <div
          className={`${styles.modal_contents} ${
            isEndedVideo && styles.ended
          } ${isMutedVideo && styles.muted}`}
        >
          <div className={styles.contents_header}>
            <button
              type="button"
              className={styles.btn_close}
              onClick={() => {
                searchParams.delete("chapter");
                searchParams.delete("isPlaying");
                setSearchParams(searchParams);
                setShowContentsModal(null);
              }}
            >
              <i className={stylesIcon.icon_close}></i>
            </button>
          </div>

          <div className={styles.video_wrap}>
            <video
              className={styles.video}
              id={c.id}
              src={c.chapters[chapter - 1].videoUrl}
              poster={c.thumbnailUrl}
              onClick={() => setShownController(true)}
              onPlaying={onPlaying}
              onPause={onPause}
              onEnded={onEnded}
              onTimeUpdate={() => updateProgress()}
              onSeeking={() => setIsMovingSeekbar(true)}
              playsInline
              muted
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
              preload="none"
              onContextMenu={(e) => e.preventDefault()}
            ></video>

            {logo && <img src={logo} className={styles.logo} alt={c.title} />}

            <div className={styles.contents_footer}>
              <div className={styles.contents_info}>
                <ul className={styles.info_actor}>
                  {c.actors.map((a, i) => (
                    <li key={i}>
                      <img
                        className={styles.info_actor_img}
                        src={a.image}
                        alt=""
                      />
                      <p className={styles.info_actor_name}>{a.name}</p>
                    </li>
                  ))}
                </ul>
                <h2 className={styles.info_title}>{c.title}</h2>
                <button
                  type="button"
                  className={styles.info_chapter}
                  onClick={() => setShownChapterList(true)}
                >
                  Chapter.{chapter}
                  <i className={stylesIcon.icon_arrow_forward}></i>
                </button>
                {c.highlight && (
                  <pre
                    className={`${styles.info_highlight} ${
                      shownDetail && styles.active
                    }`}
                    onClick={() => setShownDetail(!shownDetail)}
                  >
                    {c.highlight.replaceAll("\\n", "\n")}
                  </pre>
                )}
              </div>
              <div className={styles.contents_footer_btns}>
                <button
                  type="button"
                  className={styles.btn_mute}
                  onClick={() => {
                    if (videoRef) {
                      setIsMutedVideo(!isMutedVideo);
                    }
                  }}
                ></button>
                {c.chapters[chapter - 1].price !== 0 &&
                  (!purchasedContent ||
                    !purchasedContent.chapters.includes(
                      c.chapters[chapter - 1].index,
                    )) && (
                    <div className={styles.btn_purchase_wrap}>
                      <div
                        className={`${styles.csp} ${
                          (!shownGuideCsp || c.category === "プロモーション") &&
                          styles.hidden
                        }`}
                      >
                        <div
                          className={styles.btnWrap}
                          onClick={() => {
                            setShownGuideCsp(false);
                            localStorage.setItem("deleted_csp", "true");
                          }}
                        >
                          <button type="button">
                            <i className={stylesIcon.icon_close}></i>
                          </button>
                        </div>
                        <img
                          src={
                            require("../assets/images/logo_csp_s_white_2.svg")
                              .default
                          }
                          alt=""
                        />
                        <p>
                          売上の一部が声優へ
                          <br />
                          還元されます
                        </p>
                      </div>
                      {c.category === "プロモーション" && (
                        <div className={styles.btn_purchase_supplement}>
                          <p>
                            収録裏話が
                            <br />
                            聴ける！
                          </p>
                        </div>
                      )}
                      <button
                        type="button"
                        className={`${
                          isPausedVideo
                            ? styles.btn_primary
                            : styles.btn_secondary
                        }`}
                        onClick={() =>
                          clickButtonBuy(
                            c,
                            c.chapters[chapter - 1].index,
                            "in_watching",
                          )
                        }
                      >
                        購入する
                      </button>
                    </div>
                  )}
              </div>
            </div>

            <div
              className={`${styles.controller} ${
                shownController && styles.active
              }`}
              onClick={(e) => {
                setShownController(false);
                e.stopPropagation();
              }}
            >
              <div className={styles.controller_btn}>
                <button
                  type="button"
                  className={styles.controller_btn_replay10}
                  onClick={(e) => clickSkipBtn(e, -10)}
                >
                  <i className={stylesIcon.icon_replay10}></i>
                </button>
                <button
                  type="button"
                  className={styles.controller_btn_playpause}
                  onClick={(e) => {
                    pausePlay();
                    e.stopPropagation();
                  }}
                >
                  {isPausedVideo ? (
                    <i className={stylesIcon.icon_play}></i>
                  ) : (
                    <i className={stylesIcon.icon_pause}></i>
                  )}
                </button>
                <button
                  type="button"
                  className={styles.controller_btn_forward10}
                  onClick={(e) => clickSkipBtn(e, 10)}
                >
                  <i className={stylesIcon.icon_forward10}></i>
                </button>
              </div>
              <div className={styles.progress_bar}>
                <span className={styles.value} ref={progressBarValueRef}></span>
                <span
                  className={styles.handle}
                  ref={progressBarHundleRef}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={onMouseDownHundle}
                  onTouchStart={onTouchStartHundle}
                ></span>
              </div>
            </div>

            <div
              className={`${styles.chapters} ${
                shownChapterList && styles.active
              }`}
            >
              <div
                className={styles.overlay}
                onClick={() => setShownChapterList(false)}
              ></div>
              <div className={styles.wrap}>
                <div className={styles.chaptersHeader}>
                  <p>{c.title}</p>
                  <button
                    type="button"
                    onClick={() => setShownChapterList(false)}
                  >
                    <i className={stylesIcon.icon_close}></i>
                  </button>
                </div>
                <ul>
                  {c.chapters.map((ch, i) => (
                    <li
                      key={i}
                      className={`${ch.index === chapter && styles.active}`}
                      onClick={() => {
                        if (ch.index === chapter) {
                          setShownChapterList(false);
                          return;
                        }
                        if (
                          ch.price === 0 ||
                          purchasedContent?.chapters.includes(ch.index)
                        ) {
                          searchParams.set("chapter", `${ch.index}`);
                          setSearchParams(searchParams);
                          if (videoRef) videoRef.currentTime = 0;
                        } else {
                          setChapter(ch.index - 1);
                          clickButtonBuy(c, ch.index, "chapter");
                        }
                        setShownChapterList(false);
                      }}
                    >
                      <div>
                        <p className={styles.chapterTitle}>
                          Chapter.{ch.index}
                        </p>
                        <p className={styles.chapterTime}>
                          {convertTime(ch.totalTime)}
                          {ch.sampleTotalTime !== 0 &&
                            ch.totalTime !== ch.sampleTotalTime &&
                            `（無料視聴：${convertTime(ch.sampleTotalTime)}）`}
                        </p>
                      </div>
                      {ch.index !== chapter &&
                        (ch.price === 0 ? (
                          <p className={styles.chaptersPlay}>
                            無料で視聴する
                            <i className={stylesIcon.icon_arrow_forward}></i>
                          </p>
                        ) : purchasedContent?.chapters.includes(ch.index) ? (
                          <p className={styles.chaptersPlay}>
                            視聴する
                            <i className={stylesIcon.icon_arrow_forward}></i>
                          </p>
                        ) : (
                          <Button size="s">¥{ch.price}</Button>
                        ))}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={styles.announce_ended_video}>
              <div className={styles.inner}>
                {c.chapters.length === 1 ? (
                  // Chapter1のみ
                  !(
                    purchasedContent &&
                    purchasedContent.chapters.includes(
                      c.chapters[chapter - 1].index,
                    )
                  ) ? (
                    // 未購入
                    <>
                      <div className={styles.info}>
                        {c.category !== "プロモーション" ? (
                          // プロモ以外
                          <>
                            <p className={styles.info_free}>
                              無料視聴はここまで！
                              <br />
                              <strong>続きは購入して聴いてね！</strong>
                            </p>
                            {c.chapters[chapter - 1].outline && (
                              <p className={styles.info_outline}>
                                {c.chapters[chapter - 1].outline}
                              </p>
                            )}
                            <p className={styles.info_title}>『{c.title}』</p>
                            <div className={styles.info_actors}>
                              {c.chapters[chapter - 1].actors.map((a, i) => (
                                <div key={i} className={styles.info_actorsItem}>
                                  <img
                                    className={styles.info_actorImage}
                                    src={a.image}
                                    alt=""
                                  />
                                  <div>
                                    {a.role && (
                                      <p className={styles.info_actorRole}>
                                        {a.role}役
                                      </p>
                                    )}
                                    <p className={styles.info_actorName}>
                                      {a.name}さん
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          // プロモ
                          <>
                            <div className={styles.info_prText}>
                              <p>
                                動画の続きを購入すると
                                <br />
                                {c.chapters[chapter - 1].actors.map((a, i) =>
                                  i > 0 ? `、${a.name}さん` : `${a.name}さん`,
                                )}
                                の<br />
                                収録裏話が聞ける！
                              </p>
                            </div>
                            <div className={styles.info_prActors}>
                              {c.chapters[chapter - 1].actors.map((a, i) => (
                                <div
                                  key={i}
                                  className={styles.info_prActorImage}
                                >
                                  <img src={a.image} alt="" />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                      <div className={styles.btns}>
                        <p className={styles.price}>
                          ¥
                          <strong>
                            {c.chapters[chapter - 1].price.toLocaleString()}
                          </strong>
                          <small>（税込）</small>{" "}
                        </p>
                        {c.category !== "プロモーション" &&
                          c.chapters[chapter - 1].totalTime &&
                          c.chapters[chapter - 1].sampleTotalTime && (
                            <p className={styles.timeLeft}>
                              続きの視聴時間：
                              {convertTime(
                                c.chapters[chapter - 1].totalTime -
                                  c.chapters[chapter - 1].sampleTotalTime,
                              )}
                            </p>
                          )}
                        <button
                          type="button"
                          className={`${
                            styles.btn_primary + " " + styles.btn_buy
                          }`}
                          onClick={() =>
                            clickButtonBuy(
                              c,
                              c.chapters[chapter - 1].index,
                              "after_watching",
                            )
                          }
                        >
                          <span>
                            {user ? "購入する" : "ログイン・登録して購入する"}
                          </span>
                        </button>
                        {c.category !== "プロモーション" && (
                          <p className={styles.donationText}>
                            売上のうち、100円が声優へ還元されます
                          </p>
                        )}
                        <button
                          type="button"
                          className={
                            styles.btn_secondary + " " + styles.btn_replay
                          }
                          onClick={clickButtonReplay}
                        >
                          もう一度視聴する
                        </button>
                      </div>
                    </>
                  ) : (
                    // 購入済み
                    <>
                      <div className={styles.btns}>
                        <button
                          type="button"
                          className={
                            styles.btn_secondary + " " + styles.btn_replay
                          }
                          onClick={clickButtonReplay}
                        >
                          最初から視聴する
                        </button>
                      </div>
                    </>
                  )
                ) : c.chapters.length !== chapter ? (
                  // 次がある
                  !purchasedContent?.chapters.includes(chapter + 1) ? (
                    // 次のチャプター未購入の場合
                    <>
                      <p className={styles.info_free}>
                        <strong>続きを購入しますか？</strong>
                      </p>
                      <div className={styles.btns}>
                        <div>
                          <p className={styles.info_title}>
                            Chapter.{chapter + 1}
                          </p>
                          <p className={styles.timeLeft}>
                            {convertTime(c.chapters[chapter].totalTime)}
                          </p>
                        </div>
                        <p className={styles.price}>
                          ¥
                          <strong>
                            {c.chapters[chapter].price.toLocaleString()}
                          </strong>
                          <small>（税込）</small>{" "}
                        </p>
                        <button
                          type="button"
                          className={`${
                            styles.btn_primary + " " + styles.btn_buy
                          }`}
                          onClick={() =>
                            clickButtonBuy(
                              c,
                              c.chapters[chapter].index,
                              "after_watching",
                            )
                          }
                        >
                          <span>
                            {user ? "購入する" : "ログイン・登録して購入する"}
                          </span>
                        </button>
                        {c.category !== "プロモーション" && (
                          <p className={styles.donationText}>
                            売上のうち、100円が声優へ還元されます
                          </p>
                        )}
                        <button
                          type="button"
                          className={
                            styles.btn_secondary + " " + styles.btn_replay
                          }
                          onClick={clickButtonReplay}
                        >
                          もう一度視聴する
                        </button>
                      </div>
                    </>
                  ) : (
                    // 次のチャプター購入済みの場合
                    <></>
                  )
                ) : (
                  // 次がない
                  <>
                    <div className={styles.btns}>
                      <button
                        type="button"
                        className={
                          styles.btn_secondary + " " + styles.btn_replay
                        }
                        onClick={() => {
                          searchParams.set("chapter", "1");
                          setSearchParams(searchParams);
                        }}
                      >
                        最初から視聴する
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {shownModalSignin === c.id && (
        <SigninModal
          setShownModalSignin={setShownModalSignin}
          shownModalSignin={shownModalSignin}
          chapter={[c.chapters.length === 1 ? chapter : chapter + 1]}
        />
      )}

      {showPaymentMethodModal === c.id && (
        <PaymentMethodModal
          contentId={c.id}
          title={c.title}
          thumbnailUrl={c.thumbnailUrl}
          chapter={[
            {
              chapter: c.chapters.length === 1 ? chapter : chapter + 1,
              price:
                c.chapters[c.chapters.length === 1 ? chapter - 1 : chapter]
                  .price,
            },
          ]}
          setShownModal={setShowPaymentMethodModal}
        />
      )}
    </>
  );
};
