import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { FormEvent, useLayoutEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/AuthContext";
import HeadBlock from "../../components/HeadBlock";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import { auth, db } from "../../firebase";
import styles from "../../assets/styles/menu.module.scss";

function ProfileEdit() {
  const navigate = useNavigate();
  const uid = auth.currentUser?.uid;
  const { user } = useAuthContext();
  const [name, setName] = useState<string>("");
  const [sex, setSex] = useState<number>(0);
  const [birthday, setBirthday] = useState<string>("");
  const [isValidErrorName, setIsValidErrorName] = useState<boolean>(false);
  const optionSex = [
    { value: 0, label: "未選択" },
    { value: 1, label: "男性" },
    { value: 2, label: "女性" },
    { value: 9, label: "その他" },
  ];

  useLayoutEffect(() => {
    if (uid)
      fetchProfile(uid)
        .then((prof) => {
          if (prof) {
            setName(prof.name);
            setSex(prof.sex);
            setBirthday(prof.birthday);
          }
        })
        .catch(() => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        });
  }, [uid]);

  const save = (event: FormEvent<HTMLFormElement>) => {
    if (!checkName()) {
      setIsValidErrorName(true);
      event.preventDefault();
      return;
    }

    if (uid)
      setProfile(uid, name, sex, birthday)
        .then(() => {
          navigate("/menu", {
            state: { notificationText: "プロフィールを更新しました" },
          });
        })
        .catch(() => {
          event.preventDefault();
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        });
    event.preventDefault();
  };

  const fetchProfile = async (
    uid: string,
  ): Promise<{ name: string; sex: number; birthday: string } | null> => {
    const docSnap = await getDoc(doc(db, "users", uid));
    if (docSnap.exists()) {
      return {
        name: docSnap.data().name ? docSnap.data().name : "",
        sex: docSnap.data().sex ? docSnap.data().sex : 0,
        birthday: docSnap.data().birthday ? docSnap.data().birthday : "",
      };
    } else {
      return null;
    }
  };

  const setProfile = async (
    uid: string,
    name: string,
    sex: number,
    birthday: string,
  ) => {
    return await setDoc(doc(db, "users", uid), { name, sex, birthday });
  };

  const checkName = () => {
    if (name.length <= 50) return true;
    else return false;
  };

  if (!user) {
    return <Navigate to="/signin" />;
  } else {
    return (
      <>
        <HeadBlock title={"プロフィールの変更"} path="menu/profile/edit" />
        <Header buckButtonTarget="/menu" />
        <div className={styles.Profile}>
          <form action="" onSubmit={(e) => save(e)}>
            <ul>
              <li>
                <label>
                  ユーザーID
                  <p>{user.uid}</p>
                </label>
                <p className={styles.supplement}>現在、変更できません</p>
              </li>
              <li>
                <label>
                  ニックネーム
                  <input
                    type="text"
                    value={name}
                    className={isValidErrorName ? "invalid" : ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                {isValidErrorName && (
                  <p className={styles.error}>50文字以内で入力してください</p>
                )}
              </li>
              <li>
                <label>
                  性別
                  <select
                    value={sex}
                    onChange={(e) => setSex(Number(e.target.value))}
                  >
                    {optionSex.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </li>
              <li>
                <label>
                  生年月日
                  <input
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                  />
                </label>
              </li>
            </ul>
            <input
              type="submit"
              value="保存"
              className={styles.btn_primary}
            ></input>
            <NavLink to={"/menu"} className={styles.btn_secondary}>
              キャンセル
            </NavLink>
          </form>
        </div>
        <Nav />
      </>
    );
  }
}

export default ProfileEdit;
