import { useEffect, useRef, useState, createRef, RefObject } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db, storage } from "../firebase";
import { Actor, Chapter, Content } from "../utils/types";
import { displayContentCategory } from "../enums/contentCategory";
import HeadBlock from "../components/HeadBlock";
import { useAuthContext } from "../components/AuthContext";
import styles from "../assets/styles/contents.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import stylesLoading from "../assets/styles/loading.module.scss";
import { getDownloadURL, ref } from "firebase/storage";
import { ContentsList } from "./ContentsList";

function Contents() {
  const [contents, setContents] = useState<Content[]>([]);
  const [purchasedContents, setPurchasedContents] = useState<
    { id: string; chapters: number[] }[]
  >([]);
  const [currentContentId, setCurrentContentId] = useState<string>();
  const [currentContentTitle, setCurrentContentTitle] = useState<string>();
  const [currentIndex, setCurrentIdex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shownGuideStart, setShownGuideFirst] = useState<boolean>(false);
  const [shownModalIntroduction, setShownModalIntroduction] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const refs = useRef<RefObject<HTMLDivElement>[]>([]);
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const contentsId = searchParams.get("id");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = (
            entry.target.children.namedItem("contentId") as HTMLInputElement
          ).value;
          const title = (
            entry.target.children.namedItem("contentTitle") as HTMLInputElement
          ).value;
          const index = (
            entry.target.children.namedItem("index") as HTMLInputElement
          ).value;
          entry.target.classList.add(styles.current);
          if (contentsId !== id) {
            navigate(`/contents?id=${id}`, { replace: true });
          }
          setCurrentContentId(id);
          setCurrentContentTitle(title);
          setCurrentIdex(Number(index));
          observer.unobserve(entry.target);
        } else {
          entry.target.classList.remove(styles.current);
        }
      });
    },
    { threshold: 1 },
  );

  const getActorImageUrl = async (fileName: string): Promise<string> => {
    return getDownloadURL(ref(storage, `contents/actors/${fileName}.png`));
  };

  const fetchContents = async (): Promise<Content[]> => {
    const snapShot = await getDocs(
      query(collection(db, "contents"), orderBy("createdAt", "desc")),
    );
    const visited = JSON.parse(localStorage.getItem("visited")!);

    if (contentsId) {
      const tail = await Promise.all(
        snapShot.docs
          .filter((d) => {
            return d.data().isPublished;
          })
          .filter((d) => {
            if (!visited) return d.id !== contentsId && d.id !== "promotion";
            else return d.id !== contentsId;
          })
          .map(async (d) => {
            const actors = await Promise.all(
              d.data().actors.map(async (a: Actor) => {
                return {
                  name: a.name,
                  role: a.role,
                  image: await getActorImageUrl(a.image).then((url) => url),
                };
              }),
            );

            const chapterSnapShot = await getDocs(
              collection(db, "contents", d.id, "chapters"),
            );

            const chapters = await Promise.all(
              chapterSnapShot.docs.map(async (chapter) => {
                const actors = await Promise.all(
                  chapter.data().actors.map(async (a: Actor) => {
                    return {
                      name: a.name,
                      role: a.role,
                      image: await getActorImageUrl(a.image).then((url) => url),
                    };
                  }),
                );

                return {
                  index: Number(chapter.id),
                  actors: actors,
                  price: chapter.data().price,
                  outline: chapter.data().outline,
                  sampleTotalTime: chapter.data().sampleTotalTime,
                  totalTime: chapter.data().totalTime,
                  createdAt: chapter.data().createdAt,
                } as Chapter;
              }),
            );

            return {
              id: d.id,
              title: d.data().title,
              category: displayContentCategory(d.data().category),
              actors: actors,
              highlight: d.data().highlight,
              chapters: chapters,
              thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${d.id}.jpg?alt=media`,
              bgColor: d.data().bgColor,
              createdAt: d.data().createdAt.toDate(),
            } as Content;
          }),
      );

      const content = snapShot.docs
        .filter((d) => {
          return d.data().isPublished;
        })
        .find((d) => d.id === contentsId);
      if (content) {
        const actors = await Promise.all(
          content.data().actors.map(async (a: Actor) => {
            return {
              name: a.name,
              role: a.role,
              image: await getActorImageUrl(a.image).then((url) => url),
            };
          }),
        );

        const chapterSnapShot = await getDocs(
          collection(db, "contents", content.id, "chapters"),
        );

        const chapters = await Promise.all(
          chapterSnapShot.docs.map(async (chapter) => {
            const actors = await Promise.all(
              chapter.data().actors.map(async (a: Actor) => {
                return {
                  name: a.name,
                  role: a.role,
                  image: await getActorImageUrl(a.image).then((url) => url),
                };
              }),
            );

            return {
              index: Number(chapter.id),
              actors: actors,
              price: chapter.data().price,
              outline: chapter.data().outline,
              sampleTotalTime: chapter.data().sampleTotalTime,
              totalTime: chapter.data().totalTime,
              createdAt: chapter.data().createdAt,
            } as Chapter;
          }),
        );

        const head = {
          id: content.id,
          title: content.data().title,
          category: displayContentCategory(content.data().category),
          actors: actors,
          highlight: content.data().highlight,
          chapters: chapters,
          thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${content.id}.jpg?alt=media`,
          bgColor: content.data().bgColor,
          createdAt: content.data().createdAt.toDate(),
        } as Content;

        if (visited || content.id === "promotion") {
          return [head, ...tail];
        } else {
          const contentPromotion = snapShot.docs.find(
            (d) => d.id === "promotion",
          ); //todo: ここ？

          if (contentPromotion) {
            const actors = await Promise.all(
              contentPromotion.data().actors.map(async (a: Actor) => {
                return {
                  name: a.name,
                  role: a.role,
                  image: await getActorImageUrl(a.image).then((url) => url),
                };
              }),
            );

            const chapterSnapShot = await getDocs(
              collection(db, "contents", contentPromotion.id, "chapters"),
            );

            const chapters = await Promise.all(
              chapterSnapShot.docs.map(async (chapter) => {
                const actors = await Promise.all(
                  chapter.data().actors.map(async (a: Actor) => {
                    return {
                      name: a.name,
                      role: a.role,
                      image: await getActorImageUrl(a.image).then((url) => url),
                    };
                  }),
                );

                return {
                  index: Number(chapter.id),
                  actors: actors,
                  price: chapter.data().price,
                  outline: chapter.data().outline,
                  sampleTotalTime: chapter.data().sampleTotalTime,
                  totalTime: chapter.data().totalTime,
                  createdAt: chapter.data().createdAt,
                } as Chapter;
              }),
            );

            const promotion = {
              id: contentPromotion.id,
              title: contentPromotion.data().title,
              category: displayContentCategory(
                contentPromotion.data().category,
              ),
              actors: actors,
              chapters: chapters,
              thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${contentPromotion.id}.jpg?alt=media`,
              bgColor: content.data().bgColor,
              createdAt: contentPromotion.data().createdAt.toDate(),
            } as Content;

            return [head, promotion, ...tail];
          }
          return [head, ...tail];
        }
      }
      return tail;
    } else {
      if (visited) {
        const contents = await Promise.all(
          snapShot.docs
            .filter((d) => {
              return d.data().isPublished;
            })
            .map(async (d) => {
              const actors = await Promise.all(
                d.data().actors.map(async (a: Actor) => {
                  return {
                    name: a.name,
                    role: a.role,
                    image: await getActorImageUrl(a.image).then((url) => url),
                  };
                }),
              );

              const chapterSnapShot = await getDocs(
                collection(db, "contents", d.id, "chapters"),
              );

              const chapters = await Promise.all(
                chapterSnapShot.docs.map(async (chapter) => {
                  const actors = await Promise.all(
                    chapter.data().actors.map(async (a: Actor) => {
                      return {
                        name: a.name,
                        role: a.role,
                        image: await getActorImageUrl(a.image).then(
                          (url) => url,
                        ),
                      };
                    }),
                  );

                  return {
                    index: Number(chapter.id),
                    actors: actors,
                    price: chapter.data().price,
                    outline: chapter.data().outline,
                    sampleTotalTime: chapter.data().sampleTotalTime,
                    totalTime: chapter.data().totalTime,
                    createdAt: chapter.data().createdAt,
                  } as Chapter;
                }),
              );

              return {
                id: d.id,
                title: d.data().title,
                category: displayContentCategory(d.data().category),
                actors: actors,
                chapters: chapters,
                thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${d.id}.jpg?alt=media`,
                bgColor: d.data().bgColor,
                highlight: d.data().highlight,
                createdAt: d.data().createdAt.toDate(),
              } as Content;
            }),
        );
        return contents;
      } else {
        const tail = await Promise.all(
          snapShot.docs
            .filter((d) => {
              return d.data().isPublished;
            })
            .filter((d) => d.id !== "promotion")
            .map(async (d) => {
              const actors = await Promise.all(
                d.data().actors.map(async (a: Actor) => {
                  return {
                    name: a.name,
                    role: a.role,
                    image: await getActorImageUrl(a.image).then((url) => url),
                  };
                }),
              );

              const chapterSnapShot = await getDocs(
                collection(db, "contents", d.id, "chapters"),
              );

              const chapters = await Promise.all(
                chapterSnapShot.docs.map(async (chapter) => {
                  const actors = await Promise.all(
                    chapter.data().actors.map(async (a: Actor) => {
                      return {
                        name: a.name,
                        role: a.role,
                        image: await getActorImageUrl(a.image).then(
                          (url) => url,
                        ),
                      };
                    }),
                  );

                  return {
                    index: Number(chapter.id),
                    actors: actors,
                    price: chapter.data().price,
                    outline: chapter.data().outline,
                    sampleTotalTime: chapter.data().sampleTotalTime,
                    totalTime: chapter.data().totalTime,
                    createdAt: chapter.data().createdAt,
                  } as Chapter;
                }),
              );

              return {
                id: d.id,
                title: d.data().title,
                category: displayContentCategory(d.data().category),
                actors: actors,
                highlight: d.data().highlight,
                chapters: chapters,
                thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${d.id}.jpg?alt=media`,
                bgColor: d.data().bgColor,
                createdAt: d.data().createdAt.toDate,
              } as Content;
            }),
        );

        const content = snapShot.docs
          .filter((d) => {
            return d.data().isPublished;
          })
          .find((d) => d.id === "promotion");
        if (content) {
          const actors = await Promise.all(
            content.data().actors.map(async (a: Actor) => {
              return {
                name: a.name,
                role: a.role,
                image: await getActorImageUrl(a.image).then((url) => url),
              };
            }),
          );

          const chapterSnapShot = await getDocs(
            collection(db, "contents", content.id, "chapters"),
          );

          const chapters = await Promise.all(
            chapterSnapShot.docs.map(async (chapter) => {
              const actors = await Promise.all(
                chapter.data().actors.map(async (a: Actor) => {
                  return {
                    name: a.name,
                    role: a.role,
                    image: await getActorImageUrl(a.image).then((url) => url),
                  };
                }),
              );

              return {
                index: Number(chapter.id),
                actors: actors,
                price: chapter.data().price,
                outline: chapter.data().outline,
                sampleTotalTime: chapter.data().sampleTotalTime,
                totalTime: chapter.data().totalTime,
                createdAt: chapter.data().createdAt,
              } as Chapter;
            }),
          );

          const head = {
            id: content.id,
            title: content.data().title,
            category: displayContentCategory(content.data().category),
            actors: actors,
            chapters: chapters,
            highlight: content.data().highlight,
            thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${content.id}.jpg?alt=media`,
            bgColor: content.data().bgColor,
            createdAt: content.data().createdAt.toDate(),
          } as Content;
          return [head, ...tail];
        }
        return tail;
      }
    }
  };

  const fetchPurchasedContents = async (
    uid: string | undefined,
  ): Promise<{ id: string; chapters: number[] }[]> => {
    if (uid) {
      const snapShot = await getDocs(collection(db, "users", uid, "contents"));
      return await Promise.all(
        snapShot.docs.map(async (content) => {
          const snapShotCh = await getDocs(
            collection(db, "users", uid, "contents", content.id, "chapters"),
          );
          const chapters = snapShotCh.docs.map((ch) => Number(ch.id));
          return {
            id: content.id,
            chapters,
          };
        }),
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    refs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      refs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });

  useEffect(() => {
    fetchPurchasedContents(user?.uid)
      .then((purchasedContents) => {
        setPurchasedContents(purchasedContents);
        fetchContents()
          .then((contents) => {
            contents.forEach(
              (c, i) => (refs.current[i] = createRef<HTMLDivElement>()),
            );
            setContents(contents);
            if (contentsId && !contents.find((c) => c.id === contentsId))
              alert("作品が見つかりませんでした");
            if (!contentsId) {
              const id = contents[0].id;
              navigate(`/contents?id=${id}`, { replace: true });
            }
          })
          .catch(() => {
            alert(
              "エラーが発生しました。\nしばらくしてから再度お試しください。",
            );
          })
          .finally(() => setIsLoading(false));
      })
      .catch(() => {
        alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  useEffect(() => {
    if (searchParams.get("isPlaying"))
      navigate(`/contents?id=${contentsId}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem("introduction_code") && user) {
      setShownModalIntroduction(true);
      localStorage.removeItem("introduction_code");
      return;
    }

    const visited = JSON.parse(localStorage.getItem("visited")!);
    if (visited) return;

    setShownGuideFirst(true);

    setTimeout(() => {
      setShownGuideFirst(false);
      localStorage.setItem("visited", "true");
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownModalIntroduction]);

  const hiddenGuide = () => {
    setShownGuideFirst(false);
    localStorage.setItem("visited", "true");
  };

  return (
    <>
      <HeadBlock
        title={currentContentTitle && currentContentTitle}
        path={`contents/${currentContentId}`}
      />
      {!isLoading && (
        <div
          style={{
            position: "fixed",
            zIndex: "-1",
            width: "100vw",
            height: "100dvh",
            background: `linear-gradient(135deg, ${contents[currentIndex].bgColor.top} 0%, ${contents[currentIndex].bgColor.bottom} 100%)`,
          }}
        ></div>
      )}
      <div className={styles.Contents}>
        {!isLoading ? (
          <div className={styles.contents}>
            {contents.map((c, i) => (
              <ContentsList
                ref={refs.current[i]}
                key={i}
                c={c}
                purchasedContent={
                  purchasedContents.filter((pc) => pc.id === c.id)[0]
                }
                index={i}
                currentContentId={currentContentId}
              />
            ))}
          </div>
        ) : (
          <div className={stylesLoading.loading}>
            <p>Loading...</p>
          </div>
        )}

        <div
          className={`${
            shownGuideStart
              ? styles.guide_first
              : styles.guide_first + " " + styles.hidden
          }`}
          onClick={hiddenGuide}
        >
          <div>
            <img
              src={require("../assets/images/swipe_up.svg").default}
              alt=""
            />
            <p className={styles.text}>
              <strong>上にスワイプして次の作品へ</strong>
            </p>
          </div>
        </div>

        {shownModalIntroduction && (
          <div className={styles.modal_introduction}>
            <div className={styles.inner}>
              <div className={styles.btn_close_wrap}>
                <button
                  type="button"
                  className={styles.btn_close}
                  onClick={() => setShownModalIntroduction(false)}
                >
                  <i className={stylesIcon.icon_close}></i>
                </button>
              </div>
              <img
                src={require("../assets/images/banner_introduction_supplement.png")}
                className={styles.introduction_supplement}
                alt=""
              />
              <NavLink to="https://lin.ee/r4SVWtA" target="_blank">
                <img
                  src={require("../assets/images/banner_introduction.jpg")}
                  alt=""
                />
              </NavLink>
            </div>
            <div
              className={styles.overlay}
              onClick={() => setShownModalIntroduction(false)}
            ></div>
          </div>
        )}
      </div>
    </>
  );
}

export default Contents;
