import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Outlet } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";

function ContentKurodaSan() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock
        title={"犬派になって、黒田さん！"}
        path="contents/kuroda-san/detail"
      />
      <div className={`${styles.Content} ${styles.kurodaSan}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt=""
          />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/kuroda-san/detail">
                <img
                  src={
                    require("../../../assets/images/content/logo.svg").default
                  }
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/kuroda-san"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/kuroda-san"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/kuroda-san/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/kuroda-san/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.mainvisualTitle}>
                <div className={styles.text}>
                  <h1>
                    <img
                      src={
                        require("../../../assets/images/content/kuroda-san/logo.svg")
                          .default
                      }
                      alt="犬派になって、黒田さん！"
                    />
                  </h1>
                  <p className={styles.tag}>
                    犬系部下と俺様系上司のソフトBLストーリー
                    <br />
                    高梨だけが知る、俺様系上司の裏の顔とは?
                  </p>
                </div>
                <a
                  href="/contents/kuroda-san"
                  target="_blank"
                  className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
                >
                  ボイスドラマを視聴する
                </a>
              </div>
              <div className={styles.free}>
                <p>
                  <span>
                    <strong>1</strong>
                    <small>話目</small>
                  </span>
                  <span>無料</span>
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/kuroda-san/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/kuroda-san/actor_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>高梨影人 / 黒田剛臣 役</p>
                  <p className={styles.name}>
                    小林 裕介<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    小林 裕介（コバヤシ ユウスケ）声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/story2.png")}
                  alt=""
                />
                <p>
                  「今回の企画の意図、ちゃんと理解しているのか？さっさと練り直してこい！」
                </p>
                <p>
                  僕、高梨影人の上司である、黒田剛臣さんは部署のみんなからもイケメン鬼上司と恐れられている存在だ。
                </p>
                <p>
                  毎日たくさん怒られているが僕は全然怖くない。
                  なぜなら、僕は黒田さんの裏の顔を知っているから...
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    小林 裕介（コバヤシ ユウスケ）声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/kuroda-san"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  <strong>一人二役のBLを演じるのは不思議な気持ちでした</strong>
                </h3>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―収録お疲れ様でした！まず物語全体の印象はいかがでしたか？
                  </p>
                  <p className={styles.Interviewee}>
                    今回、数分のショートの物語だったので、主人公たちのオフィスライフの1幕をちょっとだけ切り取った、そんなストーリーでしたね。すごく日常感があってよかったです。
                    <br />
                    変なトラブルもなく、ただただいつも通り過ごすというのが僕的にはすごく好きですし、そのフラットな日常の中にどんな面白さを見つけていくかを考えるのが楽しいので・・・
                  </p>
                </div>
                <a
                  href="/interview/kuroda-san"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
          </section>

          <div className={styles.recommendContent}>
            <p>
              小林 裕介さんが出演する
              <br className={styles.brSp} />
              こちらの作品もチェック！
            </p>
            <a href="/contents/fuyuzora-melt-reply" target="_blank">
              <img
                className={styles.imgPc}
                src={require("../../../assets/images/content/kuroda-san/banner_pc.png")}
                alt=""
              />
              <img
                className={styles.imgSp}
                src={require("../../../assets/images/content/kuroda-san/banner_sp.png")}
                alt=""
              />
            </a>
          </div>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/kuroda-san/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/kuroda-san/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/kuroda-san/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/kuroda-san/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/kuroda-san/detail&text=小林裕介さん出演！犬系部下と俺様系上司のソフトBLストーリー「犬派になって、黒田さん！」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,小林裕介"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentKurodaSan;
