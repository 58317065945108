import React from "react";
import HeadBlock from "../../components/HeadBlock";
import styles from "../../assets/styles/static.module.scss";
import Header from "../../components/Header";

function Tokutei() {
  return (
    <>
      <HeadBlock title={"特定商取引法に基づく表記"} path="tokutei" />
      <Header />
      <div className={styles.tokutei}>
        <h1>特定商取引法に基づく表記</h1>
        <section>
          <h2>サービス名</h2>
          <p>クリエイターコラボレーションサービス「PAZLE」</p>
        </section>
        <section>
          <h2>販売事業者名（会社名）</h2>
          <p>VECTOR合同会社</p>
        </section>
        <section>
          <h2>代表者名</h2>
          <p>代表 吉野 直樹</p>
        </section>
        <section>
          <h2>所在地</h2>
          <p>
            〒108-0074
            <br />
            東京都港区高輪3-24−18
          </p>
        </section>
        <section>
          <h2>お問い合わせ</h2>
          <p>
            こちら (support@pazle.click) からお問い合わせください。
            <br />
            ※弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。弊社電話番号が必要なお客様は上記フォームよりお申し出ください。
          </p>
        </section>
        <section>
          <h2>販売価格</h2>
          <p>
            購入手続きの際に画面に表示されます。
            <br />
            消費税は内税として表示しております。
          </p>
        </section>
        <section>
          <h2>販売価格以外でお客様に発生する金銭</h2>
          <p>
            当サイトのページの閲覧、作品購入、ダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。
          </p>
        </section>
        <section>
          <h2>お支払方法</h2>
          <p>以下のいずれかのお支払方法をご利用いただけます。</p>
          <ul>
            <li>各種クレジットカード</li>
            <li>その他、購入にかかる決済を代行する会社が提供するお支払方法</li>
          </ul>
        </section>
        <section>
          <h2>商品購入方法</h2>
          <p>
            サービスのトップページ及び詳細ページから「購入」ボタンを押下し、各種お支払方法で決済していただきますと、作品がダウンロードできるようになります。
          </p>
        </section>
        <section>
          <h2>デジタルアイテム等の利用が可能となる時期</h2>
          <p>
            特別な定めを置いている場合を除き、お支払い手続き完了後直ちにご利用いただけます。
          </p>
        </section>
        <section>
          <h2>動作環境</h2>
          <p>
            アプリケーションによって利用環境・対応機種が異なります。作品のダウンロードの前に、必ず詳細ページで利用環境・対応機種をご確認ください。
          </p>
        </section>
        <section>
          <h2>返品・キャンセル</h2>
          <section>
            <h3>1. お客様のご都合による返品・キャンセル</h3>
            <p>
              商品の性質上、各アプリケーションご購入後の返金・返品はできかねます。作品、利用環境・対応機種および各アプリケーションの利用環境・対応機種をよくお確かめの上、お申込み、もしくはご購入願います。
            </p>
          </section>
          <section>
            <h3>2.作品の瑕疵に基づく返品（キャンセル）</h3>
            <p>
              作品に瑕疵が発見されたときは、瑕疵を修補した作品をサービスのバージョンアップ又はその他適切な方法で提供いたします。
            </p>
          </section>
        </section>
        <section>
          <h2>特別条件</h2>
          <section>
            <h3>クーリングオフについて</h3>
            <p>
              作品に瑕疵が発見されたときは、瑕疵を修補した作品をサービスのバージョンアップ又はその他適切な方法で提供いたします。
            </p>
          </section>
        </section>
      </div>
    </>
  );
}

export default Tokutei;
