import { User } from "firebase/auth";
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../firebase";
import styles from "../assets/styles/loading.module.scss";

const AuthContext = createContext<{
  user: User | null;
  setUser: (user: User | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ user: null, setUser: () => {} });

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children?: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  const value = {
    user,
    loading,
    setUser,
  };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged((user) => {
      if (user) setUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIXME: この処理が何なのか分からないが危険そう
  if (
    loading &&
    location.pathname !== "/about" &&
    !/^\/contents\/[A-Za-z0-9-]+\/detail$/.test(location.pathname) &&
    !/^\/interview\/[A-Za-z0-9-]+$/.test(location.pathname) &&
    !/^\/contents\/[A-Za-z0-9-]+\/detail\/present$/.test(location.pathname) &&
    location.pathname !== "/terms" &&
    location.pathname !== "/tokutei" &&
    location.pathname !== "/privacy"
  ) {
    return (
      <div className={styles.loading}>
        <p>Loading...</p>
      </div>
    );
  } else {
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }
}
