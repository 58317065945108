import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";

function ContentFuyuzoraMelt() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock
        title={"想い、冬空メルト。"}
        path="contents/fuyuzora-melt/detail"
      />
      <div className={styles.Content}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt=""
          />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/fuyuzora-melt/detail">
                <img
                  src={
                    require("../../../assets/images/content/logo.svg").default
                  }
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/fuyuzora-melt"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/fuyuzora-melt"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/fuyuzora-melt/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/fuyuzora-melt/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.text}>
                <h1>
                  <img
                    src={
                      require("../../../assets/images/content/fuyuzora-melt/logo.svg")
                        .default
                    }
                    alt="想い、冬空メルト。"
                  />
                </h1>
                <p className={styles.tag}>
                  PAZLEボイスドラマ第一弾公開！
                  <br />
                  高校生の瑞々しく純粋な恋心を描いた青春ストーリー
                </p>
              </div>
              <a
                href="/contents/fuyuzora-melt"
                target="_blank"
                className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
              <div className={`${styles.mainvisualCsp} ${styles.cspSp}`}>
                <img
                  src={
                    require("../../../assets/images/logo_csp_s_white.svg")
                      .default
                  }
                  alt=""
                />
                <p>
                  作品のご購入でクリエイターに
                  <br />
                  売上の一部が還元されます
                </p>
              </div>
              <div className={`${styles.mainvisualCsp} ${styles.cspPc}`}>
                <img
                  src={
                    require("../../../assets/images/logo_csp_white.svg").default
                  }
                  alt=""
                />
                <p>
                  作品をご購入いただくとクリエイターに売上の一部が還元されます
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/fuyuzora-melt/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/actor_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>玉置 実紅 役</p>
                  <p className={styles.name}>
                    長谷川 育美<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    長谷川育美（ハセガワ イクミ） 声優。
                    <br />
                    <br />
                    5月31日生まれ、栃木県出身。A型。出演作品は、アニメ『86-エイティシックス-』（ヴラディレーナ・ミリーゼ）、アニメ『弱キャラ友崎くん』（七海みなみ）、アニメ『推しが武道館いってくれたら死ぬ』（松山空音）、『ぼっち・ざ・ろっく！』（喜多郁代）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/story2.png")}
                  alt=""
                />
                <p>高校３年生の冬。私の告白はあっけなく惨敗した。</p>
                <p>
                  夜の学校に忍び込んだ玉置 実紅（たまき
                  みく）は、屋上に向かった。2月の寒い夜、学校の屋上で星空を見上げながら叫び出す。
                </p>
                <p>
                  実紅の心の奥底に秘めた思いが、その叫び声と共に夜空に溢れ出すのだった...
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    長谷川育美（ハセガワ イクミ） 声優。
                    <br />
                    <br />
                    5月31日生まれ、栃木県出身。A型。出演作品は、アニメ『86-エイティシックス-』（ヴラディレーナ・ミリーゼ）、アニメ『弱キャラ友崎くん』（七海みなみ）、アニメ『推しが武道館いってくれたら死ぬ』（松山空音）、『ぼっち・ざ・ろっく！』（喜多郁代）など。
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼冒頭33秒を無料で視聴できます！／</p>
              <a
                href="/contents/fuyuzora-melt"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  <strong>絶妙なラインの表現は新しい挑戦でした。</strong>
                </h3>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―収録お疲れ様でした！物語に命が吹き込まれる様子を目の当たりにして感動しました。さっそくですが、まず物語の印象についてお聞かせください。
                  </p>
                  <p className={styles.Interviewee}>
                    The青春という印象です！
                    <br />
                    ショートストーリーでしたけど、高校生の青春がぎゅっと詰まっていて。
                    <br />
                    個人的に終わり方が予想外だったのも面白かったです。失恋を吹っ切って「次に進もう！」で終わるのかなって・・・
                  </p>
                </div>
                <a
                  href="/interview/fuyuzora-melt"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
            <section className={styles.present}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  長谷川育美<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「想い、冬空メルト。」の感想を記載し引用リツイートしていただくと、抽選で2名様に長谷川育美さん直筆色紙をプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/fuyuzora-melt/detail/present"
                  state={{ background: location }}
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/fuyuzora-melt/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/fuyuzora-melt/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/fuyuzora-melt/detail&text=長谷川育美さん出演！高校生の瑞々しく純粋な恋心を描いた青春ストーリー「想い、冬空メルト。」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,長谷川育美"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentFuyuzoraMelt;
