import HeadBlock from "../../../components/HeadBlock";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import styles from "../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewMelody() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "佐藤 妃星「声優として最初の作品になるので想いを込めて演じました」"
        }
        path="interview/melody"
      />
      <div className={`${styles.Interview} ${styles.melody}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/melody"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              佐藤 妃星「声優として最初の作品になるので想いを込めて演じました」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回はPAZLE作品「ひみつのはぐたいむ」の続編「ひみつのめろでぃ」の公開を記念し、今回の出演が初の声優デビューとなる佐藤
              妃星さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/melody/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―声優になられて間もない主演挑戦でしたが演じられてどうでしたか？
            </p>
            <p>
              演技自体をやったことがほとんどない状態だったので、自分の至らないところがいっぱいあるなと気づくことができました。でも逆にまだまだ伸びる部分があると思えたので今後もっともっと研究して磨いていって、自分なりの強みを見つけて武器にしていきたいなと思いました！
            </p>
            <p className={styles.interviewer}>
              ―物語全体の印象はいかがでしたか？
            </p>
            <p>
              私も吹奏楽部だったこともあり、
              {user
                ? "共感できる部分がたくさんありました。あとやっぱり憧れの先輩って誰しもいると思うので、そこも共感できました。女の子特有の柔らかい作品で登場人物もすごく優しく温かい子たちだったので、お花みたいな雰囲気ですごく楽しかったです！"
                : "..."}
            </p>
            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=melody"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <img
                  src={require("../../../assets/images/interview/melody/img2.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回続編ということで意識したことはありますか？
                </p>
                <p>
                  そうですね、主人公の優花ちゃんも知らないであろう「彩希先輩」と「寧々先輩」の2人の物語（前作の「ひみつのはぐたいむ」）にどこかジェラシーを感じる部分がありました（笑）そんな前作の雰囲気を維持しつつ、でもその後輩としての新しい風を私が吹かせて行けたらいいなと思いました。
                </p>
                <img
                  src={require("../../../assets/images/interview/melody/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―佐藤さんも学生時代、吹奏楽部でクラリネットを担当されていたとのことですが、部活の思い出で印象に残っていることはありますか？
                </p>
                <p>
                  もう本当にこの作品の通りです！
                  <br />
                  私も金賞を狙ってがんばって、夏休み中も学校のプールを窓の外に見ながら、暑い教室の中で必死に練習をしていたので。残念ながら私の在籍していた3年間は銀賞どまりだったのですごく悔しかったのを覚えています。
                </p>

                <p className={styles.interviewer}>
                  ―今後どのような役にチャレンジしていきたいですか？
                </p>
                <p>
                  いろんな人から声が「特徴的だね」と言ってもらえることが多いのですが、私自身では自分がどんな声なのかいまだにちょっとよくわかっていなくて。
                  <br />
                  どんなキャラクターが合っているのかまだわからないので積極的にいろんなキャラクターにチャレンジして、自分に向いているキャラクターを見つけていきたいです。
                </p>

                <img
                  src={require("../../../assets/images/interview/melody/img4.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―ありがとうございました！最後に視聴者の皆様へメッセージをお願いします。
                </p>
                <p>
                  お聞きになっている皆様、佐藤 妃星（さとう
                  きあら）です。今回PAZLEさんで作品を朗読させていただいてすごく嬉しかったです！主人公の持つピュアな想いや、学生にしかない気持ちの変化だったり私も過去を思い出しながら演じました。学生の方も大人の方もいらっしゃると思うのですが、学生の方は今の自分に重ねながら、大人の方は過去を思い出して過去の思い出にひたっていただけたらなと思います。すごく可愛くて、守ってあげたいようなキャラクターと出会うことができて嬉しかったです！
                </p>

                <div className={styles.introduction}>
                  <p>
                    佐藤 妃星（サトウ キアラ）声優。
                    <br />
                    <br />
                    8月11日生まれ、千葉県出身。B型。2023年9月にAKB48を卒業。10月1日より声優事務所に所属。声優活動、タレント活動をスタート。今作が初の声優デビュー。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/melody"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/melody/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/melody/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/melody/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/melody/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/melody/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/melody/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/melody&text=佐藤 妃星「声優として最初の作品になるので想いを込めて演じました」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,佐藤妃星"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewMelody;
