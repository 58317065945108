import React, { useEffect } from "react";
import HeadBlock from "../../../components/HeadBlock";
import styles from "../../../assets/styles/interview.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { Link } from "react-router-dom";

function InterviewKouenNoMaken() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={
          "岡本 信彦「主人公っぽくないところが魅力だと感じました」＆ 高森 奈津美「コミカルさみたいなのが本当にかわいらしく感じました」"
        }
        path="interview/kouen-no-maken"
      />
      <div className={`${styles.Interview} ${styles.kouenNoMaken}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/kouen-no-maken"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>
              岡本 信彦「主人公っぽくないところが魅力だと感じました」＆ 高森
              奈津美「コミカルさみたいなのが本当にかわいらしく感じました」
            </h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              今回は、「風の姫君と紅炎の魔剣」の作品公開を記念し、生まれつき魔剣に愛され呪いを背負う紅役を演じた声優の岡本
              信彦さんと、紅の呪いを解くために共に旅に出る王女のセシリア役を演じた声優の高森
              奈津美さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/kouen-no-maken/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―本日はありがとうございました！
              <br />
              弊社代表が力を入れている作品なのですが、お二方の演技がイメージ以上にはまっていてびっくりしました。
              <br />
              さっそくですが、まず今回の収録についてのご感想をお聞かせください。
            </p>
            <p>
              <strong>岡本さん：</strong>
              主人公の紅は、凄惨な過去があり少し歪んだ精神状態の持ち主という主人公っぽくないところが魅力だと感じました。
              <br />
              その魅力を全開に、「セシリアとの出会い」という物語の始まりをお届けできたのではないかと思います。
              <br />
              ここからさらに広がりを感じるストーリーだと感じました。
            </p>
            <p>
              <strong>高森さん：</strong>
              今回の収録は、長いお話の中の導入部分にあたると思うんですが、今回のショートストーリーの中でもセシリアさんのキャラクターがはっきり分かりやすかったなと思いました。
              <br />
              本来、紅さんが持つ主人公としての正義感みたいな部分はセシリアさんが担っているのかな？とか。
              <br />
              でも、ヒロインとしての魅力も持ち合わせていてすごく可愛らしいキャラクターだなと思いました。
              これからどんな
              {user
                ? "旅をしていくのかな？と、すごいワクワクするお話だなあと思います。"
                : "..."}
            </p>

            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=kouen-no-maken"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <p className={styles.interviewer}>
                  ―ストーリー自体に対して、他にご感想があればお聞かせください。
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  短いストーリーの中でも、今後の伏線に繋がっていくのかな？と続きを彷彿させるような言葉がたくさん出てきていましたね。
                  <br />
                  例えばセシリアを「ゴリラ姫」と表現する単語が出てきて、相当肉体派なのかな？とか。
                  <br />
                  ゴリラ姫の由来になったエピソードも今後出てくるのかな？と想起させる、そういう伏線の散りばめられたストーリーだなと思いました。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  セシリアさんの人当たりの良さとまた違う魅力の部分なんですけど、紅さんがあまり心を開いてくれない分、セシリアさんが腕力で強引に紅の心を開いていくのかなとか、2人の関係性などがストーリーの中ですごく伝わりました。
                  <br />
                  また、紅さんが過去に孤児院でどういう風に過ごしてこのような性格になったのかとか、セシリアさんがどのようにゴリラ姫になったのかとか（笑）、そういうところの想像の余地がたくさんある話だなと思いました。
                </p>
                <p className={styles.interviewer}>
                  ―ちなみに作品のイラストはご覧になりましたか？
                </p>
                <p>
                  <strong>高森さん：</strong>はい、見ました！
                </p>
                <p className={styles.interviewer}>
                  ―ゴリラ姫と呼ばれてますが、イラストでは可愛い感じなので安心してください！（笑）
                </p>
                <p>
                  <strong>高森さん：</strong>（笑）
                </p>

                <img
                  src={require("../../../assets/images/interview/kouen-no-maken/img2.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今回のシナリオで特に印象に残るシーンはありましたか？
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  紅に対して「あなたを鞘にしたいほどに」っていう魔剣の禍々しさが分かるワードが出てきてたのが印象的でした。
                  <br />
                  紅のことを乗っ取ろうとしてるくらい禍々しい剣だから「魔剣」と呼ばれていて、忌み嫌われた剣なのかな？とか。
                  <br />
                  その「魔剣」というワードや、他にも「厄災」というワードが出てきたり、そういうネガティブなワードが紅に対して突きつけられている気がしたので、そういったことが紅の性格の歪みにつながったのかなと思いました。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  セシリアさんは第一声から紅さんに演技が下手って言われているので、ストーリーに組み込まれるコミカルさみたいなのが本当にかわいらしく感じました。
                  <br />
                  あとは紅さんだけ名前のテイストが違うのはなんでなのかな？とか、偽名なのかな？とか。
                  <br />
                  そういう風にいろいろ面白い設定がありそうなストーリーだなと思いました。
                  <br />
                  刀は喋るのかな？とか。
                </p>
                <p>
                  <strong>岡本さん：</strong>わかる。魔剣しゃべりそう！
                  <br />
                  魔剣あるある。魔剣が心に問いかけてくるあるある。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  鞘にしようとしてくるときに喋ったりしそう！
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  悪魔っぽい悪そうな喋り方をしそうだよね。
                </p>
                <p className={styles.interviewer}>
                  ―そこまで想像していただきありがとうございます！
                  <br />
                  ぜひ続編の参考にさせていただきます！
                </p>

                <p className={styles.interviewer}>
                  ―演じたそれぞれのキャラクターの性格を一言で表すとしたら何だと思いますか？
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  紅に関しては、どんな言い方が正しいんだろう.......？
                  <br />
                  うーん.........中学生が憧れる孤独！（笑）
                  <br />
                  僕はまだ心は中二なので、このかっこよさにはめっちゃ憧れますね。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  セシリアさんは公式で言われている通り、ゴリラ姫！（笑）
                  <br />
                  ただこの世界におけるゴリラは、私たちの考えるゴリラと同じなのかは気になりました。
                </p>
                <p>
                  <strong>岡本さん：</strong>確かに。もっと強いかもしれない。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  魔物のいる世界だから、ゴリラがどう存在しているのか？
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  この世界のゴリラもゴリラゴリラなのかな。
                </p>
                <p>
                  <strong>高森さん：</strong>ゴリラゴリラしているゴリラなのか。
                  <br />
                  でもやっぱりゴリラは愛情深く強い生き物だと思うので、セシリアさんにぴったりだなと思ってます。
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  セシリアの血液型はB型であってほしいね。
                </p>
                <p>
                  <strong>高森さん：</strong>確かに！（笑）
                </p>

                <img
                  src={require("../../../assets/images/interview/kouen-no-maken/img3.png")}
                  alt=""
                />

                <p className={styles.interviewer}>
                  ―今後、紅魔闘伝はショートストーリーで展開していくのですが、ショートストーリーの魅力はどんなところだと思いますか？
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  気になるワードが台本の3行以内にあって、魅力がぎゅっと詰め込まれてる感じ。
                  <br />
                  小説を読み慣れてない方でもさくっと、だけどワクワク読める1話なのかなと思いました。
                </p>
                <p>
                  <strong>高森さん：</strong>
                  岡本さんもおっしゃっているように、誰でもとっつきやすい長さだと思いました。
                  <br />
                  ショートの連続だからこそ毎回見所しかないものになってくると思うので。
                </p>
                <p className={styles.interviewer}>
                  ―最後になりますが、ファンの方にメッセージをお願いします。
                </p>
                <p>
                  <strong>岡本さん：</strong>
                  また違う話が配信されるように応援していただけたら嬉しいです。
                  <br />
                  PAZLEという場所自体がみなさんと作品づくりができる場所みたいなので、腕に覚えがある方はぜひ投稿の方をお願いします！
                </p>
                <p>
                  <strong>高森さん：</strong>
                  特に個人で創作活動をされている方々ってどのように他のクリエイターにオファーしたらいいかわからないと思いますし、そういう場もなかなかないと思います。
                  <br />
                  PAZLEは自分が好きだと思ったクリエイターの方に声をかけて作品づくりができる場になっていくということで、いろいろな創作に触れるきっかけになったり、どなたかと一緒に作るきっかけになると素敵だなと思いました。
                  <br />
                  今回収録したこの作品が、その一つのきっかけになったらすごく嬉しいです！
                </p>

                <p className={styles.interviewer}>
                  ―私たちもたくさんの素敵な作品が生まれる場にできるよう頑張ります！
                  <br />
                  ありがとうございました！
                </p>
                <div className={styles.introduction}>
                  <p>
                    岡本 信彦（オカモト ノブヒコ） 声優。
                    <br />
                    <br />
                    10月24日生まれ、東京都出身。B型。出演作品は、『とある魔術の禁書目録』（一方通行〈アクセラレータ〉役）、『青の祓魔師』（奥村燐役）、『僕のヒーローアカデミア』（爆豪勝己役）、『ハイキュー!!』（西谷夕役）、『鬼滅の刃』（不死川玄弥役）など。
                  </p>
                </div>
                <div className={styles.introduction}>
                  <p>
                    高森 奈津美（タカモリ ナツミ） 声優。
                    <br />
                    <br />
                    2月14日生まれ、山梨県出身。A型。出演作品は、『アイドルマスターシンデレラガールズ』（前川みく役）、『デリシャスパーティ♡プリキュア』コメコメ役）、『Another』（見崎鳴役）、『五等分の花嫁』（上杉らいは役）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼冒頭29秒を無料で視聴できます！／</p>
              <a
                href="/contents/kouen-no-maken"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/kouen-no-maken/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  岡本 信彦<small>さん、</small>高森 奈津美<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で1名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    公式X（旧：Twitter）から投稿される応募用のポストに「ボイスドラマの感想」を記載し引用リツイートしていただくと、抽選で岡本
                    信彦さん・高森 奈津美さん直筆色紙を1名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/kouen-no-maken/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/kouen-no-maken/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/kouen-no-maken/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/kouen-no-maken/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/kouen-no-maken&text=岡本 信彦「主人公っぽくないところが魅力だと感じました」＆ 高森 奈津美「コミカルさみたいなのが本当にかわいらしく感じました」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,岡本信彦,高森奈津美"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterviewKouenNoMaken;
