import HeadBlock from "../../../components/HeadBlock";
import { Link } from "react-router-dom";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import styles from "../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../components/AuthContext";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

function InterViewFuyuzoraMelt() {
  const { user } = useAuthContext();

  useEffect(() => {
    logEvent(analytics, "view_interview_page", {
      is_login: user ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={"長谷川 育美「絶妙なラインの表現は新しい挑戦でした」"}
        path="interview/fuyuzora-melt"
      />
      <div className={`${styles.Interview} ${styles.fuyuzoraMelt}`}>
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <img
              src={require("../../../assets/images/interview/logo.svg").default}
              alt="PAZLE Creator’s interview"
            />
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/fuyuzora-melt"
                target="_brank"
                className={`${styles.btn} ${styles.btnSmall} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>
                  ボイスドラマ
                  <br className={styles.brSp} />
                  を視聴する
                </span>
              </a>
            </div>
          </div>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.body}>
            <h1>長谷川 育美「絶妙なラインの表現は新しい挑戦でした」</h1>
            <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
            <p>
              PAZLEの初ボイスドラマである「想い、冬空メルト」の公開を記念し、主人公の実紅役を演じてくださった声優の長谷川
              育美さんに今作品の見どころや制作秘話などを語っていただきました。
            </p>
            <img
              className={styles.cover}
              src={require("../../../assets/images/interview/fuyuzora-melt/img1.png")}
              alt=""
            />
            <p className={styles.interviewer}>
              ―収録お疲れ様でした！物語に命が吹き込まれる様子を目の当たりにして感動しました。
              <br />
              さっそくですが、まず物語の印象についてお聞かせください。
            </p>
            <p>
              The青春という印象です！
              ショートストーリーでしたけど、高校生の青春がぎゅっと詰まっていて。
              <br />
              個人的に終わり方が予想外だったのも面白かったです。
              <br />
              失恋を吹っ切って「次に進もう！」で終わるのかなって思ったら、「好き」という気持ちを再確認するっていう終わり方が新鮮でいいなと思いました。
            </p>
            <p className={styles.interviewer}>
              ―諦めない前向きな強さを主人公は持っていますよね。それが長谷川さんの演技からも伝わり、声と印象がぴったりだと思いました。
              <br />
              ストーリーの魅力はどんなところでしょうか？
            </p>
            {user ? (
              <p>
                繰り返しになってしまうんですけど、ショートストーリーでも感じられる主人公の揺れ動く感情、葛藤などです。
                <br />
                あと友達同士の関係性にもちゃんと気を遣っていて、学校生活で友情を大事にしてみんなとも馴染みたいっていう学生らしい想いも短い中にちゃんと入ってて。
                <br />
                恋や友情など色々な感情を感じられるんです。
                多分みなさんも共感できるところがたくさんあると思います！
                <br />
                そんな学生ならではの想いが見えるところが良かったですね。
              </p>
            ) : (
              <p>
                繰り返しになってしまうんですけど、ショートストーリーでも感じられる主人公の揺れ動く感情、葛藤などです。
                <br />
                あと...
              </p>
            )}

            {!user && (
              <div className={styles.readmore}>
                <p>
                  続きを読むには、
                  <br />
                  アカウント登録（無料）が必要です
                </p>
                <a
                  href="/signin?interViewId=fuyuzora-melt"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  アカウント登録 / ログイン
                </a>
              </div>
            )}
            {user && (
              <>
                <img
                  src={require("../../../assets/images/interview/fuyuzora-melt/img2.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―そんな揺れ動く主人公の想いを視聴者の皆さんにぜひ感じとっていただきたいですね！
                  <br />
                  主人公の感情の揺れ動きが魅力とのことですが、中でもイチオシの演技はありますか？
                </p>
                <p>
                  冒頭の一言目からの叫び！
                  人生であんな思いっきり叫ぶことって赤ちゃん時代以外であまりないですよね？
                  <br />
                  高校生くらいの大人になって、あれだけ感情をブワーって吐き出すって経験がなかったので。
                  <br />
                  これができるってすごいなと。
                  <br />
                  失恋の感情の振り幅が本当に激しかったんだって思います。
                </p>
                <img
                  src={require("../../../assets/images/interview/fuyuzora-melt/img3.png")}
                  alt=""
                />
                <p className={styles.interviewer}>
                  ―聴いていて、強烈な感情を感じて震えました。長谷川さんの中で今回新しい挑戦などもありましたか？
                </p>
                <p>
                  セリフとストーリーがある朗読（ろうどく）形式のものはそんなにやったことはなくて。
                  <br />
                  ストーリー部分は主人公の感情が感じられる文章であるけどセリフとは違うっていう、その絶妙なラインの表現は新しい挑戦でした。
                  <br />
                  私がこれまでの作品でやってない表現、セリフとストーリーの違いに注目して聞いてもらえたら嬉しいです！
                </p>
                <p className={styles.interviewer}>
                  ―新しい長谷川さんの表現に注目して聴きますね！最後に視聴者の皆さんにメッセージをお願いします！
                </p>
                <p>
                  今回がPAZLEの作品第一弾とお聞きしました。
                  <br />
                  第一弾に選んでいただいてすごく光栄ですし、先ほども言った通り朗読作品をやらせていただくことがあまりなかったのもあって自分自身どうなるかな？とドキドキしながら挑ませていただきました。
                  <br />
                  ショートストーリーの中に喜怒哀楽のいろんな感情が詰まっています。
                  <br />
                  だからかな？
                  <br />
                  ショートストーリーと思えないくらい物語が成り立ってるのがすごいなって思ったので、ぜひこの子（主人公）の葛藤の物語を楽しんでいただきたいなと思います！
                </p>
                <p className={styles.interviewer}>
                  ―ありがとうございました！1ファンとしてとても素敵な作品だと思います。今後のご活躍を応援しています！
                </p>
                <div className={styles.introduction}>
                  <p>
                    長谷川育美（ハセガワ イクミ） 声優。
                    <br />
                    <br />
                    5月31日生まれ、栃木県出身。A型。出演作品は、アニメ『86-エイティシックス-』（ヴラディレーナ・ミリーゼ）、アニメ『弱キャラ友崎くん』（七海みなみ）、アニメ『推しが武道館いってくれたら死ぬ』（松山空音）、『ぼっち・ざ・ろっく！』（喜多郁代）など。
                  </p>
                </div>
              </>
            )}

            <div className={styles.btnWrap}>
              <p>＼冒頭33秒を無料で視聴できます！／</p>
              <a
                href="/contents/fuyuzora-melt"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${
                  user ? styles.btnPrimary : styles.btnSecondary
                }`}
              >
                <span>ボイスドラマを視聴する</span>
              </a>
              <a
                href="/contents/fuyuzora-melt/detail"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
              >
                作品詳細はこちら
              </a>
            </div>
          </div>

          <section className={styles.present}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Present</h2>
              <span className={styles.titleJa}>プレゼント企画実施中！</span>
            </div>
            <section className={styles.body}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.text}>
                <h3 className={styles.subTitle}>
                  長谷川育美<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    ツイッターにて公式ツイートに「想い、冬空メルト。」の感想を記載し引用リツイートしていただくと、抽選で2名様に長谷川育美さん直筆色紙をプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/fuyuzora-melt/detail/present"
                  target="_blank"
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/fuyuzora-melt/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/fuyuzora-melt/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/interview/fuyuzora-melt&text=長谷川 育美「絶妙なラインの表現は新しい挑戦でした」- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,長谷川育美"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InterViewFuyuzoraMelt;
