import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link, Outlet } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";
import imgLogoPazle from "../../../assets/images/logo.svg";
import imgLogoPazleBeta from "../../../assets/images/content/logo.svg";
import imgLogoPazleFooter from "../../../assets/images/content/logo_footer.svg";
import imgLogoContent from "../../../assets/images/content/fuyuzora-melt-reply/logo.svg";
import imgLogoPazleWhite from "../../../assets/images/logo_white.svg";
import imgLogoVector from "../../../assets/images/top_logo_vector.svg";
import imgArrowTop from "../../../assets/images/top_arrow_top.svg";

function ContentFuyuzoraMeltReply() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock
        title={"想い、冬空メルト。～reply～"}
        path="contents/fuyuzora-melt-reply/detail"
      />
      <div className={`${styles.Content} ${styles.reply}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img src={imgLogoPazle} alt="" />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/fuyuzora-melt-reply/detail">
                <img
                  src={imgLogoPazleBeta}
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/fuyuzora-melt-reply"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/fuyuzora-melt-reply"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/fuyuzora-melt-reply/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/fuyuzora-melt-reply/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.mainvisualTitle}>
                <div className={styles.text}>
                  <h1>
                    <img
                      src={imgLogoContent}
                      alt="想い、冬空メルト。～reply～"
                    />
                  </h1>
                  <p className={styles.tag}>
                    想い、冬空メルトのアンサーストーリー。
                    <br />
                    実紅の叫びを聴いた彼は全力で走り出す！
                  </p>
                </div>
                <a
                  href="/contents/fuyuzora-melt-reply"
                  target="_blank"
                  className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
                >
                  ボイスドラマを視聴する
                </a>
              </div>
              <div className={styles.free}>
                <p>
                  <span>
                    <strong>1</strong>
                    <small>話目</small>
                  </span>
                  <span>無料</span>
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/fuyuzora-melt-reply/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/actor_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>伏水 翠 役</p>
                  <p className={styles.name}>
                    小林 裕介<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    小林 裕介（コバヤシ ユウスケ）声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/story2.png")}
                  alt=""
                />
                <p>「俺もずっと、お前のことが好きだったんだ！」</p>
                <p>
                  どうして俺は、この言葉が言えなかったんだろう…。好きだった女の子が自分のことを好き、そんな奇跡みたいなことってあるか？
                  <br />
                  …それなのに、俺の中の弱気の虫が邪魔をした。
                </p>
                <p>
                  高校生の翠は自分のヘタレさを嘆いていた。そして冬の空を眺めていると、どこからか恐竜のような叫び声が聴こえて…
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    小林 裕介（コバヤシ ユウスケ）声優。
                    <br />
                    <br />
                    3月25日生まれ、東京都出身。B型。出演作品は、アニメ『Dr.STONE』（石神千空）、『Re:ゼロから始める異世界生活』（ナツキ・スバル）、『SYNDUALITY
                    Noir』（トキオ）、『パズドラ』（松原龍二）、『聖女の魔力は万能です』（ユーリ・ドレヴェス）など。
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼全3話のうち1話目無料！／</p>
              <a
                href="/contents/fuyuzora-melt-reply"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <div className={styles.relatedContent}>
            <a href="/contents/fuyuzora-melt" target="_blank">
              <img
                className={styles.relatedContentPc}
                src={require("../../../assets/images/content/fuyuzora-melt-reply/banner_pc.png")}
                alt=""
              />
              <img
                className={styles.relatedContentSP}
                src={require("../../../assets/images/content/fuyuzora-melt-reply/banner_sp.png")}
                alt=""
              />
            </a>
          </div>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  <strong>
                    僕の方はギャグでいいよと言われてびっくりしました
                  </strong>
                </h3>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―収録お疲れ様でした！続編ということで個人的にもとても楽しみにしていた収録です。まず物語全体の印象はいかがでしたか？
                  </p>
                  <p className={styles.Interviewee}>
                    一人語りとして、モノローグの部分とセリフの部分を同時に録らせていただきました。
                    <br />
                    最初台本をいただいたときにアンサーストーリーとは？と思っていたら、もう既に長谷川育美さんが実紅視点のストーリーを録られていて配信されているということなので、なるほど以前の物語があった上での裏側の話というかTrueStoryのようなものなんだというのを・・・
                  </p>
                </div>
                <a
                  href="/interview/fuyuzora-melt-reply"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>

            <section className={styles.present}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  小林 裕介<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    公式X（旧：Twitter）から投稿される応募用のポストに「ボイスドラマの感想」を記載し引用リポストしていただくと、抽選で2名様に小林裕介さん直筆色紙をプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/fuyuzora-melt-reply/detail/present"
                  state={{ background: location }}
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/fuyuzora-melt-reply/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/fuyuzora-melt-reply/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img src={imgLogoPazleFooter} alt="" />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/fuyuzora-melt-reply/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/fuyuzora-melt-reply/detail&text=小林裕介さん出演！想い、冬空メルトのアンサーストーリー「想い、冬空メルト。～reply～」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,小林裕介"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img src={imgArrowTop} alt="" />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img src={imgLogoVector} className={styles.vector} alt="" />
                <span>/</span>
                <img src={imgLogoPazleWhite} className={styles.pazle} alt="" />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentFuyuzoraMeltReply;
