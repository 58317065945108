import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../assets/styles/header.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import { useAuthContext } from "./AuthContext";
import { Button } from "./Button";

export const Header = ({
  buckButtonTarget = null,
  tab = null,
}: {
  buckButtonTarget?: string | null;
  tab?: { name: string; url: string }[] | null;
}) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        {buckButtonTarget && (
          <button
            type="button"
            className={styles.btn_back}
            onClick={() => navigate(buckButtonTarget)}
          >
            <i className={stylesIcon.icon_arrow_back}></i>
          </button>
        )}
        <NavLink to={"/"}>
          <img
            src={require("../assets/images/logo_beta.svg").default}
            className={styles.logo}
            alt="PAZLE"
          />
        </NavLink>
        {user ? (
          <div className={styles.login}>
            <i className={stylesIcon.icon_user_outline}></i>
            <p>ログイン中</p>
          </div>
        ) : (
          <Button size="s" onClick={() => navigate("/signin")}>
            ログイン/登録
          </Button>
        )}
      </div>
      {tab && tab.length > 0 && (
        <div className={styles.tab}>
          {tab.map((t, i) => (
            <div className={styles.tab_item} key={i}>
              <NavLink
                to={t.url}
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                {t.name}
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
