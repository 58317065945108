import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";

function ContentKouenNoMaken() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock
        title={"風の姫君と紅炎の魔剣"}
        path="contents/kouen-no-maken/detail"
      />
      <div className={`${styles.Content} ${styles.kouenNoMaken}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt=""
          />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/kouen-no-maken/detail">
                <img
                  src={
                    require("../../../assets/images/content/logo.svg").default
                  }
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/kouen-no-maken"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/kouen-no-maken"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/kouen-no-maken/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/kouen-no-maken/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.text}>
                <h1>
                  <img
                    src={
                      require("../../../assets/images/content/kouen-no-maken/logo.svg")
                        .default
                    }
                    alt="風の姫君と紅炎の魔剣"
                  />
                </h1>
                <p className={`${styles.tag}`}>
                  PAZLEボイスドラマ シリーズ作品始動！
                  <br />
                  魔剣に魅入られた青年の物語が始まる。
                </p>
              </div>
              <a
                href="/contents/kouen-no-maken"
                target="_blank"
                className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
              <div className={`${styles.mainvisualCsp} ${styles.cspSp}`}>
                <img
                  src={require("../../../assets/images/logo_csp_s.svg").default}
                  alt=""
                />
                <p>
                  作品のご購入でクリエイターに
                  <br />
                  売上の一部が還元されます
                </p>
              </div>
              <div className={`${styles.mainvisualCsp} ${styles.cspPc}`}>
                <img
                  src={require("../../../assets/images/logo_csp.svg").default}
                  alt=""
                />
                <p>
                  作品をご購入いただくとクリエイターに売上の一部が還元されます
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/kouen-no-maken/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/actor_okamoto_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>紅 役</p>
                  <p className={styles.name}>
                    岡本 信彦<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    岡本 信彦（オカモト ノブヒコ） 声優。
                    <br />
                    <br />
                    10月24日生まれ、東京都出身。B型。出演作品は、『とある魔術の禁書目録』（一方通行〈アクセラレータ〉役）、『青の祓魔師』（奥村燐役）、『僕のヒーローアカデミア』（爆豪勝己役）、『ハイキュー!!』（西谷夕役）、『鬼滅の刃』（不死川玄弥役）など。
                  </p>
                </div>
              </li>
              <li>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/actor_takamori_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>セシリア 役</p>
                  <p className={styles.name}>
                    高森 奈津美<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    高森 奈津美（タカモリ ナツミ） 声優。
                    <br />
                    <br />
                    2月14日生まれ、山梨県出身。A型。出演作品は、『アイドルマスターシンデレラガールズ』（前川みく役）、『デリシャスパーティ♡プリキュア』コメコメ役）、『Another』（見崎鳴役）、『五等分の花嫁』（上杉らいは役）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
            </div>

            <div className={styles.outline}>
              <p>
                かつて世界に起きた『紅蓮の厄災』。
                <br />
                その元凶は《メギド・ラスール》と呼ばれた。
              </p>
              <p>
                それから15年後、1人の青年が旅に出る。
                <br />
                青年は炎の魔剣に魅入られ、心臓に呪いを受けていた。
                <br />
                災厄を再び世界に解き放つほどの呪いを。
              </p>
              <p>
                青年は呪いを解くために世界を巡る。
                <br />
                自らの運命に立ち向かってー。
              </p>
            </div>

            <div className={styles.episode}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3>episode 1</h3>
                <p>
                  かつて世界に起きた『紅蓮の厄災』。
                  <br />
                  その元凶は《メギド・ラスール》と呼ばれた。
                </p>
                <p>
                  それから15年後、1人の青年が旅に出る。
                  <br />
                  青年は炎の魔剣に魅入られ、心臓に呪いを受けていた。
                  <br />
                  災厄を再び世界に解き放つほどの呪いを。
                </p>
                <p>
                  青年は呪いを解くために世界を巡る。
                  <br />
                  自らの運命に立ち向かってー。
                </p>
              </div>
            </div>

            <div className={styles.character}>
              <div className={styles.characterWrap}>
                <div className={styles.characterItem}>
                  <div>
                    <div>
                      <img
                        src={require("../../../assets/images/content/kouen-no-maken/character_kurenai.png")}
                        alt=""
                      />
                    </div>
                    <div className={styles.characterIntroduction}>
                      <p className={styles.characterRole}>
                        紅<span>（くれない）</span>
                      </p>
                      <p className={styles.characterText}>
                        魔剣に愛され強力な炎の魔力を帯び炎を自在に操る。大厄災の生き残りであり、その時に大きな呪いを負った。
                        <br />
                        16歳で呪いを解く旅に出るが、セシリアが無理やりついてくることに！
                      </p>
                    </div>
                  </div>
                  <div
                    className={`${styles.introduction} ${styles.introductionPc}`}
                  >
                    <p>
                      岡本 信彦（オカモト ノブヒコ） 声優。
                      <br />
                      <br />
                      10月24日生まれ、東京都出身。B型。出演作品は、『とある魔術の禁書目録』（一方通行〈アクセラレータ〉役）、『青の祓魔師』（奥村燐役）、『僕のヒーローアカデミア』（爆豪勝己役）、『ハイキュー!!』（西谷夕役）、『鬼滅の刃』（不死川玄弥役）など。
                    </p>
                  </div>
                </div>
                <div className={styles.characterItem}>
                  <div>
                    <div>
                      <img
                        src={require("../../../assets/images/content/kouen-no-maken/character_cecilia.png")}
                        alt=""
                      />
                    </div>
                    <div className={styles.characterIntroduction}>
                      <p className={styles.characterRole}>セシリア</p>
                      <p className={styles.characterText}>
                        風の魔弓を扱う王女。
                        <br />
                        気が強く怪力の持ち主で、陰でゴリラ姫と呼ばれることも。紅の呪いを解くため共に旅に出るが、そこには壮絶な覚悟があった。
                      </p>
                    </div>
                  </div>
                  <div
                    className={`${styles.introduction} ${styles.introductionPc}`}
                  >
                    <p>
                      高森 奈津美（タカモリ ナツミ） 声優。
                      <br />
                      <br />
                      2月14日生まれ、山梨県出身。A型。出演作品は、『アイドルマスターシンデレラガールズ』（前川みく役）、『デリシャスパーティ♡プリキュア』コメコメ役）、『Another』（見崎鳴役）、『五等分の花嫁』（上杉らいは役）など。
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.btnWrap}>
                <p>＼冒頭29秒を無料で視聴できます！／</p>
                <a
                  href="/contents/kouen-no-maken"
                  target="_blank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                >
                  ボイスドラマを視聴する
                </a>
              </div>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>
                      セリフ以外の部分で想像力を働かせられるよう演じました
                    </strong>
                  </h3>
                  <p>岡本 信彦</p>
                </div>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>
                      コミカルさみたいなのが本当にかわいらしく感じました
                    </strong>
                  </h3>
                  <p>高森 奈津美</p>
                </div>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―本日はありがとうございました！
                    <br />
                    弊社代表が力を入れている作品なのですが、お二方の演技がイメージ以上にはまっていてびっくりしました。
                    <br />
                    さっそくですが、まず今回の収録についてのご感想をお聞かせください。
                  </p>
                  <p className={styles.Interviewee}>
                    岡本さん：主人公の紅は、凄惨な過去があり少し歪んだ精神状態の持ち主という主人公っぽくないところが魅力だと感じました。
                    <br />
                    その魅力を全開に、「セシリアとの出会い」という物語の始まりをお届けできたのではないかと思います。
                    <br />
                    ここからさらに広がりを感じる...
                  </p>
                </div>
                <a
                  href="/interview/kouen-no-maken"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
            <section className={styles.present}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  岡本 信彦<small>さん、</small>高森 奈津美<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で1名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    公式X（旧：Twitter）から投稿される応募用のポストに「ボイスドラマの感想」を記載し引用リポストしていただくと、抽選で岡本
                    信彦さん・高森 奈津美さん直筆色紙を1名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/kouen-no-maken/detail/present"
                  state={{ background: location }}
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/kouen-no-maken/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/kouen-no-maken/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/kouen-no-maken/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/kouen-no-maken/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/kouen-no-maken/detail&text=岡本信彦さん、高森奈津美さん出演！魔剣に魅入られた青年の物語が始まる。「風の姫君と紅炎の魔剣」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,岡本信彦,高森奈津美"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentKouenNoMaken;
