import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./assets/styles/SignIn.scss";
import "./assets/styles/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <Helmet></Helmet>
      <App />
    </HelmetProvider>
  </BrowserRouter>,
);
