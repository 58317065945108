import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../../assets/styles/content.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";

function ContentNoroiNoRondo() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const navigation: any = performance.getEntriesByType("navigation")[0];
    const coordinates = navigation.type;
    if (coordinates === "reload") setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1600);
  }, []);

  return (
    <>
      <HeadBlock
        title={"魔法の紋章と呪いの恋輪舞（ロンド）"}
        path="contents/noroi-no-rondo/detail"
      />
      <div className={`${styles.Content} ${styles.noroiNoRondo}`}>
        <div className={`${styles.loading} ${!isLoading && styles.hidden}`}>
          <img
            src={require("../../../assets/images/logo.svg").default}
            alt=""
          />
          <p>
            <span className={styles.word}>N</span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>w</span>
            <span className={`${styles.word} ${styles.wordFirstLetter}`}>
              L
            </span>
            <span className={styles.word}>o</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>d</span>
            <span className={styles.word}>i</span>
            <span className={styles.word}>n</span>
            <span className={styles.word}>g</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
            <span className={styles.word}>.</span>
          </p>
        </div>

        <header className={styles.header}>
          <nav className={styles.nav}>
            <h1>
              <a href="/contents/noroi-no-rondo/detail">
                <img
                  src={
                    require("../../../assets/images/content/logo.svg").default
                  }
                  alt="PAZLE"
                  className={styles.logo}
                />
              </a>
            </h1>
            <a href="#sns" className={styles.pageToSns}>
              <i className={stylesIcon.icon_arrow_down_circle}></i>
              SNSをフォローする
            </a>
            <a
              href="/contents/noroi-no-rondo"
              target="_blank"
              className={`${styles.navBtnSp} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
            >
              ボイスドラマ
              <br />
              を視聴する
            </a>
            <div className={styles.headerBtns}>
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
              <a
                href="/contents/noroi-no-rondo"
                target="_blank"
                className={`${styles.navBtnPc} ${styles.btn} ${styles.btnSmall} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </nav>
        </header>

        <main className={styles.main} id="main">
          <div className={styles.mainvisual}>
            <img
              src={require("../../../assets/images/content/noroi-no-rondo/kv_sp.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgSp}`}
              alt=""
            />
            <img
              src={require("../../../assets/images/content/noroi-no-rondo/kv_pc.png")}
              className={`${styles.mainvisualImg} ${styles.mainvisualImgPc}`}
              alt=""
            />
            <div className={styles.inner}>
              <div className={styles.text}>
                <h1>
                  <img
                    src={
                      require("../../../assets/images/content/noroi-no-rondo/logo.svg")
                        .default
                    }
                    alt="魔法の紋章と呪いの恋輪舞（ロンド）"
                  />
                </h1>
                <p className={`${styles.tag}`}>
                  PAZLEボイスドラマ第三弾公開！
                  <br />
                  魔女の呪いを巡る運命の剣士たちの物語。
                </p>
              </div>
              <a
                href="/contents/noroi-no-rondo"
                target="_blank"
                className={`${styles.btn} ${styles.btnLarge} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
              <div className={`${styles.mainvisualCsp} ${styles.cspSp}`}>
                <img
                  src={require("../../../assets/images/logo_csp_s.svg").default}
                  alt=""
                />
                <p>
                  作品のご購入でクリエイターに
                  <br />
                  売上の一部が還元されます
                </p>
              </div>
              <div className={`${styles.mainvisualCsp} ${styles.cspPc}`}>
                <img
                  src={require("../../../assets/images/logo_csp.svg").default}
                  alt=""
                />
                <p>
                  作品をご購入いただくとクリエイターに売上の一部が還元されます
                </p>
              </div>
            </div>
            <img
              src={require("../../../assets/images/content/noroi-no-rondo/actor_pc.png")}
              className={styles.actorPc}
              alt=""
            />
            <div className={styles.scroll}></div>
          </div>

          <div className={styles.actor}>
            <ul>
              <li>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/actor_iwami_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>リオン・リルスィード 役</p>
                  <p className={styles.name}>
                    石見 舞菜香<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    石見 舞菜香（イワミ マナカ） 声優。
                    <br />
                    <br />
                    4月30日生まれ、埼玉県出身。出演作品は、『【推しの子】』（黒川あかね役）、『フルーツバスケット』（本田透役）、『ひきこまり吸血姫の悶々』（サクナ・メモワール役）、『不滅のあなたへ』（リーン役）、『クジラの子らは砂上に歌う』（リコス役）など。
                  </p>
                </div>
              </li>
              <li>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/actor_amasaki_sp.png")}
                  className={styles.actorPc}
                  alt=""
                />
                <div className={styles.text}>
                  <p className={styles.role}>神代 悠汰 役</p>
                  <p className={styles.name}>
                    天﨑 滉平<small>さん</small>
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionSp}`}
                >
                  <p>
                    天﨑 滉平（アマサキ コウヘイ） 声優。
                    <br />
                    <br />
                    10月22日生まれ、大阪府出身。出演作品は、『トモちゃんは女の子!』（御崎光助役）、『時々ボソッとロシア語でデレる隣のアーリャさん』（久世政近役）、『アイドルマスター
                    SideM』（東雲荘一郎役）、『事情を知らない転校生がグイグイくる。』（北川虎太郎役）、『僕のヒーローアカデミア』（物間寧人役）、『ヒプノシスマイク
                    -Division Rap Battle- Rhyme
                    Anima』（山田三郎役）、『ダンス・ダンス・ダンスール』（安田海咲役）など。
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <section className={styles.story}>
            <div className={styles.title}>
              <h2 className={styles.titleEn}>Story</h2>
              <span className={styles.titleJa}>あらすじ</span>
            </div>
            <div className={styles.inner}>
              <div className={styles.imgWrap}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/story1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/story2.png")}
                  alt=""
                />
                <p>
                  学園トーナメントで激しく大剣を振るリオン・リルスィード。
                  <br />
                  彼女は神代
                  悠汰に怒っていた。なにしろ着替え中の更衣室を見られてしまったのだから。
                  <br />
                  だが悠汰は知っていた。リオンが背負う過酷な運命と、彼がリオンを救わなければいけないことを。
                </p>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    石見 舞菜香（イワミ マナカ） 声優。
                    <br />
                    <br />
                    4月30日生まれ、埼玉県出身。出演作品は、『【推しの子】』（黒川あかね役）、『フルーツバスケット』（本田透役）、『ひきこまり吸血姫の悶々』（サクナ・メモワール役）、『不滅のあなたへ』（リーン役）、『クジラの子らは砂上に歌う』（リコス役）など。
                  </p>
                </div>
                <div
                  className={`${styles.introduction} ${styles.introductionPc}`}
                >
                  <p>
                    天﨑 滉平（アマサキ コウヘイ） 声優。
                    <br />
                    <br />
                    10月22日生まれ、大阪府出身。出演作品は、『トモちゃんは女の子!』（御崎光助役）、『時々ボソッとロシア語でデレる隣のアーリャさん』（久世政近役）、『アイドルマスター
                    SideM』（東雲荘一郎役）、『事情を知らない転校生がグイグイくる。』（北川虎太郎役）、『僕のヒーローアカデミア』（物間寧人役）、『ヒプノシスマイク
                    -Division Rap Battle- Rhyme
                    Anima』（山田三郎役）、『ダンス・ダンス・ダンスール』（安田海咲役）など。
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.btnWrap}>
              <p>＼冒頭30秒を無料で視聴できます！／</p>
              <a
                href="/contents/noroi-no-rondo"
                target="_blank"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                ボイスドラマを視聴する
              </a>
            </div>
          </section>

          <section className={styles.interview}>
            <div className={styles.title}>
              <span className={styles.supplement}>
                ＼収録の裏話を公開中！／
              </span>
              <h2 className={styles.titleEn}>Interview</h2>
              <span className={styles.titleJa}>収録後インタビュー</span>
            </div>
            <section className={styles.interviewDetail}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/interview1.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>
                      この子の奥行きみたいなものを想像してもらえたらいいな
                    </strong>
                  </h3>
                  <p>石見 舞菜香</p>
                </div>
                <div className={styles.titleWrap}>
                  <h3 className={styles.subTitle}>
                    <strong>
                      飄々としていながらも、壮絶な部分を隠して生きている
                    </strong>
                  </h3>
                  <p>天﨑 滉平</p>
                </div>
                <div className={styles.textBody}>
                  <p className={styles.interviewer}>
                    ―改めまして本日はありがとうございました。
                    <br />
                    さっそくですが、まず物語の印象についてお聞かせください。
                  </p>
                  <p className={styles.Interviewee}>
                    天﨑さん：設定資料を事前に読ませていただいたのですが、今回収録したショートストーリーの前後にも物語が広がっているんだろうな、というのをすごく感じました。
                    <br />
                    あとは結構シリアスなストーリーですよね。特に悠汰（ゆうた）は、お姉さん以外の家族を亡くしていて、そのお姉さんでさえも呪いの力で世界を変えようとする魔女になっていて...
                  </p>
                </div>
                <a
                  href="/interview/noroi-no-rondo"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                >
                  続きを読む
                </a>
              </div>
            </section>
            <section className={styles.present}>
              <div className={styles.img}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/interview2.png")}
                  alt=""
                />
              </div>
              <div className={styles.body}>
                <h3 className={styles.subTitle}>
                  石見 舞菜香<small>さん、</small>天﨑 滉平<small>さん</small>
                  <br className={styles.brPc} />
                  <strong>直筆のサイン色紙</strong>
                  <br className={styles.brPc} />
                  を抽選で2名様にプレゼント!
                </h3>
                <div className={styles.textBody}>
                  <p>
                    公式X（旧：Twitter）から投稿される応募用のポストに「ボイスドラマの感想」を記載し引用リポストしていただくと、抽選で石見舞菜香さん・天﨑滉平さん直筆色紙を2名様にプレゼント！
                  </p>
                  <p>ご応募の詳細は下記からご確認いただけます。</p>
                </div>
                <Link
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary}`}
                  to="/contents/noroi-no-rondo/detail/present"
                  state={{ background: location }}
                >
                  ご応募の詳細はこちら
                </Link>
              </div>
            </section>
          </section>

          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={require("../../../assets/images/content/noroi-no-rondo/csp_pc.png")}
              alt=""
            />
            <img
              className={styles.imgSp}
              src={require("../../../assets/images/content/noroi-no-rondo/csp_sp.png")}
              alt=""
            />
          </div>

          <div className={styles.product}>
            <div className={styles.inner}>
              <div className={styles.textBody}>
                <div className={styles.logo}>
                  <img
                    src={
                      require("../../../assets/images/content/logo_footer.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className={styles.subTitle}>
                  話題の声優のボイスドラマが
                  <br className={styles.brPc} />
                  視聴できる「PAZLE<span>（ベータ版）</span>」誕生
                </h3>
                <p>
                  今後も、人気声優さんのボイスドラマを続々配信予定！
                  <br />
                  ぜひ、公式ページもチェックしてください！
                </p>
                <img
                  className={styles.imgContentsSp}
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_sp.png")}
                  alt=""
                />
                <a
                  href="/about"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnMedium} ${styles.btnSecondary}`}
                >
                  <span>PAZLE公式ページへ</span>
                </a>
              </div>
              <div className={styles.imgContentsPc}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_pc.png")}
                  alt=""
                />
              </div>
              <div className={styles.imgContentsIpad}>
                <img
                  src={require("../../../assets/images/content/noroi-no-rondo/footer_ipad.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.footerInner}>
            <h3 className={styles.subTitle}>
              その他の新作作品も順次公開予定！
            </h3>
            <ul className={styles.btns}>
              <li>
                <p>＼友達にも共有しよう／</p>
                <a
                  href="http://twitter.com/share?url=pazle.click/contents/noroi-no-rondo/detail&text=石見舞菜香さん、天﨑滉平さん出演！魔女の呪いを巡る運命の剣士たちの物語「魔法の紋章と呪いの恋輪舞（ロンド）」&via=Pazle_info&hashtags=PAZLE,声優,ショートドラマ,ボイスドラマ,石見舞菜香,天﨑滉平"
                  target="_brank"
                  className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
                >
                  SHARE
                </a>
              </li>
            </ul>
            <div className={styles.snsLinks} id="sns">
              <a
                href="https://twitter.com/Pazle_info"
                target="_brank"
                className={`${styles.btnTw}`}
              >
                フォローする
              </a>
              <a
                href="https://lin.ee/r4SVWtA"
                target="_brank"
                className={styles.btnLine}
              >
                <img
                  src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                  alt="友だち追加"
                />
              </a>
            </div>
            <div className={styles.inner}>
              <div className={styles.backToTopWrap}>
                <a className={styles.backToTop} href="#main">
                  <img
                    src={
                      require("../../../assets/images/top_arrow_top.svg")
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <ul className={styles.linkList}>
                <li>
                  <a href="https://vectordesign.jp/" target="_brank">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_brank">
                    利用規約
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_brank">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="/tokutei" target="_brank">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="/requirements" target="_brank">
                    動作環境について
                  </a>
                </li>
              </ul>
              <div className={styles.copylight}>
                <small>©︎2023</small>
                <img
                  src={
                    require("../../../assets/images/top_logo_vector.svg")
                      .default
                  }
                  className={styles.vector}
                  alt=""
                />
                <span>/</span>
                <img
                  src={require("../../../assets/images/logo_white.svg").default}
                  className={styles.pazle}
                  alt=""
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Outlet />
    </>
  );
}

export default ContentNoroiNoRondo;
